// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AssetListNEW from "../components/AssetListNEW";
import { AssetBreadCrumb } from "../components/AssetBreadCrumb";
import AssetDescription from "../components/AssetDescription";
import AssetGraph from "../components/AssetGraph";
import AssetAlerts from "../components/AssetAlerts";
import { useParams } from "react-router-dom";
import LoadingIcon from "../components/LoadingIcon";
import { fetchAssetGroupById } from "../store/assetGroupSlice";
import { fetchAssetsByGroupId } from "../store/assetsSlice";
import { generateClient } from "aws-amplify/api";
import { getUser } from "../graphql/queries";

const Dashboard = () => {
  let { groupid, assetid } = useParams();
  const dispatch = useDispatch();
  const [assetOwner, setAssetOwner] = useState(null);
  const { assets, assetGroups, user } = useSelector(
    ({ assets, assetGroups, user }) => ({
      assets,
      assetGroups,
      user,
    })
  );
  const assetGroup = assetGroups.groups[groupid];
  const assetList = assets.dataGroups[groupid];

  const fetchAssetAndGroup = async () => {
    if (!assetGroup) {
      await dispatch(fetchAssetGroupById(groupid));
    }
    if (!assetList) {
      await dispatch(fetchAssetsByGroupId(groupid));
    }
  };

  useEffect(() => {
    fetchAssetAndGroup();
  }, []);

  const selectedAsset = useMemo(() => {
    return assetList ? assetList.find((asset) => asset.id === assetid) : null;
  }, [assetList, assetid]);

  const getAssetOwnerUser = async () => {
    // if the user is an admin, get the owner details - needed for S3 fetching
    if (selectedAsset && user?.permissionDetails?.id) {
      if (
        user?.permissionDetails?.id !== selectedAsset?.owner &&
        assetOwner?.id !== selectedAsset?.owner
      ) {
        console.log("get Owner");
        // if (groups && groups.includes("Admin")) {
        const client = generateClient();
        const { data } = await client.graphql({
          query: getUser,
          variables: { id: selectedAsset?.owner },
        });

        const { id, name, cognitoIdentityId, email, username } = data.getUser;
        setAssetOwner({ id, name, cognitoIdentityId, email, username });
      }
    }
  };

  useEffect(() => {
    getAssetOwnerUser();
  }, [selectedAsset, user]);

  if (!assetGroup || !assetList) {
    return (
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div
          className={`transition-opacity delay-700 duration-500 ease-in-out opacity-100`}
        >
          <LoadingIcon />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="min-w-[300px] bg-stone-50 hidden xl:flex flex-col">
        <AssetListNEW assetGroup={assetGroup} assetList={assetList} />
      </div>
      <div className="flex-1 ">
        <div className="flex-col flex h-screen">
          <AssetBreadCrumb
            assetGroupName={assetGroup.name}
            assetName={selectedAsset.name}
          />
          <AssetDescription
            asset={selectedAsset}
            assetOwner={assetOwner ?? user.permissionDetails}
          />
          <div className="flex flex-row flex-1 bg-stone-50">
            <AssetGraph selectedAsset={selectedAsset} />
            {/* <AssetAlerts /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

// @ts-nocheck
import React from "react";
import AssetProximity from "./AssetProximity";
import { BiSolidLeftArrow, BiSolidRightArrow, BiDollar } from "react-icons/bi";
import { FaMapLocationDot } from "react-icons/fa6";
import AssetForecastTabs from "../../AssetForecastTabs";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedAsset } from "../../../store/assetsSlice";
import { setFlyLocation } from "../../../store/mapStateSlice";

const displayPropertySchema = [
  {
    id: "name",
    title: "Asset Name",
    number: false,
    clipLength: 5,
  },
  {
    id: "lat",
    title: "Latitude",
    number: true,
    clipLength: 7,
  },
  {
    id: "lng",
    title: "Longitude",
    number: true,
    clipLength: 7,
  },
];

const AssetDetail = ({ selectedAsset }) => {
  const assets = useSelector((state) => state.assets);
  const dispatch = useDispatch();

  const handleAssetNav = (direction) => {
    const assetIndex = assets.data.findIndex(
      (asset) => selectedAsset.id === asset.id
    );

    let newAssetIndex = assetIndex + direction;
    if (newAssetIndex < 0) {
      newAssetIndex = assets.data.length - 1;
    } else if (newAssetIndex + 1 > assets.data.length) {
      newAssetIndex = 0;
    }
    const nextAsset = assets.data[newAssetIndex];
    dispatch(setSelectedAsset(nextAsset));
    dispatch(setFlyLocation([nextAsset.lng, nextAsset.lat]));
  };

  return (
    <div>
      <div className="border border-2 p-2">
        <div className="flex justify-between">
          <div className="text-3xl font-medium">{selectedAsset.name}</div>
          <div className="flex align-middle pr-2">
            <button onClick={() => handleAssetNav(-1)}>
              <BiSolidLeftArrow
                color="grey"
                className="align-middle"
                fontSize="1.5em"
              />
            </button>
            <button onClick={() => handleAssetNav(1)}>
              <BiSolidRightArrow
                color="grey"
                className="align-middle"
                fontSize="1.5em"
              />
            </button>
          </div>
        </div>
        <div className="font-medium">{selectedAsset?.client}</div>
        <div className="flex">
          <div className="flex space-x-4 mr-5">
            <FaMapLocationDot fontSize="1.5em" />
            <div>
              {selectedAsset?.lng.toFixed(3)}, {selectedAsset?.lat.toFixed(3)}
            </div>
          </div>
          <div className="flex space-x-2">
            {selectedAsset?.value && (
              <>
                <BiDollar fontSize="1.5em" />
                <div>
                  {selectedAsset?.value?.value?.toFixed(2)}{" "}
                  {selectedAsset?.value.currency}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* <table>
        <tbody>
          {displayPropertySchema.map((prop) => {
            return (
              <tr key={prop.id} className="">
                <td className="font-bold pr-2">{prop.title}: </td>
                <td>
                  {prop.number
                    ? selectedAsset[prop.id]
                        .toString()
                        .substring(0, prop.clipLength)
                    : selectedAsset[prop.id]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> */}
      <AssetForecastTabs />
      {/* <AssetProximity /> */}
    </div>
  );
};

export default AssetDetail;

// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as CopyIcon } from "../assets/icons/copy-icon.svg";
import { ReactComponent as DotsVertical } from "../assets/icons/dots-vertical.svg";
import { ReactComponent as NoGroupsIcon } from "../assets/icons/no-groups-icon.svg";
import { ReactComponent as RightArrow } from "../assets/icons/right-arrow.svg";
import { ReactComponent as GroupIcon } from "../assets/icons/group-icon.svg";
import { AssetCount } from "./TagStyles";
import { setModalState, modalViewsConst } from "../store/modalSlice";
import { useSelector } from "react-redux";
import { fetchAssetsByGroupId } from "../store/assetsSlice";

export const AssetGroupFolder = ({ assetGroup }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groupSettingsRef = useRef();
  const [settingDialogueOpen, setSettingDialogueOpen] = useState(false);
  const { assets } = useSelector(({ assets }) => ({
    assets,
  }));

  useEffect(() => {
    // error handling here
    if (!assets.dataGroups[assetGroup.id]) {
      dispatch(fetchAssetsByGroupId(assetGroup.id));
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        groupSettingsRef.current &&
        !groupSettingsRef.current.contains(event.target)
      ) {
        setSettingDialogueOpen(false); // Clicked outside the div
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [groupSettingsRef]);

  const handleGroupSettings = (e) => {
    setSettingDialogueOpen(!settingDialogueOpen);
    e.stopPropagation();
  };

  const handleOuterDiv = (e) => {
    navigate(`/home/group/${assetGroup.id}`);
  };

  const handleEdit = (e) => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.editAssetGroup,
        properties: assetGroup,
      })
    );

    e.stopPropagation();
  };

  const handleDelete = (e) => {
    // CRAIG DO THIS LATER

    // setSettingDialogueOpen(false);
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.deleteAssetGroup,
        properties: assetGroup,
      })
    );

    e.stopPropagation();
  };

  return (
    <>
      <div
        onClick={handleOuterDiv}
        // onClick={() => navigate(`/home/group/${assetGroup.id}`)}
        className="cursor-pointer hover:border-gray-500 transition flex-row justify-center max-h-[250px] w-full sm:w-[300px] bg-eai-bg-lt2 bg-pattern-01 bg-center rounded-md border border-gray-200 m-3"
        // className="cursor-pointer hover:border-gray-500 transition flex-row justify-center max-h-[250px] w-full sm:w-[300px] bg-eai-bg-lt2 bg-pattern-01 bg-center rounded-md border border-gray-200 m-3"
      >
        <div className="flex w-10 h-10 bg-white rounded-md shadow border border-gray-200 m-3 mb-20 items-center justify-center">
          <GroupIcon className="h-6 w-6" />
        </div>
        <div className="bg-white bottom-div rounded-b-md p-3">
          <div className="flex flex-row grow">
            <div className="flex flex-col grow">
              <div className="text-gray-900 text-lg font-semibold font-Inter">
                {assetGroup.name}
              </div>

              <AssetCount
                count={assets?.dataGroups?.[assetGroup.id]?.length ?? 0}
              />
            </div>

            <div ref={groupSettingsRef} onClick={handleGroupSettings}>
              <div
                style={{ transform: "translateX(-65px) translateY(-100px)" }}
                className="absolute bg-white border hover:border-gray-200"
              >
                {settingDialogueOpen && (
                  <div>
                    <div
                      onClick={handleEdit}
                      className="p-2 border border-white hover:border-gray-200 "
                    >
                      Edit
                    </div>
                    <div
                      onClick={handleDelete}
                      className="p-2 border border-white hover:border-gray-200 "
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
              <DotsVertical className="hover:border" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const NoAssetGroup = ({ handleAddAssetGroup }) => {
  return (
    <>
      <div className="min-w-[400px] border rounded-md flex flex-col grow py-8 justify-center items-center">
        <NoGroupsIcon />
        <div className="text-gray-900 text-lg font-semibold font-Inter">
          No Asset Groups
        </div>
        <div className="w-[245px] text-center text-gray-500 text-sm mb-10 font-normal font-Inter">
          Create an asset group to begin creating asset profiles.
        </div>

        <div
          onClick={() => handleAddAssetGroup()}
          className="cursor-pointer flex flex-row justify-center items-center p-3 bg-white rounded-xl border border-gray-200"
        >
          <div className="w-12 h-12 shrink-0 flex items-center justify-center mr-3 bg-white rounded-[10px] border border-gray-200">
            <GroupIcon className="w-6 h-6" />
          </div>
          <div className="flex flex-col grow justify-center">
            <div className="text-gray-900 mb-1 text-base font-semibold font-Inter">
              Create an Asset Group
            </div>
            <div className="text-gray-400 text-sm font-normal font-Inter">
              Before you add an asset, create your first group.{" "}
            </div>
          </div>
          <div className="w-12 h-12 shrink-0 flex items-center justify-center ml-3">
            <RightArrow className="w-6 h-6" />
          </div>
        </div>
      </div>
    </>
  );
};

// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setModalOpen } from "../../store/modalSlice";
import { ReactComponent as GroupIcon } from "../../assets/icons/group-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import {
  postAssetGroup,
  editAssetGroupFunc,
} from "../../store/assetGroupSlice";
import { z } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const AssetGroupNameSchema = z.object({
  name: z.string().trim().min(2).max(50),
});
type AssetGroupNameType = z.infer<typeof AssetGroupNameSchema>;

const NewAssetGroupModal = ({ editFlag = false, initialAssetGroup }) => {
  const [formStep, setFormStep] = useState(1);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AssetGroupNameType>({
    resolver: zodResolver(AssetGroupNameSchema),
    mode: "onChange",
  });
  const onSubmit: SubmitHandler<SignUpSchemaType> = async (data) => {
    let nextStep = (formStep + 1) % 2 === 0 ? 2 : 1;

    if (editFlag) {
      await dispatch(editAssetGroupFunc({ id: initialAssetGroup.id, ...data }));
    } else {
      //   setFormStep(nextStep);
      await dispatch(postAssetGroup(data));
    }
    dispatch(setModalOpen(false));
  };

  useEffect(() => {
    if (editFlag) {
      setValue("name", initialAssetGroup.name);
    }
  }, []);

  const handleCancel = () => {
    dispatch(setModalOpen(false));
  };

  return (
    <div className="">
      <div
        onClick={() => dispatch(setModalOpen(false))}
        className=" flex flow-row grow items-center border-b border-gray-200 p-5"
      >
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <GroupIcon className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-600 text-md font-normal font-Inter mr-3 justify-end">
          Step 1 of 2
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>

      <div className="h-1 flex grow">
        <div className="h-1 w-1/2 bg-primary-500"></div>
      </div>
      <div className="flex flex-col grow p-5">
        <div className="text-gray-900 text-xl font-semibold mb-2 font-Inter">
          {editFlag ? "Existing" : "New"} Asset Group
        </div>
        <div className="text-gray-400 text-md mb-5 font-normal font-Inter">
          Please enter a name for this group
        </div>
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px] flex justify-between">
          <span>Group Name</span>
          {errors.name ? <span>{errors.name.message}</span> : <span></span>}
        </div>
        {/* <div className="flex grow"> */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          id="asset-group-name-form"
          className="flex grow"
        >
          <input
            // name="name"
            {...register("name")}
            className="flex grow border p-3 text-md rounded-md"
          />
        </form>
      </div>
      <div className="flex flex-row grow space-x-3 p-5">
        <button className="flex-grow eai-btn my-1" type="submit">
          <div className="mx-3" onClick={handleCancel}>
            Cancel
          </div>
        </button>
        <button
          // onClick={() => handleSubmit()}
          className="flex-grow eai-btn-grn my-1"
          type="submit"
          form="asset-group-name-form"
        >
          <div className="mx-3">Create</div>
        </button>
      </div>
    </div>
  );
};

export default NewAssetGroupModal;

// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateClient } from "aws-amplify/api";
import { listUsersCustom, getUserCustom } from "../graphql/customQueries";

export const authenticationState = {
  unfetched: "unfetched", //initial state
  unAuthenticated: "unAuthenticated",
  authenticated: "authenticated",
};

const initialState = {
  status: authenticationState.unfetched,
  details: {},
  permissionDetails: {},
};

const client = generateClient();

// Remove this enventually
export const getMeUser = createAsyncThunk(
  "user/getDetails",
  async (id, thunkAPI) => {
    try {
      // Craig, later get based off of ID, fetch user, get Id, then fetch
      const { data } = await client.graphql({
        query: getUserCustom,
        variables: { id },
      });

      return data.getUser;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const UserSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.status = authenticationState.authenticated;
      state.details = action.payload;
    },
    logOutUser: (state, action) => {
      state.status = authenticationState.unAuthenticated;
      state.details = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMeUser.pending, (state, action) => {
        //newHire
      })
      .addCase(getMeUser.fulfilled, (state, action) => {
        //newHire
        state.permissionDetails = action.payload;
      })
      .addCase(getMeUser.rejected, (state, action) => {
        //newHire
      });
  },
});

export const { setUserDetails, logOutUser } = UserSlice.actions;
export default UserSlice.reducer;

// @ts-nocheck
import React from "react";
import GraphAnnual from "./GraphAnnual";

const GraphCollection = ({ graphDataCollection }) => {
  return (
    <div>
      {Object.keys(graphDataCollection).map((key) => {
        return (
          <div key={key}>
            <div>{key}</div>
            <GraphAnnual title={key} graphData={graphDataCollection[key]} />
          </div>
        );
      })}
    </div>
  );
};

export default GraphCollection;

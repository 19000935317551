// @ts-nocheck
import React, { useEffect, useRef } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const Geocoder = ({ onSelected }) => {
  const geocoderContainerRef = useRef(null);

  useEffect(() => {
    const geocoder = new MapboxGeocoder({
      accessToken:
        "pk.eyJ1IjoiY3JhaWdtYWMwMDciLCJhIjoiY2xkbHNlZ3h1MDE2bjNxcGJjYWo5YzRtMCJ9.ezesyu3cASMlnZlJLMkZwg",
      mapboxgl: mapboxgl,
    });

    geocoder.on("result", (e) => {
      // e.result contains the geocoded result.
      // For example, you can retrieve the coordinates like this:
      const coordinates = e.result.geometry.coordinates;
      console.log("Selected coordinates:", coordinates);
      if (onSelected) {
        onSelected(coordinates);
      }
    });

    geocoderContainerRef.current.appendChild(geocoder.onAdd());

    return () => geocoder.onRemove();
  }, [onSelected]);

  return <div ref={geocoderContainerRef} />;
};

export default Geocoder;

// @ts-nocheck
import React, { useState } from "react";
import AssetProximity from "../../TabViewer/AssetsTab/AssetProximity";

const AFNow = () => {
  const [dayIndex, setDayIndex] = useState(6);
  return (
    <div>
      <div className="border-2 border mt-2">
        <div className="text-lg p-2 font-bold">Current Status</div>
        <div className="p-2">Toggle Placeholder</div>

        <div className="flex justify-between border-b-2">
          <div className="">
            {[1, 2, 3, 4, 5, 6, 7].map((num, i) => (
              <button
                onClick={() => setDayIndex(i)}
                className={`p-1 ${dayIndex === i ? "font-semibold" : ""}`}
              >
                {num}D
              </button>
            ))}
          </div>
          <div className="p-1">LOOKBACK WINDOW</div>
        </div>
        <div className="p-2">
          <AssetProximity />
        </div>
      </div>
    </div>
  );
};

export default AFNow;

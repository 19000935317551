// @ts-nocheck
import React, { useMemo } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { Scatter, Line, Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

import { createTickCallback } from "./graphutil";
import { dateFormatZone } from "./forecastUtil";

// This contains code for processed Models
ChartJS.register(...registerables, annotationPlugin, zoomPlugin);

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const hexColors = [
  "#FF5733",
  "#16537e", //def keep
  "#3357FF",
  "#FF33A1",
  "#A133FF",
  "#33FFF1",
  "#FF5733",
  "#FFC300",
  "#DAF7A6",
  "#C70039",
];

const preSetChannelColors = {
  mean: "#EF5350",
};

// Craig Adjust these
const percentileColors = {
  3: ["#e0e6c5", "#8ba043", "#e0e6c5"],
  5: ["#cfd5b3", "#abb57b", "#5e692e", "#abb57b", "#cfd5b3"],
  7: [
    "#e0e6c5",
    "#c2d08f",
    "#a5b962",
    "#8ba043",
    "#a5b962",
    "#c2d08f",
    "#e0e6c5",
  ],
  9: [
    "#e0e6c5",
    "#c2d08f",
    "#a5b962",
    "#8ba043",
    "#71882a",
    "#8ba043",
    "#a5b962",
    "#c2d08f",
    "#e0e6c5",
  ],
};

const areNumbersSwitched = (str1, str2) => {
  // Extract the first 5 characters and split by "-"
  const [num1a, num1b] = str1.substring(0, 5).split("-");
  const [num2a, num2b] = str2.substring(0, 5).split("-");

  // Check if the numbers are switched
  return num1a === num2b && num1b === num2a;
};

// Custom plugin to draw the vertical line CRAIG MOVE TO UTIL
const verticalLineIntersect = {
  id: "verticalLine",
  afterDraw: (chart) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx;
      const activePoint = chart.tooltip._active[0];
      const x = activePoint.element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
      ctx.stroke();
      ctx.restore();
    }
  },
};

const ProcessedScatterGraph = React.memo(
  ({
    percentiles,
    otherChannels,
    labels,
    yaxis,
    timeZone,
    lineTension = 0.2,
  }) => {
    // Craig Extract to a util
    const maxYValue = useMemo(
      () =>
        [...Object.values(percentiles), ...Object.values(otherChannels)]
          .flat()
          .reduce((max, item) => Math.max(max, item?.y), 0) * 1.1,
      [percentiles, otherChannels]
    );

    const maxXValue = [
      ...Object.values(percentiles),
      ...Object.values(otherChannels),
    ]
      .flat()
      .reduce((max, item) => Math.max(max, new Date(item?.x).getTime()), 0);

    const minXValue = [
      ...Object.values(percentiles),
      ...Object.values(otherChannels),
    ]
      .flat()
      .reduce(
        (min, item) => Math.min(min, new Date(item?.x).getTime()),
        Infinity
      );

    const handleToggleVisibility = (e, legendItem) => {
      const index = legendItem.datasetIndex;
      const ci = e.chart;
      const meta = ci.getDatasetMeta(index);

      // Toggle the visibility
      meta.hidden = !meta.hidden;

      // Also toggle other datasets with the same group label
      ci.data.datasets.forEach((dataset, i) => {
        if (areNumbersSwitched(dataset.label, legendItem.text)) {
          ci.getDatasetMeta(i).hidden = meta.hidden;
        }
      });

      ci.update();
    };

    const options = useMemo(
      () => ({
        tension: lineTension,
        interaction: {
          mode: "nearest",
          intersect: false,
          axis: "x",
        },
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true, // Enable zooming with the mouse wheel
              },
              mode: "x", // Only allow zooming on the x-axis
            },
            limits: {
              x: {
                min: minXValue,
                max: maxXValue,
              },
            },
            pan: {
              enabled: true,
              mode: "x", // Only allow panning on the x-axis
            },
          },
          tooltip: {
            enabled: true,
            mode: "nearest", // Display the tooltip for all items in the index (stack)
            axis: "x",
            intersect: false, // Ensure the tooltip appears even if the mouse isn't directly over a dataset
            callbacks: {
              // Customize the tooltip
              beforeTitle: function (context) {
                // Optional: Add content before the title
              },
              title: function (context) {
                const newTimeDate = new Date(context[0].parsed.x);

                const { year, month, date, hour, minute, intTimeZone } =
                  dateFormatZone(newTimeDate, timeZone); //CRAIG FIX THIS
                return `${date}-${month}-${year} ${hour}:${minute} ${intTimeZone}`;
              },
              label: function (context) {
                // Customizing label to show desired data for each point in the stack
                const label = context.dataset.label || "";
                const value = context.parsed.y.toFixed(3);

                const regex = /^(\d+)-\d+ Percentile$/;
                const match = label.match(regex);

                // If a match is found, return the first captured group followed by " Percentile"
                if (match) {
                  return `${match[1]} Percentile: ${value}`;
                }

                return `${label}: ${value}`;
              },
              afterLabel: function (context) {
                // Optional: Add content after each label
              },
              footer: function (context) {
                // Optional: Add footer content
              },
            },
          },

          verticalLine: {},
          annotation: {
            annotations: {
              currentUTCTime: {
                type: "line",
                xMin: new Date().toISOString(),
                xMax: new Date().toISOString(),
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 2,
                label: {
                  display: true,
                  content: () => {
                    const newTimeDate = new Date();
                    const { year, month, date, hour, minute, intTimeZone } =
                      dateFormatZone(newTimeDate, timeZone);
                    return `${date}-${month}-${year} ${hour}:${minute} ${intTimeZone}`;
                  },
                  enabled: true,
                  position: "end",
                },
              },
            },
          },
          legend: {
            onClick: handleToggleVisibility,
            labels: {
              filter: function (legendItem, chartData) {
                // Hide 'Dataset 2' from the legend
                const firstFive = legendItem.text.substring(0, 5);
                const percentileFlag = firstFive.match(/(\d+)-(\d+)/);
                if (!percentileFlag) {
                  return true;
                }
                const [first, second] = firstFive.split("-");

                return second >= first;
              },
            },
          },
        },
        maintainAspectRatio: false,
        spanGaps: true,
        scales: {
          x: {
            stacked: true,
            display: true,
            type: "time",

            title: {
              display: true,
              text: "Date Time",
            },
            ticks: {
              callback: createTickCallback(),
            },
          },

          y: {
            display: true,
            title: {
              display: true,
              text: yaxis,
            },
            max: Math.ceil(maxYValue),
          },
        },
      }),
      [maxYValue, lineTension, timeZone]
    );

    const boxplotData = useMemo(() => {
      const datasets = [
        ...Object.keys(otherChannels).map((channel, jindex) => {
          let color;
          if (Object.keys(preSetChannelColors).includes(channel)) {
            color = preSetChannelColors[channel];
          } else {
            color = hexColors[jindex]; //default
          }

          return {
            label: channel.charAt(0).toUpperCase() + channel.slice(1),
            type: "line",
            radius: 0,
            backgroundColor: color,
            borderColor: color,
            data: otherChannels[channel],
          };
        }),

        ...Object.keys(percentiles).map((key, index) => {
          const channelCount = Object.keys(percentiles).length;
          const percentile = parseInt(key.replace("p", ""));
          const oppositePercentile = 100 - percentile;
          let label = `${percentile}-${oppositePercentile} Percentile`;
          if (percentile === oppositePercentile) {
            label = `${percentile} Percentile`;
          }
          return {
            label,
            type: "line",
            data: percentiles[key],
            radius: 0,
            backgroundColor:
              percentileColors[Object.keys(percentiles).length][index], // Customize as needed
            borderColor:
              percentileColors[Object.keys(percentiles).length][index], // Customize as needed
            borderWidth: 3.5,

            fill:
              Math.floor(channelCount / 2) === index
                ? false
                : index < Math.floor(channelCount / 2)
                ? `+1`
                : "-1", // Fill down to the next dataset
          };
        }),
      ];
      return { datasets };
    }, [percentiles, otherChannels]);

    if (!percentiles && !otherChannels) {
      return false;
    }

    if (percentiles || otherChannels) {
      return (
        <Line
          // onMouseOut={() => dispatch(setAlertHighlightIndex(-1))}
          className="flex-1"
          options={options}
          data={boxplotData}
          plugins={[verticalLineIntersect]}
        />
      );
    }
    return false;
  }
);

export default ProcessedScatterGraph;

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAssets, setSelectedAsset } from "../../../store/assetsSlice";
import { setFlyLocation } from "../../../store/mapStateSlice";
import AssetList from "./AssetList";
import AssetToolBar from "./AssetToolBar";
import AssetDetail from "./AssetDetail";
import FlyToButton from "../../FlyToButton";

const AssetsTab = () => {
  const assets = useSelector((state) => state.assets);
  const dispatch = useDispatch();
  const selectedAsset = useSelector(({ assets }) => assets.selectedAsset);

  const handleAssetClick = (asset) => {
    dispatch(setSelectedAsset(asset)); //CRAIG RENAME
    dispatch(setFlyLocation([asset.lng, asset.lat]));
  };

  if (assets.loading !== "success") {
    return <div>Pending</div>;
  }

  return (
    <div className="overflow-y-scroll max-h-[70vh]">
      <div className="text-xl font-normal py-2">Add or Remove Assets</div>
      <div className="text-sm font-montserrat pb-2">
        Use the buttons to add or remove assets from your asset portfolio.
      </div>
      <AssetToolBar />
      {selectedAsset ? (
        <div>
          <button
            className="eai-btn"
            onClick={() => dispatch(setSelectedAsset(null))}
          >
            Cancel
          </button>
          <FlyToButton
            size={1.3}
            lngLat={[selectedAsset.lng, selectedAsset.lat]}
          />
        </div>
      ) : (
        <>
          <div className="text-xl font-normal py-2">Existing Assets</div>
          <div className="text-sm font-montserrat py-2">
            A list of your existing assets is provided below. Click on an asset
            for additional functionality.
          </div>
        </>
      )}
      {selectedAsset ? (
        <AssetDetail selectedAsset={selectedAsset} />
      ) : (
        <AssetList assets={assets.data} onAssetClick={handleAssetClick} />
      )}
    </div>
  );
};

export default AssetsTab;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      name
      cognitoIdentityId
      geoPoints {
        nextToken
        __typename
      }
      assetGroups {
        nextToken
        __typename
      }
      assetGroupCount
      assetCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        email
        name
        cognitoIdentityId
        assetGroupCount
        assetCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetGroup = /* GraphQL */ `
  query GetAssetGroup($id: ID!) {
    getAssetGroup(id: $id) {
      id
      name
      profilePictureUrl
      description
      assets {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userAssetGroupsId
      owner
      __typename
    }
  }
`;
export const listAssetGroups = /* GraphQL */ `
  query ListAssetGroups(
    $filter: ModelAssetGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        profilePictureUrl
        description
        createdAt
        updatedAt
        userAssetGroupsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeoPoint = /* GraphQL */ `
  query GetGeoPoint($id: ID!) {
    getGeoPoint(id: $id) {
      id
      name
      lat
      lng
      elevation
      client
      value {
        value
        currency
        __typename
      }
      description
      processedModels {
        nextToken
        __typename
      }
      assetGroupId
      freqAnalysis {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      precipCurveId
      precipCurve {
        id
        name
        description
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      windCurveId
      windCurve {
        id
        name
        description
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      solarCurveId
      solarCurve {
        id
        name
        description
        xUnit
        yUnit
        dcCap
        acCap
        panelEfficiency
        thermalLoss
        minIrradiance
        trackingType
        arrayTilt
        arrayAzimuth
        maxTilt
        groundCoverRatio
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      userGeoPointsId
      owner
      __typename
    }
  }
`;
export const listGeoPoints = /* GraphQL */ `
  query ListGeoPoints(
    $filter: ModelGeoPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeoPoints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const geoPointsByAssetGroupId = /* GraphQL */ `
  query GeoPointsByAssetGroupId(
    $assetGroupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGeoPointFilterInput
    $limit: Int
    $nextToken: String
  ) {
    geoPointsByAssetGroupId(
      assetGroupId: $assetGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFreqAnalysis = /* GraphQL */ `
  query GetFreqAnalysis($id: ID!) {
    getFreqAnalysis(id: $id) {
      id
      assetId
      name
      radius
      minYear
      maxYear
      minimumRecordLength
      plotPoints {
        x
        y
        __typename
      }
      stations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFreqAnalyses = /* GraphQL */ `
  query ListFreqAnalyses(
    $filter: ModelFreqAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFreqAnalyses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assetId
        name
        radius
        minYear
        maxYear
        minimumRecordLength
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const freqAnalysesByAssetId = /* GraphQL */ `
  query FreqAnalysesByAssetId(
    $assetId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFreqAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    freqAnalysesByAssetId(
      assetId: $assetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        name
        radius
        minYear
        maxYear
        minimumRecordLength
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStation = /* GraphQL */ `
  query GetStation($stationId: String!) {
    getStation(stationId: $stationId) {
      id
      stationId
      name
      lat
      lng
      elevM
      minYear
      maxYear
      climateId
      source
      yearlyMax {
        nextToken
        __typename
      }
      forecast {
        nextToken
        __typename
      }
      freqAnalysis {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStations = /* GraphQL */ `
  query ListStations(
    $stationId: String
    $filter: ModelStationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStations(
      stationId: $stationId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        stationId
        name
        lat
        lng
        elevM
        minYear
        maxYear
        climateId
        source
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStationYearlyRainMax = /* GraphQL */ `
  query GetStationYearlyRainMax($id: ID!) {
    getStationYearlyRainMax(id: $id) {
      id
      stationId
      date
      year
      precip_mm
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStationYearlyRainMaxes = /* GraphQL */ `
  query ListStationYearlyRainMaxes(
    $filter: ModelStationYearlyRainMaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStationYearlyRainMaxes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stationId
        date
        year
        precip_mm
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const stationYearlyRainMaxesByStationIdAndYear = /* GraphQL */ `
  query StationYearlyRainMaxesByStationIdAndYear(
    $stationId: String!
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStationYearlyRainMaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stationYearlyRainMaxesByStationIdAndYear(
      stationId: $stationId
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stationId
        date
        year
        precip_mm
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStationRiskForecast = /* GraphQL */ `
  query GetStationRiskForecast($id: ID!) {
    getStationRiskForecast(id: $id) {
      id
      stationId
      year
      risk
      p10
      p50
      p90
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStationRiskForecasts = /* GraphQL */ `
  query ListStationRiskForecasts(
    $filter: ModelStationRiskForecastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStationRiskForecasts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stationId
        year
        risk
        p10
        p50
        p90
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const stationRiskForecastsByStationIdAndYear = /* GraphQL */ `
  query StationRiskForecastsByStationIdAndYear(
    $stationId: String!
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStationRiskForecastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stationRiskForecastsByStationIdAndYear(
      stationId: $stationId
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stationId
        year
        risk
        p10
        p50
        p90
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLoginAccessCodes = /* GraphQL */ `
  query GetLoginAccessCodes($code: String!) {
    getLoginAccessCodes(code: $code) {
      code
      recipient
      notes
      available
      activatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLoginAccessCodes = /* GraphQL */ `
  query ListLoginAccessCodes(
    $code: String
    $filter: ModelLoginAccessCodesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLoginAccessCodes(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        recipient
        notes
        available
        activatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataUpdate = /* GraphQL */ `
  query GetDataUpdate($id: ID!) {
    getDataUpdate(id: $id) {
      topic
      source
      additional
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataUpdates = /* GraphQL */ `
  query ListDataUpdates(
    $filter: ModelDataUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        topic
        source
        additional
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssetAlert = /* GraphQL */ `
  query GetAssetAlert($id: ID!) {
    getAssetAlert(id: $id) {
      id
      occurenceKey
      name
      description
      rainfall
      period
      window
      assetId
      asset {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      emails
      phoneNumbers
      active
      groupAlert
      dataSource
      probability
      lastRun
      assetGroupId
      assetGroup {
        id
        name
        profilePictureUrl
        description
        createdAt
        updatedAt
        userAssetGroupsId
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAssetAlerts = /* GraphQL */ `
  query ListAssetAlerts(
    $filter: ModelAssetAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssetAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        occurenceKey
        name
        description
        rainfall
        period
        window
        assetId
        emails
        phoneNumbers
        active
        groupAlert
        dataSource
        probability
        lastRun
        assetGroupId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetAlertsByAssetId = /* GraphQL */ `
  query AssetAlertsByAssetId(
    $assetId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssetAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetAlertsByAssetId(
      assetId: $assetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        occurenceKey
        name
        description
        rainfall
        period
        window
        assetId
        emails
        phoneNumbers
        active
        groupAlert
        dataSource
        probability
        lastRun
        assetGroupId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const assetAlertsByAssetGroupIdAndAssetId = /* GraphQL */ `
  query AssetAlertsByAssetGroupIdAndAssetId(
    $assetGroupId: ID!
    $assetId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssetAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    assetAlertsByAssetGroupIdAndAssetId(
      assetGroupId: $assetGroupId
      assetId: $assetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        occurenceKey
        name
        description
        rainfall
        period
        window
        assetId
        emails
        phoneNumbers
        active
        groupAlert
        dataSource
        probability
        lastRun
        assetGroupId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPerformanceCurve = /* GraphQL */ `
  query GetPerformanceCurve($id: ID!) {
    getPerformanceCurve(id: $id) {
      id
      name
      description
      curveData {
        x
        y
        __typename
      }
      xUnit
      yUnit
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPerformanceCurves = /* GraphQL */ `
  query ListPerformanceCurves(
    $filter: ModelPerformanceCurveFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPerformanceCurves(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSolarEnergyModel = /* GraphQL */ `
  query GetSolarEnergyModel($id: ID!) {
    getSolarEnergyModel(id: $id) {
      id
      name
      description
      xUnit
      yUnit
      dcCap
      acCap
      panelEfficiency
      thermalLoss
      minIrradiance
      trackingType
      arrayTilt
      arrayAzimuth
      maxTilt
      groundCoverRatio
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSolarEnergyModels = /* GraphQL */ `
  query ListSolarEnergyModels(
    $filter: ModelSolarEnergyModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSolarEnergyModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        xUnit
        yUnit
        dcCap
        acCap
        panelEfficiency
        thermalLoss
        minIrradiance
        trackingType
        arrayTilt
        arrayAzimuth
        maxTilt
        groundCoverRatio
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProcessedModel = /* GraphQL */ `
  query GetProcessedModel($id: ID!) {
    getProcessedModel(id: $id) {
      id
      assetId
      geopoint {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      label
      routeShort
      routeLong
      displayType
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProcessedModels = /* GraphQL */ `
  query ListProcessedModels(
    $filter: ModelProcessedModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProcessedModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assetId
        label
        routeShort
        routeLong
        displayType
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const processedModelsByAssetId = /* GraphQL */ `
  query ProcessedModelsByAssetId(
    $assetId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProcessedModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    processedModelsByAssetId(
      assetId: $assetId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assetId
        label
        routeShort
        routeLong
        displayType
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUploadData = /* GraphQL */ `
  query GetUploadData($id: ID!) {
    getUploadData(id: $id) {
      id
      name
      description
      parameter
      objective
      optimizationHorizon
      temporalResolution
      seasonality
      fileLocation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUploadData = /* GraphQL */ `
  query ListUploadData(
    $filter: ModelUploadDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUploadData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        parameter
        objective
        optimizationHorizon
        temporalResolution
        seasonality
        fileLocation
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFreqAnalysisStations = /* GraphQL */ `
  query GetFreqAnalysisStations($id: ID!) {
    getFreqAnalysisStations(id: $id) {
      id
      freqAnalysisId
      stationStationId
      freqAnalysis {
        id
        assetId
        name
        radius
        minYear
        maxYear
        minimumRecordLength
        createdAt
        updatedAt
        owner
        __typename
      }
      station {
        id
        stationId
        name
        lat
        lng
        elevM
        minYear
        maxYear
        climateId
        source
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listFreqAnalysisStations = /* GraphQL */ `
  query ListFreqAnalysisStations(
    $filter: ModelFreqAnalysisStationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFreqAnalysisStations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freqAnalysisId
        stationStationId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const freqAnalysisStationsByFreqAnalysisId = /* GraphQL */ `
  query FreqAnalysisStationsByFreqAnalysisId(
    $freqAnalysisId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFreqAnalysisStationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    freqAnalysisStationsByFreqAnalysisId(
      freqAnalysisId: $freqAnalysisId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freqAnalysisId
        stationStationId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const freqAnalysisStationsByStationStationId = /* GraphQL */ `
  query FreqAnalysisStationsByStationStationId(
    $stationStationId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelFreqAnalysisStationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    freqAnalysisStationsByStationStationId(
      stationStationId: $stationStationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        freqAnalysisId
        stationStationId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

const presetX = [
  -1.9327894159422434, -1.5293379817722994, -1.1133440539599848,
  -0.8379550354502723, -0.4758849953271107, 0.36651292058166435,
  1.4999399867595158, 2.2503673273124454, 2.9701952490421637,
  3.9019386579358333, 4.600149226776579, 5.295812142535025,
];

const labelsX = [1.001, 1.05, 2, 5, 10, 20, 100, 200];

const options = {
  scales: {
    x: {
      // type: 'logarithmic',
      ticks: {
        callback: (val, index, ticks) => labelsX[index],
      },
    },
  },
};

ChartJS.register(
  LinearScale,
  LogarithmicScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const GraphMultiStation = ({ data, onCalculateAverage, forecasts = [] }) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const xPointsArray = [];
    const graphReadyLineData = Object.keys(data).map((key) => {
      xPointsArray.push(data[key].twentyFourHour.dist_y);

      const plotPoints = data[key].twentyFourHour.dist_y.map((yVal, i) => {
        return { y: yVal, x: data[key].twentyFourHour.dist_x[i] };
      });
      return {
        label: `${key}-line`,
        data: plotPoints,
        showLine: true,
        borderColor: "rgb(75, 192, 192)",
      };
    });

    const calculatedAverage = [];
    for (let i = 0; i < 12; i++) {
      const averagePoint =
        xPointsArray.reduce((acc, curr) => acc + curr[i], 0) /
        xPointsArray.length;
      calculatedAverage.push({ y: averagePoint, x: presetX[i] });
    }
    const calcAverageDataSet = {
      label: `calcAverage`,
      data: calculatedAverage,
      showLine: true,
      borderColor: "#FE0000",
    };

    const graphReadyScatterData = Object.keys(data).map((key) => {
      const plotPoints = data[key].twentyFourHour.hist_x
        .sort((a, b) => a - b)
        .map((xVal, i) => {
          return {
            x: xVal,
            y: data[key].twentyFourHour.hist_y.sort((a, b) => a - b)[i],
          };
        });
      return { label: `${key}-scatter`, data: plotPoints };
    });

    onCalculateAverage(calculatedAverage);
    //CRAIG ask cody is x axis set in stone?

    //Previous Station Saved
    const forecastPlot = forecasts.map((forecast) => {
      return {
        label: `${forecast.createdAt}-line`,
        data: forecast.plotPoints,
        showLine: true,
        borderColor: "rgb(160, 42, 192)",
      };
    });

    setConfig({
      labels: presetX,
      datasets: [
        calcAverageDataSet,
        ...graphReadyLineData,
        ...graphReadyScatterData,
        ...forecastPlot,
      ],
    });
  }, [data, forecasts.length]);

  if (!config) {
    return false;
  }

  return <Scatter options={options} data={config} />;
};

export default GraphMultiStation;

// @ts-nocheck
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setStationFilter } from "../../../store/weatherStationslice";
import { capitalizeWords } from "../../../util/utils";

const stationDetailSchema = [
  {
    key: "name",
    title: "Name",
  },
  {
    key: "stationID",
    title: "Station Id",
  },
  {
    key: "Elev_m",
    title: "Elevation (m)",
  },
  {
    key: "src",
    title: "Source",
  },
  {
    key: "minYear",
    title: "Minimum Recorded Year",
  },
  {
    key: "maxYear",
    title: "Maximum Recorded Year",
  },
  {
    key: "Climate Id",
    title: "ClimateID",
  },
];

const StationDetails = ({ stationDetails }) => {
  const [tempYearFilter, setTempYearFilter] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const weatherStation = useSelector((state) => state.weatherStation);
  const dispatch = useDispatch();

  const handleSetFilter = (e) => {
    dispatch(
      setStationFilter({
        yearFilterOn: true,
        yearFilter: parseInt(tempYearFilter),
      })
    );
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div>
      {stationDetails ? (
        <table>
          <tbody>
            {stationDetailSchema.map((prop) => {
              return (
                <tr key={prop.key} className="">
                  <td className="font-bold pr-2">{prop.title}: </td>
                  <td className="text-center">
                    {prop.key === "name"
                      ? capitalizeWords(stationDetails[prop.key])
                      : stationDetails[prop.key]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div>
          <div className="text-xl font-normal py-2">Station Explorer</div>
          <div className="text-sm font-montserrat pb-2 border-b-2">
            Click a weather station on the map to view station details and
            historical values.
          </div>
          <div className="text-xl font-normal py-2">
            Filter Stations by Year
          </div>
          <div className="text-sm mb-2">
            <b>Current filter: </b>
            {weatherStation.yearFilter}
          </div>
          <div className="text-sm font-montserrat pb-2">
            Use the filter below to only select stations with data for a
            specific year.
          </div>
          <input
            className="mb-2 bg-white border"
            type="number"
            value={tempYearFilter}
            onChange={(e) => setTempYearFilter(e.target.value)}
          />
          <br />
          <button className="eai-btn" onClick={handleSetFilter}>
            Apply Filter
          </button>
          <br />
          <div className="text-sm font-montserrat">
            <label>
              <input
                className="mr-2 border"
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              Toggle filter
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default StationDetails;

import React from "react";

const RestTest = () => {
  const handleTest = async () => {
    console.log("hey!");
  };
  return (
    <div>
      <button onClick={handleTest}>RestTest</button>
    </div>
  );
};

export default RestTest;

// @ts-nocheck
import React from "react";

const AssetLatLon = ({ lat, lng, value }) => {
  return (
    <div className="w-[350px]  border border-gray-200 rounded-lg flex items-center shrink-0 justify-center py-2">
      <div className="w-1/3 flex-row px-4 items-center">
        <div className="text-gray-500 text-xs font-semibold font-Inter mb-1">
          Asset Value
        </div>
        <div className="text-gray-900 text-sm font-medium font-Inter overflow-hidden whitespace-nowrap">
          {value ? (
            value.currency === "MW" ? (
              `${value.value} MW`
            ) : (
              `${value.currency} ${value.value}`
            )
          ) : (
            <span className="invisible">*</span>
          )}
        </div>
      </div>
      <div className="w-1/3 flex-row px-4 border-l border-gray-200">
        <div className="text-gray-500 text-xs font-semibold font-Inter mb-1">
          Latitude
        </div>
        <div className="text-gray-900 text-sm font-medium font-Inter">
          {lat.toFixed(5)}
        </div>
      </div>
      <div className="w-1/3 flex-row px-4 border-l border-gray-200">
        <div className="text-gray-500 text-xs font-semibold font-Inter mb-1">
          Longitude
        </div>
        <div className="text-gray-900 text-sm font-medium font-Inter">
          {lng.toFixed(5)}
        </div>
      </div>
    </div>
  );
};

export default AssetLatLon;

// @ts-nocheck
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
// export const dateFormatZone = (targetDate, format) => {
//   let year, month, date, hour, minute, intTimeZone;
//   if (format === "utc") {
//     year = targetDate.getUTCFullYear();
//     month = months[targetDate.getUTCMonth()]; // getUTCMonth() returns 0-11, so add 1
//     date = targetDate.getUTCDate();
//     hour = targetDate.getUTCHours();
//     minute = targetDate.getUTCMinutes();
//     intTimeZone = "UTC";
//   } else if (format === "local") {
//     year = targetDate.getFullYear();
//     month = months[targetDate.getMonth()]; // getMonth() returns 0-11, just like getUTCMonth()
//     date = targetDate.getDate();
//     hour = targetDate.getHours();
//     minute = targetDate.getMinutes();
//     const timeZoneName = new Intl.DateTimeFormat("en-US", {
//       timeZoneName: "short",
//     }).format(targetDate);
//     // Extract the abbreviation from the formatted string
//     // The output might look like "3/11/2022, GMT+3" or "3/11/2022, PDT", so we try to extract the part after the comma
//     const matches = timeZoneName.match(/\b(\w+)$/);
//     intTimeZone = matches ? matches[1] : "Unknown";
//   }
//   return { year, month, date, hour, minute, intTimeZone };
// };

export const dateFormatZone = (targetDate, timeZone = "UTC") => {
  const options = {
    timeZone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZoneName: "short",
  };

  // Use Intl.DateTimeFormat to format the date according to the provided time zone
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = formatter.formatToParts(targetDate);

  // Extract the relevant parts
  const year = formattedDate.find((part) => part.type === "year").value;
  const monthIndex =
    formattedDate.find((part) => part.type === "month").value - 1;
  const month = months[monthIndex];
  const date = formattedDate.find((part) => part.type === "day").value;
  const hour = formattedDate.find((part) => part.type === "hour").value;
  const minute = formattedDate.find((part) => part.type === "minute").value;
  const intTimeZone = formattedDate.find(
    (part) => part.type === "timeZoneName"
  ).value;

  return { year, month, date, hour, minute, intTimeZone };
};

// @ts-nocheck
import React, { useState, useEffect } from "react";
import { ReactComponent as GroupIcon } from "../assets/icons/group-icon.svg";
import { ReactComponent as SearchIcon } from "../assets/icons/search-sm.svg";
import { ReactComponent as XClose } from "../assets/icons/x-close.svg";
import { ReactComponent as CloudRaining } from "../assets/icons/cloud-raining-05.svg";
import AssetListItem from "./AssetListItem";
import AssetListClipped from "./AssetListClipped";
// import { useState } from "react";

const AssetListNEW = ({ assetList, assetGroup }) => {
  const [filteredList, setFilteredList] = useState(assetList);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (searchValue.trim().length === 0) {
      setFilteredList(assetList);
      return;
    } else {
      const updatedList = assetList.filter((asset) =>
        asset.name.includes(searchValue.trim())
      );
      setFilteredList(updatedList);
    }
  }, [searchValue]);

  return (
    <>
      {/* <div className="flex flex-col"> */}
      <div className=" h-[93px] inline-flex pl-6 py-[20px] ">
        <div>
          <div className="text-gray-900 text-xl font-semibold leading-[30px]">
            My Assets
          </div>
          <div className=" text-gray-400 text-sm font-normal ">
            Monitor the status of your assets below
          </div>
        </div>
      </div>
      <div className=" h-[65px] py-[12px] pl-6 pr-[22px] bg-gray-200">
        <div className="flex flex-row ">
          <div className="justify-center items-center inline-flex">
            <div className="p-2 bg-zinc-700 rounded-md justify-start items-center  inline-flex">
              <div className="w-6 h-6 flex justify-center ">
                <GroupIcon />
              </div>
            </div>
          </div>
          <div className="ml-2 flex-1 items-center">
            <div className="text-gray-900 text-sm font-semibold font-Inter flex justify-between ">
              <div>{assetGroup.name}</div>
              <div>
                <div className="w-6 h-[19px] px-2 bg-slate-400 rounded-[100px] flex justify-center items-center gap-1.5 inline-flex">
                  <div className="text-gray-50 text-xs font-medium font-['Inter'] leading-none">
                    {assetList?.length ?? 1}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-gray-500 text-xs font-normal font-Inter">
              Asset Group
            </div>
          </div>
        </div>
      </div>
      <div className="h-[75px]  py-[16px] pl-[24px] pr-[22px]">
        <div className="  bg-white rounded-lg border border-gray-200 flex mb-4">
          <div className="">
            <div className="p-2    ">
              <div className="w-6 h-6 flex justify-center ">
                <SearchIcon />
              </div>
            </div>
          </div>
          <input
            className="flex-1 rounded-r-lg pl-2 focus:outline-none"
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <div className="">
            <div onClick={() => setSearchValue("")} className="p-2    ">
              <div className="w-6 h-6 flex justify-center ">
                <XClose className="" />
              </div>
            </div>
          </div>
        </div>

        {/* <div className=" h-[35px] p-1.5 bg-gray-200 rounded-lg border border-gray-200 justify-center items-center gap-2 inline-flex">
          <div className="h-7 px-3.5 py-[5px] bg-white rounded-md justify-center items-center gap-2 flex">
            <div className="text-gray-900 text-xs font-semibold font-['Inter'] leading-[18px]">
              All
            </div>
          </div>
          <div className="grow shrink basis-0 h-[38px] px-3.5 py-2.5 rounded-md justify-center items-center gap-2 flex">
            <div className="w-1.5 h-1.5  rounded-full" />
            <div className="text-gray-500 text-xs font-medium font-['Inter'] leading-[18px]">
              Urgent
            </div>
          </div>
          <div className="grow shrink basis-0 h-[38px] px-3.5 py-2.5 rounded-md justify-center items-center gap-2 flex">
            <div className="w-1.5 h-1.5 bg-yellow-400 rounded-full" />
            <div className="text-gray-500 text-xs font-medium font-['Inter'] leading-[18px]">
              Monitor
            </div>
          </div>
          <div className="grow shrink basis-0 h-[38px] px-3.5 py-2.5 rounded-md justify-center items-center gap-2 flex">
            <div className="w-1.5 h-1.5 bg-stone-500 rounded-full" />
            <div className="text-gray-500 text-xs font-medium font-['Inter'] leading-[18px]">
              Clear
            </div>
          </div>
        </div> */}
      </div>
      <div className="flex-1 ">
        <AssetListClipped assetGroup={assetGroup} assetList={filteredList} />
        {/* test */}
        {/* <div className=" overflow-y"> */}

        {/* {[1, 2, 3, 4].map((item) => {
          return <AssetListItem key={item} />;
        })} */}
        {/* </div> */}
      </div>
      {/* </div> */}
    </>
  );
};

export default AssetListNEW;

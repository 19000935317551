// @ts-nocheck
import React, { useEffect } from "react";
import { ReactComponent as LightningIcon } from "../assets/icons/lightning.svg";
import { HeadBreadCrumb } from "../components/AssetBreadCrumb";
import { modalViewsConst, setModalState } from "../store/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchPCurves, fetchSolarEnergyModels } from "../store/pCurveSlice";
import { PCurveTable } from "../components/PerformanceCurve/PCurveTable";

const PerformanceCurvePage = () => {
  const dispatch = useDispatch();
  const pCurve = useSelector((state) => state.pCurve);

  const getPowerCurves = async () => {
    dispatch(fetchPCurves());
    dispatch(fetchSolarEnergyModels());
  };

  useEffect(() => {
    getPowerCurves();
  }, []);

  const handleNewPCurve = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.energyModelSelect,
        properties: null,
      })
    );
  };

  return (
    <>
      <div className="flex-1 flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow">
          <HeadBreadCrumb text="Energy Models">
            <LightningIcon stroke="#6B7280" className=" w-5 h-5 ml-2 mr-1" />
          </HeadBreadCrumb>
          <div className="flex flex-row h-24 border-y border-gray-200 p-5 justify-left items-center">
            <div className="flex grow items-center">
              <div className="w-12 h-12 border border-gray-200 rounded-lg flex items-center shrink-0 justify-center mr-3">
                <LightningIcon stroke="#A2BBBF" />
              </div>
              <div className="flex-col items-center justify-center">
                <div className="text-gray-900 text-xl font-semibold font-Inter mb-1">
                  All Energy Models
                </div>
                <div className="text-gray-500 text-sm font-normal font-Inter ml-1">
                  View and manage energy models, power curves, etc.
                </div>
              </div>
            </div>
            <button
              onClick={handleNewPCurve}
              className="flex-wrap eai-btn-grn my-1"
              type="submit"
            >
              <div className="mx-3">
                <span className="hidden md:block">+ Add Energy Model</span>
                <span className="block md:hidden">+</span>
              </div>
            </button>
          </div>
          <div className="flex grow p-5 h-[calc(100vh_-_9rem)] overflow-y-auto">
            <div className="w-full bg-white overflow-x-auto border border-gray-200 rounded-md">
              {pCurve?.data || pCurve?.solarData ? (
                <PCurveTable
                  solarEnergyRecords={Object.values(pCurve.solarData)}
                  pCurveRecords={Object.values(pCurve.data)}
                />
              ) : (
                <div className="text-center">No data yet</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceCurvePage;

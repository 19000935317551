// @ts-nocheck
import React, { useState, useRef } from "react";
import { NavLink, Outlet } from "react-router-dom";
import whiteLogo from "../assets/images/logo-white-transparent.png";
import useGetMe from "../hooks/useGetMe";
import useOutsideClick from "../hooks/useOutsideClick";

const navSchema = [
  {
    title: "Home",
    route: "/legacy",
  },
  {
    title: "Account",
    route: "/legacy/user",
  },
  {
    title: "Map",
    route: "/legacy/map",
  },
];

export const NavBar = () => {
  // const { user } = useGetMe();
  const [mobileOpenFlag, setMobileOpenFlag] = useState(false);
  const navRef = useRef(null);
  useOutsideClick(navRef, setMobileOpenFlag);
  // console.log("user", user)

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <nav
          ref={navRef}
          className="flex items-center p-2 justify-between flex-wrap bg-eai-dark fixed w-full z-50"
        >
          <div className="flex items-center flex-shrink-0 text-white mr-10">
            <img src={whiteLogo} className="h-8 mr-3 ml-2" />
            <span className="font-normal font-montserrat text-xl tracking-normal uppercase">
              Erode AI
            </span>
          </div>
          <div className="hidden md:flex items-center">
            {navSchema.map((link) => {
              return (
                <NavLink
                  key={link.route}
                  className="transition py-4 px-6 tracking-wide text-center text-base text-white font-montserrat font-normal nav-link uppercase"
                  to={link.route}
                >
                  {link.title}
                </NavLink>
              );
            })}
          </div>
          <div className="block md:hidden m-3">
            <label className="hamburger-menu">
              <input
                type="checkbox"
                checked={mobileOpenFlag}
                onChange={() => setMobileOpenFlag(!mobileOpenFlag)}
              />
            </label>
          </div>
          <div
            className={`sidebar md:hidden transition bg-eai-dark ${
              mobileOpenFlag ? "" : "collapsed"
            }`}
          >
            <div className="items-center flex flex-col ">
              {navSchema.map((link) => {
                return (
                  <NavLink
                    key={link.route}
                    className="transition py-3 w-full tracking-wide text-center text-base text-white font-montserrat font-normal nav-link uppercase"
                    onClick={() => setMobileOpenFlag(false)}
                    to={link.route}
                  >
                    {link.title}
                  </NavLink>
                );
              })}
            </div>
          </div>
        </nav>
        <Outlet />
      </div>
    </>
  );
};

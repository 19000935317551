// @ts-nocheck
import { configureStore } from "@reduxjs/toolkit";
import selectedStationSlice from "./selectedStationSlice";
import assetsSlice from "./assetsSlice";
import rainGridSlice from "./rainGridSlice";
import mapStateSlice from "./mapStateSlice";
import modalStateSlice from "./modalSlice";
import weatherStationslice from "./weatherStationslice";
import tabSlice from "./tabSlice";
import forecastSlice from "./forecastSlice";
import alertSlice from "./alertsSlice";
import assetGroupSlice from "./assetGroupSlice";
import userSlice from "./userSlice";
import pCurveSlice from "./pCurveSlice";

export const store = configureStore({
  reducer: {
    selectedStation: selectedStationSlice,
    assets: assetsSlice,
    rainGrid: rainGridSlice,
    mapState: mapStateSlice,
    modalState: modalStateSlice,
    weatherStation: weatherStationslice,
    tabState: tabSlice,
    forecast: forecastSlice,
    alerts: alertSlice,
    assetGroups: assetGroupSlice,
    user: userSlice,
    pCurve: pCurveSlice,
  },
});

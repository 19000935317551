/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      name
      cognitoIdentityId
      geoPoints {
        nextToken
        __typename
      }
      assetGroups {
        nextToken
        __typename
      }
      assetGroupCount
      assetCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      name
      cognitoIdentityId
      geoPoints {
        nextToken
        __typename
      }
      assetGroups {
        nextToken
        __typename
      }
      assetGroupCount
      assetCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      name
      cognitoIdentityId
      geoPoints {
        nextToken
        __typename
      }
      assetGroups {
        nextToken
        __typename
      }
      assetGroupCount
      assetCount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetGroup = /* GraphQL */ `
  mutation CreateAssetGroup(
    $input: CreateAssetGroupInput!
    $condition: ModelAssetGroupConditionInput
  ) {
    createAssetGroup(input: $input, condition: $condition) {
      id
      name
      profilePictureUrl
      description
      assets {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userAssetGroupsId
      owner
      __typename
    }
  }
`;
export const updateAssetGroup = /* GraphQL */ `
  mutation UpdateAssetGroup(
    $input: UpdateAssetGroupInput!
    $condition: ModelAssetGroupConditionInput
  ) {
    updateAssetGroup(input: $input, condition: $condition) {
      id
      name
      profilePictureUrl
      description
      assets {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userAssetGroupsId
      owner
      __typename
    }
  }
`;
export const deleteAssetGroup = /* GraphQL */ `
  mutation DeleteAssetGroup(
    $input: DeleteAssetGroupInput!
    $condition: ModelAssetGroupConditionInput
  ) {
    deleteAssetGroup(input: $input, condition: $condition) {
      id
      name
      profilePictureUrl
      description
      assets {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userAssetGroupsId
      owner
      __typename
    }
  }
`;
export const createGeoPoint = /* GraphQL */ `
  mutation CreateGeoPoint(
    $input: CreateGeoPointInput!
    $condition: ModelGeoPointConditionInput
  ) {
    createGeoPoint(input: $input, condition: $condition) {
      id
      name
      lat
      lng
      elevation
      client
      value {
        value
        currency
        __typename
      }
      description
      processedModels {
        nextToken
        __typename
      }
      assetGroupId
      freqAnalysis {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      precipCurveId
      precipCurve {
        id
        name
        description
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      windCurveId
      windCurve {
        id
        name
        description
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      solarCurveId
      solarCurve {
        id
        name
        description
        xUnit
        yUnit
        dcCap
        acCap
        panelEfficiency
        thermalLoss
        minIrradiance
        trackingType
        arrayTilt
        arrayAzimuth
        maxTilt
        groundCoverRatio
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      userGeoPointsId
      owner
      __typename
    }
  }
`;
export const updateGeoPoint = /* GraphQL */ `
  mutation UpdateGeoPoint(
    $input: UpdateGeoPointInput!
    $condition: ModelGeoPointConditionInput
  ) {
    updateGeoPoint(input: $input, condition: $condition) {
      id
      name
      lat
      lng
      elevation
      client
      value {
        value
        currency
        __typename
      }
      description
      processedModels {
        nextToken
        __typename
      }
      assetGroupId
      freqAnalysis {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      precipCurveId
      precipCurve {
        id
        name
        description
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      windCurveId
      windCurve {
        id
        name
        description
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      solarCurveId
      solarCurve {
        id
        name
        description
        xUnit
        yUnit
        dcCap
        acCap
        panelEfficiency
        thermalLoss
        minIrradiance
        trackingType
        arrayTilt
        arrayAzimuth
        maxTilt
        groundCoverRatio
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      userGeoPointsId
      owner
      __typename
    }
  }
`;
export const deleteGeoPoint = /* GraphQL */ `
  mutation DeleteGeoPoint(
    $input: DeleteGeoPointInput!
    $condition: ModelGeoPointConditionInput
  ) {
    deleteGeoPoint(input: $input, condition: $condition) {
      id
      name
      lat
      lng
      elevation
      client
      value {
        value
        currency
        __typename
      }
      description
      processedModels {
        nextToken
        __typename
      }
      assetGroupId
      freqAnalysis {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      precipCurveId
      precipCurve {
        id
        name
        description
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      windCurveId
      windCurve {
        id
        name
        description
        xUnit
        yUnit
        createdAt
        updatedAt
        owner
        __typename
      }
      solarCurveId
      solarCurve {
        id
        name
        description
        xUnit
        yUnit
        dcCap
        acCap
        panelEfficiency
        thermalLoss
        minIrradiance
        trackingType
        arrayTilt
        arrayAzimuth
        maxTilt
        groundCoverRatio
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      userGeoPointsId
      owner
      __typename
    }
  }
`;
export const createFreqAnalysis = /* GraphQL */ `
  mutation CreateFreqAnalysis(
    $input: CreateFreqAnalysisInput!
    $condition: ModelFreqAnalysisConditionInput
  ) {
    createFreqAnalysis(input: $input, condition: $condition) {
      id
      assetId
      name
      radius
      minYear
      maxYear
      minimumRecordLength
      plotPoints {
        x
        y
        __typename
      }
      stations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFreqAnalysis = /* GraphQL */ `
  mutation UpdateFreqAnalysis(
    $input: UpdateFreqAnalysisInput!
    $condition: ModelFreqAnalysisConditionInput
  ) {
    updateFreqAnalysis(input: $input, condition: $condition) {
      id
      assetId
      name
      radius
      minYear
      maxYear
      minimumRecordLength
      plotPoints {
        x
        y
        __typename
      }
      stations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFreqAnalysis = /* GraphQL */ `
  mutation DeleteFreqAnalysis(
    $input: DeleteFreqAnalysisInput!
    $condition: ModelFreqAnalysisConditionInput
  ) {
    deleteFreqAnalysis(input: $input, condition: $condition) {
      id
      assetId
      name
      radius
      minYear
      maxYear
      minimumRecordLength
      plotPoints {
        x
        y
        __typename
      }
      stations {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createStation = /* GraphQL */ `
  mutation CreateStation(
    $input: CreateStationInput!
    $condition: ModelStationConditionInput
  ) {
    createStation(input: $input, condition: $condition) {
      id
      stationId
      name
      lat
      lng
      elevM
      minYear
      maxYear
      climateId
      source
      yearlyMax {
        nextToken
        __typename
      }
      forecast {
        nextToken
        __typename
      }
      freqAnalysis {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStation = /* GraphQL */ `
  mutation UpdateStation(
    $input: UpdateStationInput!
    $condition: ModelStationConditionInput
  ) {
    updateStation(input: $input, condition: $condition) {
      id
      stationId
      name
      lat
      lng
      elevM
      minYear
      maxYear
      climateId
      source
      yearlyMax {
        nextToken
        __typename
      }
      forecast {
        nextToken
        __typename
      }
      freqAnalysis {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStation = /* GraphQL */ `
  mutation DeleteStation(
    $input: DeleteStationInput!
    $condition: ModelStationConditionInput
  ) {
    deleteStation(input: $input, condition: $condition) {
      id
      stationId
      name
      lat
      lng
      elevM
      minYear
      maxYear
      climateId
      source
      yearlyMax {
        nextToken
        __typename
      }
      forecast {
        nextToken
        __typename
      }
      freqAnalysis {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStationYearlyRainMax = /* GraphQL */ `
  mutation CreateStationYearlyRainMax(
    $input: CreateStationYearlyRainMaxInput!
    $condition: ModelStationYearlyRainMaxConditionInput
  ) {
    createStationYearlyRainMax(input: $input, condition: $condition) {
      id
      stationId
      date
      year
      precip_mm
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStationYearlyRainMax = /* GraphQL */ `
  mutation UpdateStationYearlyRainMax(
    $input: UpdateStationYearlyRainMaxInput!
    $condition: ModelStationYearlyRainMaxConditionInput
  ) {
    updateStationYearlyRainMax(input: $input, condition: $condition) {
      id
      stationId
      date
      year
      precip_mm
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStationYearlyRainMax = /* GraphQL */ `
  mutation DeleteStationYearlyRainMax(
    $input: DeleteStationYearlyRainMaxInput!
    $condition: ModelStationYearlyRainMaxConditionInput
  ) {
    deleteStationYearlyRainMax(input: $input, condition: $condition) {
      id
      stationId
      date
      year
      precip_mm
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStationRiskForecast = /* GraphQL */ `
  mutation CreateStationRiskForecast(
    $input: CreateStationRiskForecastInput!
    $condition: ModelStationRiskForecastConditionInput
  ) {
    createStationRiskForecast(input: $input, condition: $condition) {
      id
      stationId
      year
      risk
      p10
      p50
      p90
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStationRiskForecast = /* GraphQL */ `
  mutation UpdateStationRiskForecast(
    $input: UpdateStationRiskForecastInput!
    $condition: ModelStationRiskForecastConditionInput
  ) {
    updateStationRiskForecast(input: $input, condition: $condition) {
      id
      stationId
      year
      risk
      p10
      p50
      p90
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStationRiskForecast = /* GraphQL */ `
  mutation DeleteStationRiskForecast(
    $input: DeleteStationRiskForecastInput!
    $condition: ModelStationRiskForecastConditionInput
  ) {
    deleteStationRiskForecast(input: $input, condition: $condition) {
      id
      stationId
      year
      risk
      p10
      p50
      p90
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLoginAccessCodes = /* GraphQL */ `
  mutation CreateLoginAccessCodes(
    $input: CreateLoginAccessCodesInput!
    $condition: ModelLoginAccessCodesConditionInput
  ) {
    createLoginAccessCodes(input: $input, condition: $condition) {
      code
      recipient
      notes
      available
      activatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLoginAccessCodes = /* GraphQL */ `
  mutation UpdateLoginAccessCodes(
    $input: UpdateLoginAccessCodesInput!
    $condition: ModelLoginAccessCodesConditionInput
  ) {
    updateLoginAccessCodes(input: $input, condition: $condition) {
      code
      recipient
      notes
      available
      activatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLoginAccessCodes = /* GraphQL */ `
  mutation DeleteLoginAccessCodes(
    $input: DeleteLoginAccessCodesInput!
    $condition: ModelLoginAccessCodesConditionInput
  ) {
    deleteLoginAccessCodes(input: $input, condition: $condition) {
      code
      recipient
      notes
      available
      activatedBy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDataUpdate = /* GraphQL */ `
  mutation CreateDataUpdate(
    $input: CreateDataUpdateInput!
    $condition: ModelDataUpdateConditionInput
  ) {
    createDataUpdate(input: $input, condition: $condition) {
      topic
      source
      additional
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDataUpdate = /* GraphQL */ `
  mutation UpdateDataUpdate(
    $input: UpdateDataUpdateInput!
    $condition: ModelDataUpdateConditionInput
  ) {
    updateDataUpdate(input: $input, condition: $condition) {
      topic
      source
      additional
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDataUpdate = /* GraphQL */ `
  mutation DeleteDataUpdate(
    $input: DeleteDataUpdateInput!
    $condition: ModelDataUpdateConditionInput
  ) {
    deleteDataUpdate(input: $input, condition: $condition) {
      topic
      source
      additional
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAssetAlert = /* GraphQL */ `
  mutation CreateAssetAlert(
    $input: CreateAssetAlertInput!
    $condition: ModelAssetAlertConditionInput
  ) {
    createAssetAlert(input: $input, condition: $condition) {
      id
      occurenceKey
      name
      description
      rainfall
      period
      window
      assetId
      asset {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      emails
      phoneNumbers
      active
      groupAlert
      dataSource
      probability
      lastRun
      assetGroupId
      assetGroup {
        id
        name
        profilePictureUrl
        description
        createdAt
        updatedAt
        userAssetGroupsId
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateAssetAlert = /* GraphQL */ `
  mutation UpdateAssetAlert(
    $input: UpdateAssetAlertInput!
    $condition: ModelAssetAlertConditionInput
  ) {
    updateAssetAlert(input: $input, condition: $condition) {
      id
      occurenceKey
      name
      description
      rainfall
      period
      window
      assetId
      asset {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      emails
      phoneNumbers
      active
      groupAlert
      dataSource
      probability
      lastRun
      assetGroupId
      assetGroup {
        id
        name
        profilePictureUrl
        description
        createdAt
        updatedAt
        userAssetGroupsId
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteAssetAlert = /* GraphQL */ `
  mutation DeleteAssetAlert(
    $input: DeleteAssetAlertInput!
    $condition: ModelAssetAlertConditionInput
  ) {
    deleteAssetAlert(input: $input, condition: $condition) {
      id
      occurenceKey
      name
      description
      rainfall
      period
      window
      assetId
      asset {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      emails
      phoneNumbers
      active
      groupAlert
      dataSource
      probability
      lastRun
      assetGroupId
      assetGroup {
        id
        name
        profilePictureUrl
        description
        createdAt
        updatedAt
        userAssetGroupsId
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPerformanceCurve = /* GraphQL */ `
  mutation CreatePerformanceCurve(
    $input: CreatePerformanceCurveInput!
    $condition: ModelPerformanceCurveConditionInput
  ) {
    createPerformanceCurve(input: $input, condition: $condition) {
      id
      name
      description
      curveData {
        x
        y
        __typename
      }
      xUnit
      yUnit
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePerformanceCurve = /* GraphQL */ `
  mutation UpdatePerformanceCurve(
    $input: UpdatePerformanceCurveInput!
    $condition: ModelPerformanceCurveConditionInput
  ) {
    updatePerformanceCurve(input: $input, condition: $condition) {
      id
      name
      description
      curveData {
        x
        y
        __typename
      }
      xUnit
      yUnit
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePerformanceCurve = /* GraphQL */ `
  mutation DeletePerformanceCurve(
    $input: DeletePerformanceCurveInput!
    $condition: ModelPerformanceCurveConditionInput
  ) {
    deletePerformanceCurve(input: $input, condition: $condition) {
      id
      name
      description
      curveData {
        x
        y
        __typename
      }
      xUnit
      yUnit
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSolarEnergyModel = /* GraphQL */ `
  mutation CreateSolarEnergyModel(
    $input: CreateSolarEnergyModelInput!
    $condition: ModelSolarEnergyModelConditionInput
  ) {
    createSolarEnergyModel(input: $input, condition: $condition) {
      id
      name
      description
      xUnit
      yUnit
      dcCap
      acCap
      panelEfficiency
      thermalLoss
      minIrradiance
      trackingType
      arrayTilt
      arrayAzimuth
      maxTilt
      groundCoverRatio
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSolarEnergyModel = /* GraphQL */ `
  mutation UpdateSolarEnergyModel(
    $input: UpdateSolarEnergyModelInput!
    $condition: ModelSolarEnergyModelConditionInput
  ) {
    updateSolarEnergyModel(input: $input, condition: $condition) {
      id
      name
      description
      xUnit
      yUnit
      dcCap
      acCap
      panelEfficiency
      thermalLoss
      minIrradiance
      trackingType
      arrayTilt
      arrayAzimuth
      maxTilt
      groundCoverRatio
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSolarEnergyModel = /* GraphQL */ `
  mutation DeleteSolarEnergyModel(
    $input: DeleteSolarEnergyModelInput!
    $condition: ModelSolarEnergyModelConditionInput
  ) {
    deleteSolarEnergyModel(input: $input, condition: $condition) {
      id
      name
      description
      xUnit
      yUnit
      dcCap
      acCap
      panelEfficiency
      thermalLoss
      minIrradiance
      trackingType
      arrayTilt
      arrayAzimuth
      maxTilt
      groundCoverRatio
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createProcessedModel = /* GraphQL */ `
  mutation CreateProcessedModel(
    $input: CreateProcessedModelInput!
    $condition: ModelProcessedModelConditionInput
  ) {
    createProcessedModel(input: $input, condition: $condition) {
      id
      assetId
      geopoint {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      label
      routeShort
      routeLong
      displayType
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProcessedModel = /* GraphQL */ `
  mutation UpdateProcessedModel(
    $input: UpdateProcessedModelInput!
    $condition: ModelProcessedModelConditionInput
  ) {
    updateProcessedModel(input: $input, condition: $condition) {
      id
      assetId
      geopoint {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      label
      routeShort
      routeLong
      displayType
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProcessedModel = /* GraphQL */ `
  mutation DeleteProcessedModel(
    $input: DeleteProcessedModelInput!
    $condition: ModelProcessedModelConditionInput
  ) {
    deleteProcessedModel(input: $input, condition: $condition) {
      id
      assetId
      geopoint {
        id
        name
        lat
        lng
        elevation
        client
        description
        assetGroupId
        precipCurveId
        windCurveId
        solarCurveId
        createdAt
        updatedAt
        userGeoPointsId
        owner
        __typename
      }
      label
      routeShort
      routeLong
      displayType
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUploadData = /* GraphQL */ `
  mutation CreateUploadData(
    $input: CreateUploadDataInput!
    $condition: ModelUploadDataConditionInput
  ) {
    createUploadData(input: $input, condition: $condition) {
      id
      name
      description
      parameter
      objective
      optimizationHorizon
      temporalResolution
      seasonality
      fileLocation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUploadData = /* GraphQL */ `
  mutation UpdateUploadData(
    $input: UpdateUploadDataInput!
    $condition: ModelUploadDataConditionInput
  ) {
    updateUploadData(input: $input, condition: $condition) {
      id
      name
      description
      parameter
      objective
      optimizationHorizon
      temporalResolution
      seasonality
      fileLocation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUploadData = /* GraphQL */ `
  mutation DeleteUploadData(
    $input: DeleteUploadDataInput!
    $condition: ModelUploadDataConditionInput
  ) {
    deleteUploadData(input: $input, condition: $condition) {
      id
      name
      description
      parameter
      objective
      optimizationHorizon
      temporalResolution
      seasonality
      fileLocation
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createFreqAnalysisStations = /* GraphQL */ `
  mutation CreateFreqAnalysisStations(
    $input: CreateFreqAnalysisStationsInput!
    $condition: ModelFreqAnalysisStationsConditionInput
  ) {
    createFreqAnalysisStations(input: $input, condition: $condition) {
      id
      freqAnalysisId
      stationStationId
      freqAnalysis {
        id
        assetId
        name
        radius
        minYear
        maxYear
        minimumRecordLength
        createdAt
        updatedAt
        owner
        __typename
      }
      station {
        id
        stationId
        name
        lat
        lng
        elevM
        minYear
        maxYear
        climateId
        source
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFreqAnalysisStations = /* GraphQL */ `
  mutation UpdateFreqAnalysisStations(
    $input: UpdateFreqAnalysisStationsInput!
    $condition: ModelFreqAnalysisStationsConditionInput
  ) {
    updateFreqAnalysisStations(input: $input, condition: $condition) {
      id
      freqAnalysisId
      stationStationId
      freqAnalysis {
        id
        assetId
        name
        radius
        minYear
        maxYear
        minimumRecordLength
        createdAt
        updatedAt
        owner
        __typename
      }
      station {
        id
        stationId
        name
        lat
        lng
        elevM
        minYear
        maxYear
        climateId
        source
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFreqAnalysisStations = /* GraphQL */ `
  mutation DeleteFreqAnalysisStations(
    $input: DeleteFreqAnalysisStationsInput!
    $condition: ModelFreqAnalysisStationsConditionInput
  ) {
    deleteFreqAnalysisStations(input: $input, condition: $condition) {
      id
      freqAnalysisId
      stationStationId
      freqAnalysis {
        id
        assetId
        name
        radius
        minYear
        maxYear
        minimumRecordLength
        createdAt
        updatedAt
        owner
        __typename
      }
      station {
        id
        stationId
        name
        lat
        lng
        elevM
        minYear
        maxYear
        climateId
        source
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;

// @ts-nocheck
import React, { forwardRef, useState, useEffect } from "react";
import { Source, Layer } from "react-map-gl";
import * as turf from "@turf/turf";
import { useSelector } from "react-redux";

const CircleSelect = forwardRef((props, mapRef) => {
  const [circleProperties, setCircleProperties] = useState(null);

  const assetLayerVisible = useSelector(
    (state) => state.mapState.assetLayerVisible
  );
  const assets = useSelector(({ assets }) => assets);

  useEffect(() => {
    if (assets.selectedAsset && assets.selectedAssetProximity > 0) {
      const center = [assets.selectedAsset.lng, assets.selectedAsset.lat];
      const radius = assets.selectedAssetProximity;
      const options = {
        steps: 50,
        units: "kilometers",
        properties: { foo: "bar" },
      };
      const circle = turf.circle(center, radius, options);

      setCircleProperties(circle);
    }
  }, [assets.selectedAssetProximity, assets.selectedAsset]);

  if (!assets.selectedAsset) {
    return false;
  }

  return (
    <Source id={"CirclSelectSource"} type="geojson" data={circleProperties}>
      <Layer
        visibility={assetLayerVisible ? "visible" : "none"}
        {...{
          id: "assetCircleSelect",
          type: "fill",
          source: "CirclSelectSource",
          paint: {
            "fill-color": "#088",
            "fill-opacity": assetLayerVisible ? 0.3 : 0,
            "fill-outline-color": "green",
          },
        }}
      />
    </Source>
  );
});

export default CircleSelect;

// @ts-nocheck
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import "react-tabs/style/react-tabs.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AssetsTab from "./AssetsTab";
import SelectedStationTab from "./SelectedStationTab";
import ForecastTab from "./ForecastTab";
import RainGridTab from "./RainGridTab";
import { setTabIndex } from "../../store/tabSlice";

const TabViewer = () => {
  const dispatch = useDispatch();

  const tabIndex = useSelector((state) => state.tabState.tabIndex);

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={(index) => dispatch(setTabIndex(index))}
    >
      <TabList>
        <Tab className="cursor-pointer px-2 py-1">Assets</Tab>
        <Tab className="cursor-pointer px-2 py-1">Stations</Tab>
        <Tab className="cursor-pointer px-2 py-1">Forecasts</Tab>
        <Tab className="cursor-pointer px-2 py-1">Historical</Tab>
        <Tab className="cursor-pointer px-2 py-1">Erosion</Tab>
      </TabList>

      <TabPanel>
        <AssetsTab />
      </TabPanel>
      <TabPanel>
        <SelectedStationTab />
      </TabPanel>
      <TabPanel>
        <ForecastTab />
      </TabPanel>
      <TabPanel>
        <RainGridTab />
      </TabPanel>
      <TabPanel>
        <div className="text-xl font-normal">Erosion Risk Assessment</div>
        <div className="text-sm font-montserrat py-2">
          Check back soon for updates.
        </div>
      </TabPanel>
    </Tabs>
  );
};

export default TabViewer;

// @ts-nocheck
import React from "react";
import PCurveGraph from "../PerformanceCurve/PCurveGraph";
import { useDispatch } from "react-redux";
import {
  modalViewsConst,
  setModalOpen,
  setModalState,
} from "../../store/modalSlice";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as PowerIcon } from "../../assets/icons/lightning.svg";

const PCurveDisplay = ({ graphProperties }) => {
  console.log("curveData", graphProperties);
  const dispatch = useDispatch();

  const handleEdit = (e) => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.editPerformanceCurve,
        properties: graphProperties,
      })
    );
  };
  return (
    <div>
      <div
        onClick={() => dispatch(setModalOpen(false))}
        className=" flex flow-row grow items-center border-b border-gray-200 p-5"
      >
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <PowerIcon stroke="#A2BBBF" className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          {graphProperties.name}
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      <div className="flex">
        <div className="flex-2 p-5">{graphProperties.description}</div>
        <div className="flex-1 py-2">
          <PCurveGraph
            data={graphProperties.curveData}
            xAxisLabel={graphProperties.xUnit}
            yAxisLabel={graphProperties.yUnit}
          />
        </div>
      </div>
      <div className="flex flex-row grow space-x-3 p-5">
        <button className="flex-grow eai-btn my-1" type="submit">
          <div className="mx-3" onClick={() => dispatch(setModalOpen(false))}>
            Cancel
          </div>
        </button>
        <button
          onClick={handleEdit}
          className="flex-grow eai-btn-grn my-1"
          type="submit"
          form="my-form"
        >
          <div className="mx-3">Edit</div>
        </button>
      </div>
    </div>
  );
};

export default PCurveDisplay;

// @ts-nocheck
import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchAuthSession, signOut } from "aws-amplify/auth";

//CRAIG REMOVE
const useGetMe = () => {
  const { data, isLoading, refetch, fetchStatus, isSuccess } = useQuery({
    queryKey: ["currentUser", 0],
    queryFn: async () => {
      const authSession = await fetchAuthSession();
      return {
        groups: authSession?.tokens?.idToken?.payload?.["cognito:groups"],
        email: authSession?.tokens?.idToken?.payload?.email,
        username: authSession?.tokens?.idToken?.payload?.preferred_username,
      };
    },
    retry: 1,
    refetchOnMount: false,
  });

  const signOutMutation = useMutation({
    mutationFn: () => signOut(),
    onSuccess: () => {
      window.location.reload();
    },
    onError: (err) => console.log("error deleting Point", err),
  });

  return {
    user: data,
    isLoading,
    refetch,
    fetchStatus,
    isSuccess,
    signOutMutation,
  };
};

export default useGetMe;

// @ts-nocheck
import React, { useEffect, useState } from "react";
// import { Auth } from "aws-amplify";
import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

const withAdminAuth = (Component) => {
  return (props) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      checkAdminGroup();
    }, []);

    const checkAdminGroup = async () => {
      try {
        const authSession = await fetchAuthSession();
        const groups =
          authSession?.tokens?.idToken?.payload?.["cognito:groups"] ?? [];
        if (groups && groups.includes("Admin")) {
          setIsAdmin(true);
        } else {
          navigate("/unauthorized"); // Redirect to unauthorized page or handle differently
        }
      } catch (error) {
        console.error("Error checking admin group", error);
        navigate("/home"); // Redirect to sign-in page
      }
    };

    return isAdmin ? <Component {...props} /> : null; // Or a loading indicator
  };
};

export default withAdminAuth;

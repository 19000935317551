// @ts-nocheck
import React, { useEffect, forwardRef, useState } from "react";
import Map, { Source, Layer, Popup } from "react-map-gl";
import { useSelector, useDispatch } from "react-redux";
import { setSelectStation } from "../../store/selectedStationSlice";
import { setTabIndex } from "../../store/tabSlice";
import { capitalizeWords } from "../../util/utils";

export const weatherStationLayerId = "WEATHER_STATION";

const getColorExpression = (stationID = "1", idArray) => {
  // Define your color expression based on multiple cases
  return [
    "case",
    ["in", ["get", "stationID"], ["literal", idArray]],
    "black",
    ["==", ["get", "stationID"], stationID],
    "red",
    ["==", ["get", "climateStation"], true],
    "purple",
    "#0096FF", // Default color
  ];
};

const WeatherStationLayer = forwardRef((props, mapRef) => {
  const dispatch = useDispatch();
  const [hoveredStation, setHoveredStation] = useState(null);
  const selectedStation = useSelector((state) => state.selectedStation);
  const assets = useSelector((state) => state.assets);
  const weatherStation = useSelector((state) => state.weatherStation);
  const weatherStationLayerVisible = useSelector(
    (state) => state.mapState.weatherStationLayerVisible
  );
  const [proximityStationIds, setProximityStationIds] = useState([]);

  const [climateStations, setClimateStations] = useState(null);

  //Craig abstract later?
  // const getNorthAmericanClimateStations = async () => {
  //   const file = await Storage.get("NorthAmerica/CAN_US_meta.json", {
  //     // const file = await Storage.get("NorthAmerica/CAN_US_meta_subset.json", {
  //     download: true,
  //   });
  //   const stations = await new Response(file.Body).json();

  //   setClimateStations(stations);
  // };

  // onLoad
  useEffect(() => {
    // getNorthAmericanClimateStations();
  }, []);

  //event listeners
  useEffect(() => {
    mapRef.current.on("click", "unclustered-point", handleClick);
    mapRef.current.on("mouseenter", "unclustered-point", handleMouseEnter);
    mapRef.current.on("mouseleave", "unclustered-point", handleMouseExit);
    return () => {};
  }, []);

  const handleClick = (e) => {
    const features = mapRef.current.queryRenderedFeatures(e.point);

    //CRAIG abstract this out to a util later
    const [stationFeature] = features.filter(
      (feature) => feature.source === weatherStationLayerId
    );
    const clickedStation = stationFeature?.properties;
    if (clickedStation) {
      dispatch(setSelectStation(clickedStation));
      dispatch(setTabIndex(1));
    }
  };

  const handleMouseEnter = (event) => {
    const { features } = event;

    if (features && features.length > 0) {
      const hoveredFeature = features[0];
      setHoveredStation({
        ...hoveredFeature.properties,
        coordinates: hoveredFeature.geometry.coordinates,
      });
    }
  };

  const handleMouseExit = () => {
    setHoveredStation(null);
  };

  if (!climateStations) {
    return false;
  }

  return (
    <>
      <Source
        id={weatherStationLayerId}
        type="geojson"
        data={{ ...climateStations }}
        cluster={true}
        clusterMaxZoom={8} //lower val = more points appear further away,
        clusterRadius={50} //lower val = more clusters, distance between
      >
        <Layer
          {...{
            id: "clusters",
            type: "circle",
            source: weatherStationLayerId,
            filter: ["has", "point_count"],
            paint: {
              "circle-color": [
                "step",
                ["get", "point_count"],
                "#51bbd6",
                100,
                "#f1f075",
                750,
                "#f28cb1",
              ],
              "circle-opacity": weatherStationLayerVisible ? 1 : 0,
              "circle-radius": [
                "step",
                ["get", "point_count"],
                20,
                100,
                30,
                750,
                40,
              ],
            },
          }}
        />
        <Layer
          {...{
            id: "cluster-count",
            type: "symbol",
            source: weatherStationLayerId,

            filter: ["has", "point_count"],
            paint: {
              "text-opacity": weatherStationLayerVisible ? 1 : 0,
            },
            layout: {
              "text-field": "{point_count_abbreviated}",
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 12,
            },
          }}
        />
        <Layer
          {...{
            id: "unclustered-point",
            type: "circle",
            source: weatherStationLayerId,
            filter: [
              "all",
              ["!", ["has", "point_count"]],
              weatherStation.yearFilterOn
                ? ["in", weatherStation.yearFilter, ["get", "dataYears"]]
                : ["!", ["has", "point_count"]],
            ],
            paint: {
              "circle-radius": 7,
              "circle-opacity": weatherStationLayerVisible ? 1 : 0,
              "circle-color": getColorExpression(
                selectedStation?.details?.stationID,
                assets.filteredProximityStationIds
              ),
            },
          }}
        />
      </Source>
      {/* Need this to query proximity to assets. It may be the right thing to do? */}
      <Source
        id={"hiddenWeatherStations"}
        type="geojson"
        data={{ ...climateStations }}
      >
        <Layer
          style={{ cursor: "pointer" }}
          {...{
            id: "unclustered-point-invisible",
            type: "circle",
            source: "hiddenWeatherStations",
            filter: [
              "all",
              ["!", ["has", "point_count"]],
              weatherStation.yearFilterOn
                ? ["in", weatherStation.yearFilter, ["get", "dataYears"]]
                : ["!", ["has", "point_count"]],
            ],
            paint: {
              "circle-radius": 7,
              "circle-color": "rgba(0,0,0,0.0)",
            },
          }}
        />
        {hoveredStation?.coordinates && weatherStationLayerVisible && (
          <Popup
            closeButton={false}
            anchor="bottom-left"
            offset={15}
            latitude={hoveredStation.coordinates[1]}
            longitude={hoveredStation.coordinates[0]}
          >
            <div>Name: {capitalizeWords(hoveredStation.name)}</div>
            <div>Elevation: {hoveredStation.Elev_m} m</div>
            <div>Id: {hoveredStation.stationID}</div>
            <div>Min. Year {hoveredStation.minYear}</div>
            <div>Max. Year {hoveredStation.maxYear}</div>
          </Popup>
        )}
      </Source>
    </>
  );
});

export default WeatherStationLayer;

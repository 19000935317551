// @ts-nocheck
import React, { useState, useEffect } from "react";
import { ReactComponent as GroupIcon } from "../../assets/icons/group-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as HomeSmile } from "../../assets/icons/home-smile.svg";
import { useSelector, useDispatch } from "react-redux";
import { setModalOpen } from "../../store/modalSlice";
import {
  postAsset,
  fetchAssets,
  editAsset,
  fetchAssetsByGroupId,
} from "../../store/assetsSlice";
import MapViewerReact from "../MapViewerReact";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  setFlyLocation,
  setPointExploreVisible,
} from "../../store/mapStateSlice";
import SearchMapBox from "../GeocodingSearchBox";

const latMessage = "range: (-90) - 90";
const lngMessage = "range: (-180) - 180";

const AssetSchema = z.object({
  name: z.string().trim().min(2).max(50),
  assetGroupId: z
    .string()
    .min(5, { message: "Asset must belong to Asset Group" }),
  description: z.string().trim().max(150),
  lat: z.number().max(90, latMessage).min(-90, latMessage),
  lng: z.number().max(180, lngMessage).min(-180, lngMessage),
  elevation: z.number().min(-180), //Cody, mins? can depressions from dikes or similar occur?
});
type AssetType = z.infer<typeof AssetSchema>;

const NewAssetModal = ({ initialAssetState, editFlag }) => {
  const [monetaryValue, setMonetaryValue] = useState({
    value: 0,
    currency: "",
  });
  const [mapViewOpen, setMapViewOpen] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AssetType>({
    defaultValues: {
      name: initialAssetState?.name || "",
      assetGroupId: initialAssetState?.assetGroupId || "",
      description: initialAssetState?.description || "",
      lat: initialAssetState?.lat || 0,
      lng: initialAssetState?.lng || 0,
      elevation: initialAssetState?.elevation || 0,
    },
    resolver: zodResolver(AssetSchema),
    mode: "onChange",
  });

  const [mapViewerLatLng, setMapViewerLatLng] = useState([null, null]);
  const dispatch = useDispatch();
  const { assetGroups, mapState } = useSelector(
    ({ assetGroups, mapState }) => ({ assetGroups, mapState })
  );

  useEffect(() => {
    if (editFlag) {
      if (initialAssetState.value) {
        setMonetaryValue(initialAssetState.value);
      }
    }
    return () => {
      dispatch(setPointExploreVisible({ active: false, lnglat: null }));
    };
  }, []);

  const submitAsset = async (data) => {
    let assetToSubmit = { ...data };

    if (monetaryValue?.currency?.length > 0 && monetaryValue.value > 0) {
      const { value, currency } = monetaryValue;
      assetToSubmit.value = { value, currency };
    } else {
      assetToSubmit.value = null;
    }

    if (editFlag) {
      await dispatch(editAsset({ id: initialAssetState.id, ...assetToSubmit }));
      dispatch(setModalOpen(false));
      dispatch(fetchAssetsByGroupId(initialAssetState.assetGroupId));
    } else {
      await dispatch(postAsset(assetToSubmit));
      dispatch(setModalOpen(false));
      dispatch(fetchAssets());
    }
  };

  const handleFindOnMap = () => {
    setMapViewOpen(true);
  };

  const handleSelectLocation = (e) => {
    const coords = [
      parseFloat(e.lngLat.lng.toFixed(5)),
      parseFloat(e.lngLat.lat.toFixed(5)),
    ];
    setMapViewerLatLng(coords);
    dispatch(setPointExploreVisible({ active: true, lnglat: coords }));
  };

  const handleConfirmMapLatLng = () => {
    setValue("lng", mapViewerLatLng[0]);
    setValue("lat", mapViewerLatLng[1]);
    setMapViewOpen(false);
  };

  const handleGeoCodeSelect = (lngLat) => {
    const lnglat = [
      parseFloat(lngLat[0].toFixed(5)),
      parseFloat(lngLat[1].toFixed(5)),
    ];
    setMapViewerLatLng(lnglat);
    dispatch(setPointExploreVisible({ active: true, lnglat }));
    dispatch(setFlyLocation(lnglat));
  };

  if (mapViewOpen) {
    return (
      <div className="">
        <div className="px-4 py-2">
          <div className="flex justify-between">
            <div>
              <div>Longitude: {mapViewerLatLng[0] ?? ""}</div>
              <div>Latitude: {mapViewerLatLng[1] ?? ""}</div>
            </div>
            <SearchMapBox onSelected={handleGeoCodeSelect} />
          </div>
        </div>
        <MapViewerReact
          defaultLat={mapState?.pointExploreLngLat?.[1]}
          defaultLng={mapState?.pointExploreLngLat?.[0]}
          OnClick={handleSelectLocation}
        />
        <div className="flex flex-row grow space-x-3 p-5">
          <button
            className="flex-grow eai-btn my-1"
            type="submit"
            onClick={() => setMapViewOpen(false)}
          >
            <div className="">Return</div>
          </button>
          <button
            onClick={handleConfirmMapLatLng}
            className={`${
              mapViewerLatLng[0] ? "eai-btn-grn" : "eai-btn cursor-not-allowed"
            } my-1 flex-grow`}
            // type="submit"
            // form="my-form"
          >
            <div className="">Confirm</div>
          </button>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(submitAsset)} className="">
      <div className="flex flow-row grow items-center border-b border-gray-200 p-2">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <HomeSmile className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          {editFlag
            ? `Edit Existing Asset: ${initialAssetState.name}`
            : "New Asset Details"}
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>

      {/* <div className="h-1 flex grow">
        <div className="h-1 w-full bg-primary-500"></div>
      </div> */}
      <div className="flex grow flex-col p-5">
        <div className="flex justify-between">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            Name
          </div>
          {errors.name ? (
            <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              {errors.name.message}
            </span>
          ) : (
            <span></span>
          )}
        </div>
        <div className="flex grow">
          <input
            name="name"
            {...register("name")}
            className="flex grow border p-3 mb-5 text-md rounded-md"
          />
        </div>
        <div
          className="flex justify-end cursor-pointer text-eai-primary-accent font-bold hover:underline"
          onClick={handleFindOnMap}
        >
          Find on map
        </div>

        <div className="flex md:flex-row flex-col gap-2 justify-between">
          <div className="flex-1">
            <div className="flex justify-between">
              <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                Latitude
              </div>
              {errors.lat ? (
                <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  {errors.lat.message}
                </span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="flex-1">
              <input
                name="lat"
                type="number"
                step="any"
                {...register("lat", { valueAsNumber: true })}
                className="flex md:w-[100%] border pl-3 py-3 mb-3 text-md rounded-md"
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between">
              <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                Longitude
              </div>
              {errors.lng ? (
                <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  {errors.lng.message}
                </span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="">
              <input
                type="number"
                name="lng"
                step="any"
                {...register("lng", { valueAsNumber: true })}
                className="flex md:w-[100%] border pl-3 py-3 mb-5 text-md rounded-md"
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between">
              <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                Elevation (m)
              </div>
              {errors.elevation ? (
                <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  {errors.elevation.message}
                </span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="">
              <input
                name="elevation"
                type="number"
                step="any"
                {...register("elevation", { valueAsNumber: true })}
                className="flex md:w-[100%]  border pl-3 py-3 mb-3 text-md rounded-md"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
            Asset Group
          </div>
          {errors.assetGroupId ? (
            <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              {errors.assetGroupId.message}
            </span>
          ) : (
            <span></span>
          )}
        </div>
        <div className="flex grow">
          <select
            name="assetGroupId"
            {...register("assetGroupId")}
            className="flex grow border p-3 mb-5 text-md rounded-md"
          >
            <option value="">Select an Asset Group</option>
            {Object.keys(assetGroups.groups).map((assetGroupId) => (
              <option key={assetGroupId} value={assetGroupId}>
                {assetGroups.groups[assetGroupId].name}
              </option>
            ))}
            {/* Add more options as needed */}
          </select>
        </div>

        <div className="border-t border-gray-200 mb-5"></div>
        <div className="flex gap-2 justify-between">
          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Asset Value
            </div>
            <div className="">
              <input
                step={0.01}
                name="monValue"
                type="number"
                min="0"
                value={monetaryValue.value}
                onChange={(e) =>
                  setMonetaryValue({ ...monetaryValue, value: e.target.value })
                }
                className="flex w-[35vw] sm:w-auto  border pl-3 py-3 mb-3 text-md rounded-md"
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Value Units
            </div>
            <div className="flex-1 flex">
              <select
                name="currency"
                value={monetaryValue.currency}
                onChange={(e) =>
                  setMonetaryValue({
                    ...monetaryValue,
                    currency: e.target.value,
                  })
                }
                className="flex-1 w-[35vw] sm:w-auto border pl-3 py-3 mb-5 text-md rounded-md"
              >
                <option value="">No Measurement</option>
                <option value="USD">USD</option>
                <option value="CAD">CAD</option>
                <option value="MW">MW</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
            Asset Description
          </div>
          {errors.description ? (
            <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              {errors.description.message}
            </span>
          ) : (
            <span></span>
          )}
        </div>
        <div className="flex grow">
          <textarea
            name="description"
            {...register("description")}
            className="flex grow border p-3 mb-2 text-md rounded-md"
          />
        </div>
      </div>
      <div className="flex flex-row grow space-x-3 px-5 pb-5">
        <button className="flex-grow eai-btn my-1" type="submit">
          <div className="mx-3" onClick={() => dispatch(setModalOpen(false))}>
            Cancel
          </div>
        </button>
        <button className="flex-grow eai-btn-grn my-1" type="submit">
          <div className="mx-3">{editFlag ? `Update` : "Create"}</div>
        </button>
      </div>
    </form>
  );
};

export default NewAssetModal;

// @ts-nocheck
import React from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { capitalizeWords } from "../../../util/utils";
import { useDispatch, useSelector } from "react-redux";
import { setHoveredAsset } from "../../../store/assetsSlice";
import FlyToButton from "../../FlyToButton";

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor("fly", {
    header: () => "",
    cell: (info) => [info.row.original.lng, info.row.original.lat],
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("name", {
    header: () => "Name",
    cell: (info) => capitalizeWords(info.getValue()),
  }),
  columnHelper.accessor("distance", {
    header: () => "Distance (km)",
    cell: (info) => Math.round(info.getValue() * 10) / 10,
  }),
  columnHelper.accessor("minYear", {
    header: () => "Min. Year",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("maxYear", {
    header: () => "Max. Year",
    cell: (info) => info.renderValue(),
  }),
  columnHelper.accessor("stationID", {
    header: () => "Id",
    cell: (info) => info.renderValue(),
  }),
  columnHelper.display({
    id: "graphed",
    header: () => "graph",
  }),
];

const ProximityStationList = ({ data, onToggleStation, activeStations }) => {
  const dispatch = useDispatch();
  const handleAssetHover = (id) => {
    dispatch(setHoveredAsset(id));
  };

  const hoverId = useSelector((state) => state.assets.hoveredAsset);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex">
      <table className="flex-1">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="border" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                onMouseEnter={() => handleAssetHover(row?.original?.id)}
                className={`border 
                
                ${row.id % 2 === 0 ? "bg-gray-300" : "bg-gray-400"}
                            `}
              >
                {row.getVisibleCells().map((cell) => {
                  if (cell.column.id === "graphed") {
                    return (
                      <td key={cell.id} className="text-center">
                        <input
                          type="checkbox"
                          checked={activeStations.includes(
                            row.original.stationID.toString()
                          )}
                          onChange={(e) =>
                            onToggleStation({
                              stationId: row.original.stationID,
                              checked: e.target.checked,
                            })
                          }
                        />
                      </td>
                    );
                  }

                  if (cell.column.id === "fly") {
                    return (
                      <td key={cell.id}>
                        <FlyToButton lngLat={row.original.geometry} />
                      </td>
                    );
                  }
                  return (
                    <td className="text-center" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-4" />
    </div>
  );
};

export default ProximityStationList;

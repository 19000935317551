// @ts-nocheck
import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import { Scatter, Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import zoomPlugin from "chartjs-plugin-zoom";
// import { verticalLinePlugin } from "./alertEventPlugin";
import { setAlertHighlightIndex } from "../../store/forecastSlice";
import "chartjs-adapter-date-fns";
import { createTickCallback } from "./graphutil";
import { dateFormatZone } from "./forecastUtil";

ChartJS.register(...registerables, annotationPlugin, zoomPlugin);

const verticalLineIntersect = {
  id: "verticalLine",
  afterDraw: (chart) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx;
      const activePoint = chart.tooltip._active[0];
      const x = activePoint.element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 1;
      ctx.strokeStyle = "rgba(0, 0, 0, 0.8)";
      ctx.stroke();
      ctx.restore();
    }
  },
};

const percentileColors = [
  "#cfd5b3",
  "#abb57b",
  "#879643",
  "#abb57b",
  "#cfd5b3",
];

const PrecipPercentile = ({
  data,
  labels,
  highResData,
  control,
  average,
  alerts = [],
  timeZone,
  yaxis,
}) => {
  const dispatch = useDispatch();
  const forecast = useSelector((state) => state.forecast);
  if (!data) {
    return false;
  }

  const maxYValue =
    [...Object.values(data), highResData, ...control, average]
      .flat()
      .reduce((max, item) => Math.max(max, item?.y), 0) * 1.1;

  const maxXValue = [...Object.values(data), highResData, control, average]
    .flat()
    .reduce((max, item) => Math.max(max, new Date(item?.x).getTime()), 0);

  const minXValue = [...Object.values(data), highResData, control, average]
    .flat()
    .reduce(
      (min, item) => Math.min(min, new Date(item?.x).getTime()),
      Infinity
    );

  const options = {
    plugins: {
      legend: { display: true },
      zoom: {
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          mode: "x", // Only allow zooming on the x-axis
        },
        limits: {
          x: {
            min: minXValue,
            max: maxXValue,
          },
        },
        pan: {
          enabled: true,
          mode: "x", // Only allow panning on the x-axis
        },
      },
      tooltip: {
        enabled: true,
        mode: "nearest", // Display the tooltip for all items in the index (stack)
        axis: "x",
        intersect: false,
        callbacks: {
          // Customize the tooltip
          beforeTitle: function (context) {
            // Optional: Add content before the title
          },
          title: function (context) {
            const newTimeDate = new Date(context[0].parsed.x);
            const { year, month, date, hour, minute, intTimeZone } =
              dateFormatZone(newTimeDate, timeZone);
            return `${date}-${month}-${year} ${hour}:${minute} ${intTimeZone}`;
          },
          label: function (context) {
            // Customizing label to show desired data for each point in the stack
            const label = context.dataset.label || "";
            const value = context.parsed.y.toFixed(2);
            return `${label}: ${value}`;
          },
          afterLabel: function (context) {
            // Optional: Add content after each label
          },
          footer: function (context) {
            // Optional: Add footer content
          },
        },
      },

      verticalLine: {},
      annotation: {
        annotations: {
          currentUTCTime: {
            type: "line",
            xMin: new Date().toISOString(),
            xMax: new Date().toISOString(),
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
            label: {
              display: true,
              content: () => {
                const newTimeDate = new Date();
                const { year, month, date, hour, minute, intTimeZone } =
                  dateFormatZone(newTimeDate, timeZone);
                return `${date}-${month}-${year} ${hour}:${minute} ${intTimeZone}`;
              },
              enabled: true,
              position: "end",
            },
          },
        },
      },
    },
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "x",
    },
    maintainAspectRatio: false,
    responsive: true,
    spanGaps: true,
    scales: {
      x: {
        stacked: true,
        display: true, //mobile
        type: "time",
        title: {
          display: true,
          text: "Hour",
        },
        ticks: {
          callback: createTickCallback(),
        },
      },

      y: {
        display: true,
        title: {
          display: true,
          text: "Precipitation (mm)",
        },
        max: Math.ceil(maxYValue),
      },
    },
  };

  const boxplotData = {
    // define label tree
    // labels: labels.map(dateTime => {
    //   const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', hour12: false };
    //   const shortDateTime = new Intl.DateTimeFormat('en-US', options).format(dateTime)
    //   return shortDateTime
    // }),
    type: "boxplot",
    datasets: [
      {
        label: `Control`,
        type: "line",
        backgroundColor: "rgba(66,165,245, 0.5)",
        borderColor: "rgba(66,165,245, 0.5)",
        data: control,
        // backgroundColor: 'rgba(255, 255, 255, 1)', // Customize as needed
        // borderColor: `rgba(255, 255, 255, 1)`, // Customize as needed
        // borderWidth: 1,
      },
      {
        label: `Average`,
        type: "line",
        backgroundColor: "rgba(239,83,80, 0.5)",
        borderColor: "rgba(239,83,80, 0.5)",
        data: average,
      },
      {
        label: "Hourly",
        type: "line",
        data: highResData,
        backgroundColor: "rgba(75, 0, 130, 0.5)",
        borderColor: "rgba(75, 0, 130, 0.5)",
      },
      ...Object.values(data).map((arr, index) => ({
        label: `${10 + index * 20} Percentile`,
        data: arr,
        backgroundColor: percentileColors[index], // Customize as needed
        borderColor: `rgba(255, 255, 255, 1)`, // Customize as needed
        borderWidth: 1,
        barPercentage: 0.4, // Adjust this value to control bar width
        categoryPercentage: 2.5, // Adjust this value to control spacing between bar
      })),
    ],
  };
  options.plugins.verticalLine = {
    lines: alerts?.map((alert) => {
      return {
        lineValue: alert.alertTimeIndex, // x-value for first line
        lineColor: "blue",
        hoverLineColor: "green",
        lineWidth: 1,
        alertId: alert.alertId,
        onLeave: () => console.log("left"),
      };
    }),
    onHover: (line) => {
      if (line.lineValue !== forecast.shortGefsAlerts.highlightIndex) {
        dispatch(setAlertHighlightIndex(line.lineValue));
      }
    },
    onLeave: () => {
      if (forecast.shortGefsAlerts.highlightIndex !== -1) {
        dispatch(setAlertHighlightIndex(-1));
      }
    },
  };

  if (data) {
    return (
      <Bar
        onMouseOut={() => dispatch(setAlertHighlightIndex(-1))}
        className="flex-1"
        options={options}
        data={boxplotData}
        plugins={[verticalLineIntersect]}
      />
    );
  }
  return false;
};

export default PrecipPercentile;

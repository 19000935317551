// @ts-nocheck
import React from "react";
import { ReactComponent as HomeSmile } from "../assets/icons/home-smile.svg";
import { getUser } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

const AssetHeaderGroup = ({ asset }) => {
  const handleAssetDetail = async () => {
    const authSession = await fetchAuthSession();
    const groups =
      authSession?.tokens?.idToken?.payload?.["cognito:groups"] ?? [];
    if (groups && groups.includes("Admin")) {
      const client = generateClient();
      const { data } = await client.graphql({
        query: getUser,
        variables: { id: asset.owner },
      });

      const user = data.getUser;

      const displayDetails = {
        userId: user.id,
        cognitoIdentityId: user.cognitoIdentityId,
        assetId: asset.id,
        processedModels: asset.processedModels,
      };
      console.log("displayDetails", displayDetails);
    }
  };

  return (
    <div className=" flex items-center">
      <div
        onClick={handleAssetDetail}
        className="w-12 h-12 border border-gray-200 rounded-lg flex items-center shrink-0 justify-center mr-3"
      >
        <HomeSmile />
      </div>
      <div className="flex-col items-center justify-center">
        <div className="text-gray-900 text-xl font-semibold font-Inter mb-1">
          {asset?.name}
        </div>
        <div className="text-gray-400 text-sm font-normal font-Inter">
          {asset?.description ? (
            asset.description.substring(0, 150)
          ) : (
            <span className="invisible">*</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetHeaderGroup;

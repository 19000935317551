// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  // assetAlertsByAssetId,
  // listAssetAlerts,
  assetAlertsByAssetGroupIdAndAssetId,
} from "../graphql/queries";
import {
  assetAlertsByAssetGroupIdAndAssetIdCustom,
  listAssetAlertsCustom,
} from "../graphql/customQueries";
import { generateClient } from "aws-amplify/api";
import {
  createAssetAlert,
  updateAssetAlert,
  deleteAssetAlert,
} from "../graphql/mutations";
import { schemaMatch } from "../util/utils";

const client = generateClient();

const alertSchema = [
  "id",
  "occurenceKey",
  "assetId",
  "emails",
  "phoneNumbers",
  "active",
  "rainfall",
  "period",
  "lastRun",
  "groupAlert",
  "description",
  "window",
  "name",
  "probability",
  "dataSource",
];

const initialState = {
  groups: {},
};

//This one is good
export const postAlert = createAsyncThunk(
  "alerts/PostAlert",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: createAssetAlert,
        variables: { input },
      });

      return data.createAssetAlert;
    } catch (error) {
      console.log("post alert error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAlertsbyGroupId = createAsyncThunk(
  "alerts/getAlertsbyGroupId",
  async (assetGroupId, thunkAPI) => {
    try {
      // CRAIG Does not return null assetID
      // const { data } = await API.graphql({
      //   query: assetAlertsByAssetGroupIdAndAssetIdCustom,
      //   variables: {
      //     assetGroupId,

      //   },
      // });

      // CRAIG This works for now for fetching ALL alerts including alerts without assets
      const { data } = await client.graphql({
        query: listAssetAlertsCustom,
        variables: {
          filter: {
            assetGroupId: { eq: assetGroupId },
          },
        },
      });

      // return {
      //   groupid: assetGroupId,
      //   alerts: data.assetAlertsByAssetGroupIdAndAssetId.items,
      // };

      return {
        groupid: assetGroupId,
        alerts: data.listAssetAlerts.items,
      };
    } catch (error) {
      console.log("post alert error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

//maybe not so good CRAIG
// export const fetchAlertbyAssetId = createAsyncThunk(
//   "alerts/fetchAlerts",
//   async (assetId, thunkAPI) => {
//     try {
//       const { data } = await API.graphql({
//         query: assetAlertsByAssetId,
//         variables: {
//           assetId,
//         },
//       });

//       return { data: data.assetAlertsByAssetId.items, assetId };
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.message);
//     }
//   }
// );

export const editAlert = createAsyncThunk(
  "alerts/editAlert",
  async (input, thunkAPI) => {
    const formattedInput = schemaMatch(input, alertSchema);
    try {
      const { data } = await client.graphql({
        query: updateAssetAlert,
        variables: { input: formattedInput },
      });

      return data.updateAssetAlert;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const toggleAlertActive = createAsyncThunk(
  "alerts/toggleAlertActive",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: updateAssetAlert,
        variables: { input },
      });

      return data.updateAssetAlert;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteAlert = createAsyncThunk(
  "alerts/deleteAlert",
  async (alertId, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: deleteAssetAlert,
        variables: { input: { id: alertId } },
      });

      return {
        id: data.deleteAssetAlert.id,
        assetGroupId: data.deleteAssetAlert.assetGroupId,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const AlertSlice = createSlice({
  name: "assetAlerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAlertsbyGroupId.pending, (state, action) => {
        //newHire
      })
      .addCase(getAlertsbyGroupId.fulfilled, (state, action) => {
        state.groups[action.payload.groupid] = action.payload.alerts;
      })
      .addCase(getAlertsbyGroupId.rejected, (state, action) => {
        //newHire
      })
      .addCase(postAlert.pending, (state) => {
        // newHire
      })
      .addCase(postAlert.fulfilled, (state, action) => {
        state.groups[action.payload.assetGroupId].unshift(action.payload);
      })
      .addCase(postAlert.rejected, (state, action) => {
        // newHire
      })
      // .addCase(fetchAlertbyAssetId.pending, (state) => {
      //   // newHire
      // })
      // .addCase(fetchAlertbyAssetId.fulfilled, (state, action) => {
      //   state[action.payload.assetId] = action.payload.data;
      // })
      // .addCase(fetchAlertbyAssetId.rejected, (state, action) => {
      //   // newHire
      // })
      .addCase(toggleAlertActive.pending, (state, action) => {
        const index = state.groups[action.meta.arg.assetGroupId].findIndex(
          (alert) => alert.id === action.meta.arg.id
        );
        if (index !== -1) {
          state.groups[action.meta.arg.assetGroupId][index].active =
            action.meta.arg.active;
        }
      })
      .addCase(toggleAlertActive.fulfilled, (state, action) => {
        const index = state.groups[action.payload.assetGroupId].findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.groups[action.payload.assetGroupId][index] = action.payload;
        }
      })
      .addCase(toggleAlertActive.rejected, (state, action) => {
        // newHire
      })
      .addCase(editAlert.pending, (state) => {
        // newHire
      })
      .addCase(editAlert.fulfilled, (state, action) => {
        const index = state.groups[action.payload.assetGroupId].findIndex(
          (alert) => alert.id === action.payload.id
        );
        if (index !== -1) {
          state.groups[action.payload.assetGroupId][index] = action.payload;
        }
      })
      .addCase(editAlert.rejected, (state, action) => {
        // newHire
      })
      .addCase(deleteAlert.fulfilled, (state, action) => {
        state.groups[action.payload.assetGroupId] = state.groups[
          action.payload.assetGroupId
        ].filter((assetAlert) => assetAlert.id !== action.payload.id);
      })
      .addCase(deleteAlert.pending, (state, action) => {
        // newHire
        console.log("delete pending");
      })
      .addCase(deleteAlert.rejected, (state, action) => {
        // newHire
        console.log("delete rejected");
      });
  },
});

export const { setSelectStation } = AlertSlice.actions;
export default AlertSlice.reducer;

// @ts-nocheck
import React, { useState, useEffect } from "react";
import { ReactComponent as BellIcon } from "../../assets/icons/bell-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { setModalOpen } from "../../store/modalSlice";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import useGetMe from "../../hooks/useGetMe";
import AntSwitch from "../customWidget/AntSwitch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { editAlert, postAlert } from "../../store/alertsSlice";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const defaultAlertState = {
  name: "",
  assetId: "",
  description: "",
  rainfall: 0,
  period: "",
  window: "",
  active: true,
  emails: [],
  groupAlert: false,
  dataSource: "GEFS",
  probability: 50,
};

const NewAlertModal = ({ groupId, initialAlertState, editFlag = false }) => {
  const dispatch = useDispatch();
  const { user } = useGetMe();

  const [alertState, setAlertState] = useState({
    ...defaultAlertState,
    ...initialAlertState,
  });
  const [newEmail, setNewEmail] = useState("");

  const assets = useSelector((state) => state.assets);

  useEffect(() => {
    if (
      editFlag &&
      !initialAlertState.assetId &&
      initialAlertState.groupAlert
    ) {
      setAlertState({ ...alertState, assetId: "allAssets" });
    }
  }, [initialAlertState]);

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (newEmail.length > 3) {
      const updatedSet = new Set([...alertState.emails, newEmail]);
      setAlertState({ ...alertState, emails: Array.from(updatedSet) });
      setNewEmail("");
    }
  };

  const handleDefaultEmailAdd = (e) => {
    const updatedSet = new Set([...alertState.emails, user.email]);
    setAlertState({ ...alertState, emails: Array.from(updatedSet) });
  };

  const handleEmailDelete = (deleteEmail) => {
    const filteredEmails = alertState.emails.filter(
      (email) => email !== deleteEmail
    );
    setAlertState({ ...alertState, emails: filteredEmails });
  };

  //CRAIG ADD ERROR HANDLING
  const submitNewAlert = async () => {
    let alertForm = { ...alertState };
    if (alertForm.assetId === "allAssets") {
      alertForm.assetId = null;
      alertForm.groupAlert = true;
    }
    alertForm.assetGroupId = groupId;

    if (editFlag) {
      await dispatch(editAlert(alertForm));
    } else {
      await dispatch(postAlert(alertForm));
    }

    await dispatch(setModalOpen(false));
  };

  const groupAssets = assets.dataGroups[groupId];

  return (
    <div className="">
      <div className="flex flow-row grow items-center border-b border-gray-200 p-2">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <BellIcon className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          {editFlag ? `Edit Alert` : "Add Alert"}
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      <div className="flex grow flex-col p-5">
        <div className="flex gap-8 justify-between">
          <div className="flex-1">
            <div
              name="name"
              className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
            >
              Alert Name
            </div>
            <div className="flex">
              <input
                name="name"
                value={alertState.name}
                onChange={(e) =>
                  setAlertState({
                    ...alertState,
                    [e.target.name]: e.target.value,
                  })
                }
                className="flex grow border p-3 mb-5 text-md rounded-md"
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Asset
            </div>
            <div className="flex grow">
              {/* <input  name="assetGroupId" value={assetState.assetGroupId} onChange={(e)=>setAlertState({...assetState, [e.target.name]:e.target.value})} className="flex grow border p-3 mb-5 text-md rounded-md" /> */}
              <select
                name="assetId"
                value={alertState.assetId}
                onChange={(e) =>
                  setAlertState({
                    ...alertState,
                    [e.target.name]: e.target.value,
                  })
                }
                className="flex grow border p-3 mb-5 text-md rounded-md"
              >
                <option value="">Select an Asset Group</option>
                <option value="allAssets">All Assets in this Group</option>
                {groupAssets &&
                  groupAssets.map((groupAsset) => (
                    <option key={groupAsset.id} value={groupAsset.id}>
                      {groupAsset.name}
                    </option>
                  ))}
                {/* Add more options as needed */}
              </select>
            </div>
          </div>
        </div>
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Alert Notes
        </div>
        <div className="flex grow">
          <textarea
            name="description"
            value={alertState.description}
            onChange={(e) =>
              setAlertState({ ...alertState, [e.target.name]: e.target.value })
            }
            className="flex grow border p-3 mb-5 text-md rounded-md"
          />
        </div>
        <div className="flex gap-8 justify-between">
          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Rainfall (mm)
            </div>
            <div className="flex">
              <input
                name="rainfall"
                type="number"
                value={alertState.rainfall}
                onChange={(e) =>
                  setAlertState({
                    ...alertState,
                    [e.target.name]: e.target.value,
                  })
                }
                className="flex-1  border pl-3 py-3 mb-3 text-md rounded-md"
              />
            </div>
          </div>
          <div className="flex-1 ">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Rain Period
            </div>
            <div className=" flex">
              <select
                name="period"
                value={alertState.period}
                onChange={(e) =>
                  setAlertState({
                    ...alertState,
                    [e.target.name]: e.target.value,
                  })
                }
                className="flex-1 grow border p-3 mb-5 text-md rounded-md"
              >
                <option value="">Event Window</option>
                <option value="3">3 Hours</option>
                <option value="6">6 Hours</option>
                <option value="12">12 Hours</option>
                <option value="18">18 Hours</option>
                <option value="24">24 Hours</option>
                <option value="48">48 Hours</option>
                <option value="72">72 Hours</option>
                <option value="168">1 Week</option>
                <option value="240">10 Days</option>
                {/* Add more options as needed */}
              </select>
            </div>
          </div>
        </div>
        <div className="flex gap-8 justify-between">
          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Event Window
            </div>
            <div className="flex grow">
              {/* <input  name="assetGroupId" value={assetState.assetGroupId} onChange={(e)=>setAlertState({...assetState, [e.target.name]:e.target.value})} className="flex grow border p-3 mb-5 text-md rounded-md" /> */}
              <select
                name="window"
                value={alertState.window}
                onChange={(e) =>
                  setAlertState({
                    ...alertState,
                    [e.target.name]: e.target.value,
                  })
                }
                className="flex-1 grow border p-3 mb-5  text-md rounded-md"
              >
                <option value="">Event Window</option>
                <option value="3">3 Hours</option>
                <option value="6">6 Hours</option>
                <option value="12">12 Hours</option>
                <option value="18">18 Hours</option>
                <option value="24">24 Hours</option>
                <option value="48">48 Hours</option>
                <option value="72">72 Hours</option>
                <option value="168">1 Week</option>
                <option value="240">10 Days</option>
                {/* Add more options as needed */}
              </select>
            </div>
          </div>
          <div className="flex-1">
            <div
              name="name"
              className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
            >
              Probability (%)
            </div>
            <div className="flex">
              <select
                name="probability"
                value={alertState.probability}
                onChange={(e) =>
                  setAlertState({
                    ...alertState,
                    [e.target.name]: e.target.value,
                  })
                }
                className="flex-1 grow border p-3 mb-5  text-md rounded-md"
              >
                <option value="10">10</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="70">70</option>
                <option value="90">90</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex gap-8 justify-between">
          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Data Source
            </div>
            <div className="flex grow">
              <select
                name="dataSource"
                value={alertState.dataSource}
                onChange={(e) =>
                  setAlertState({
                    ...alertState,
                    [e.target.name]: e.target.value,
                  })
                }
                className="flex grow border p-3 mb-5  text-md rounded-md"
              >
                <option value="GEFS">GEFS</option>
                <option value="graphCast" disabled>
                  GraphCast - Coming Soon
                </option>
              </select>
            </div>
          </div>
          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Alert Active
            </div>
            <div className="flex-1 flex mt-5">
              {/* Craig Fix this? */}
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Off</Typography>
                <AntSwitch
                  checked={alertState.active}
                  onChange={(e) =>
                    setAlertState({ ...alertState, active: e.target.checked })
                  }
                  inputProps={{ "aria-label": "ant design" }}
                />
                <Typography>On</Typography>
              </Stack>
            </div>
          </div>
        </div>
        <div className="flex  justify-between">
          <div className="flex-1">
            <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Email
            </div>
            <div>
              <form onSubmit={handleEmailSubmit}>
                <input
                  onChange={(e) => setNewEmail(e.target.value)}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                  type="email"
                  name="threshold"
                  value={newEmail}
                />
              </form>
            </div>
          </div>
          <div>
            <div className="text-white text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              Email
            </div>

            <div>
              {alertState.emails.includes(user?.email) ? (
                ""
              ) : (
                <button
                  onClick={handleDefaultEmailAdd}
                  className="eai-btn-grn my-1"
                >
                  + Add {user?.email}{" "}
                </button>
              )}
            </div>
          </div>
        </div>
        <div>
          {alertState.emails.length > 0 && (
            <Paper
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {alertState.emails.map((email) => {
                return (
                  <ListItem key={email}>
                    <Chip
                      label={email}
                      onDelete={() => handleEmailDelete(email)}
                    />
                  </ListItem>
                );
              })}
            </Paper>
          )}
        </div>
        <div className="flex flex-row grow space-x-3  pb-5">
          <button className="flex-grow eai-btn my-1" type="submit">
            <div className="mx-3" onClick={() => dispatch(setModalOpen(false))}>
              Cancel
            </div>
          </button>
          <button
            onClick={submitNewAlert}
            className="flex-grow eai-btn-grn my-1"
            type="submit"
          >
            <div className="mx-3"> {editFlag ? "Update" : "Create"}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewAlertModal;

// @ts-nocheck
import React, { useState } from "react";
import { updatePassword } from "aws-amplify/auth";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as Bell } from "../../assets/icons/bell-icon.svg";
import {
  modalViewsConst,
  setModalOpen,
  setModalState,
} from "../../store/modalSlice";
import { useDispatch } from "react-redux";

const LoggedInResetPassword = () => {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("New Password and Confirm Password don't match.");
      return;
    }
    if (newPassword.length < 6) {
      setError("Password must have at least 7 characters.");
      return;
    }

    try {
      const res = await updatePassword({
        oldPassword,
        newPassword,
      });

      dispatch(
        setModalState({
          modalOpen: true,
          modalView: modalViewsConst.notification,
          properties: {
            header: "Password Change",
            details: "Password changed successfully!",
          },
        })
      );
      setError("");
      // Clear fields
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      setError(error.message);
      setSuccess("");
    }
  };

  return (
    <div>
      <div className="flex flow-row grow items-center border-b border-gray-200 p-2">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <Bell className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          Change Password
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      <form className="flex grow flex-col p-5" onSubmit={handleSubmit}>
        <div className="flex justify-between">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            Old Password
          </div>
        </div>
        <div className="flex grow">
          <input
            className="flex grow border p-3 mb-5 text-md rounded-md"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex justify-between">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            New Password
          </div>
        </div>
        <div className="flex grow">
          <input
            className="flex grow border p-3 mb-5 text-md rounded-md"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="flex justify-between">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            Confirm New Password
          </div>
        </div>
        <div className="flex grow">
          <input
            className="flex grow border p-3 mb-5 text-md rounded-md"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>

        <button className="eai-btn-grn" type="submit">
          Change Password
        </button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
    </div>
  );
};

export default LoggedInResetPassword;

// @ts-nocheck
import React from "react";

const AFSetup = () => {
  return (
    <div>
      <div className="border-2 border mt-2">
        <div className="text-lg p-2 font-bold">Rainfall Return Period</div>
        <div className="m-5 h-48 bg-slate-500">Graph Placeholder</div>
      </div>
    </div>
  );
};

export default AFSetup;

// @ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHoveredAsset } from "../../../store/assetsSlice";
import { BiCurrentLocation } from "react-icons/bi";
import { setFlyLocation } from "../../../store/mapStateSlice";
import FlyToButton from "../../FlyToButton";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor("fly", {
    header: () => "",
    cell: (info) => [info.row.original.lng, info.row.original.lat],
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("name", {
    header: () => "Name",
    cell: (info) => info.getValue(),
    // footer: info => info.column.id,
  }),
  columnHelper.accessor("lng", {
    header: () => "Long",
    cell: (info) => info.getValue().toString().substring(0, 6),
  }),
  columnHelper.accessor("lat", {
    header: () => "Lat",
    cell: (info) => info.renderValue().toString().substring(0, 6),
  }),
  columnHelper.display({
    id: "precip",
    header: () => "Precip mm",
  }),
];

const AssetRainGrid = ({ assets, onAssetClick, rainGridData }) => {
  const dispatch = useDispatch();

  const hoverId = useSelector((state) => state.assets.hoveredAsset);

  const handleAssetHover = (id) => {
    dispatch(setHoveredAsset(id));
  };

  const table = useReactTable({
    data: assets,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex">
      <table
        onMouseLeave={() => dispatch(setHoveredAsset(null))}
        className="flex-1"
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="border" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                onMouseEnter={() => handleAssetHover(row?.original?.id)}
                onClick={() => onAssetClick(row?.original)}
                className={`border 
            
            ${
              hoverId === row?.original?.id
                ? "bg-cyan-300"
                : row.id % 2 === 0
                ? "bg-gray-300"
                : "bg-gray-400"
            }
                        `}
              >
                {row.getVisibleCells().map((cell) => {
                  if (cell.column.id === "precip") {
                    return (
                      <td className="text-center" key={cell.id}>
                        {rainGridData?.[row.original.id]?.Precip_mm ?? "N/A"}
                      </td>
                    );
                  }

                  if (cell.column.id === "fly") {
                    return (
                      <td key={cell.id}>
                        <FlyToButton
                          lngLat={[row.original.lng, row.original.lat]}
                        />
                      </td>
                    );
                  }
                  return (
                    <td className="text-center" key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-4" />
    </div>
  );
};

export default AssetRainGrid;

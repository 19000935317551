// @ts-nocheck
import React, { useState } from "react";

interface TagProps {
  tagText: string;
}

export const AssetCount = ({ count, countText = "Assets" }) => {
  return (
    <>
      <div className="flex flex-row grow mt-1">
        <div className="px-3 py-0.5 bg-eai-primary-accent rounded-[100px] justify-start items-center inline-flex">
          <div className="text-white text-xs font-medium font-Inter">
            {count}
          </div>
        </div>
        <div className="text-gray-500 text-sm font-normal font-Inter ml-1">
          {countText}
        </div>
      </div>
    </>
  );
};

export const AssetTag = ({ tagText }: TagProps) => {
  return (
    <>
      <div className="flex flex-row grow mt-1">
        <div className="py-0.5 px-3 bg-gray-400 rounded-[100px] justify-start items-center inline-flex">
          <div className="text-white text-xs font-medium font-Inter">
            {tagText}
          </div>
        </div>
      </div>
    </>
  );
};

// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const options = {
  scales: {
    x: {
      display: true,
      title: {
        display: true,
        text: "Year",
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: "Precipitation (mm)",
      },
    },
  },
};

const GraphYearlyMax = () => {
  const selectedStation = useSelector((state) => state.selectedStation);
  const [config, setConfig] = useState(null);

  useEffect(() => {
    if (selectedStation?.yrRainMax) {
      const data = selectedStation?.yrRainMax;
      const dataFormatted = data
        .slice()
        .sort((a, b) => a.year - b.year)
        .map((yearlyData, i) => {
          return { x: yearlyData.year, y: yearlyData.precip_mm };
        });

      const configuration = {
        datasets: [
          {
            label: "Yearly Max",
            data: dataFormatted,
            showLine: true,
            backgroundColor: "rgba(66, 135, 245, 1)",
          },
        ],
      };

      setConfig(configuration);
    } else {
      setConfig(null);
    }
  }, [selectedStation?.yrRainMax]);

  if (!config || !selectedStation.details) {
    return false;
  }

  return <Scatter options={options} data={config} />;
};

export default GraphYearlyMax;

// @ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFrequencyAnalysis } from "../../../store/assetsSlice";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor("createdAt", {
    header: () => "Created",
    cell: (info) =>
      new Date(info.getValue()).toLocaleDateString("en-gb", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }),
  }),
  columnHelper.accessor("minYear", {
    header: () => "Min. Year",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("maxYear", {
    header: () => "Max. Year",
    cell: (info) => info.renderValue(),
  }),
  columnHelper.accessor("minimumRecordLength", {
    header: () => "Min. Record Length",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("radius", {
    header: () => "Radius (km)",
    cell: (info) => info.renderValue(),
  }),
  // columnHelper.accessor("stations", {
  //     header: () => "Stations",
  //     cell: (info) => info.renderValue(),
  //   }),
];

const NowForecasts = () => {
  const { selectedAsset, freqAnalyses } = useSelector((state) => state.assets);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFrequencyAnalysis(selectedAsset.id));
  }, [selectedAsset.id]);

  const table = useReactTable({
    data: freqAnalyses[selectedAsset.id],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  //Craig maybe change to loading it in the parent component
  if (
    !freqAnalyses[selectedAsset.id] ||
    freqAnalyses[selectedAsset.id].length === 0
  ) {
    return false;
  }

  return (
    <div>
      <div>Previous Forecasts</div>

      <div className="flex">
        <table className="flex-1">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="border" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  onMouseEnter={() => console.log("Highlighted")}
                  className={`border 
                
                ${row.id % 2 === 0 ? "bg-gray-300" : "bg-gray-400"}
                            `}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td className="text-center" key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="h-4" />
      </div>
    </div>
  );
};

export default NowForecasts;

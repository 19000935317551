// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setModalState, modalViewsConst } from "../../store/modalSlice";
import { ReactComponent as DotsVertical } from "../../assets/icons/dots-vertical.svg";
import { ReactComponent as HomeSmile } from "../../assets/icons/home-smile.svg";
import { useNavigate } from "react-router-dom";

export const CustomModelRow = ({ record, onRecordDelete }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settingsRef = useRef();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [hoveringSetting, setHoveringSetting] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        setSettingsOpen(false); // Clicked outside the div
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [settingsRef]);

  const formatOutPut = (input) => {
    return input
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words back with spaces
  };

  const handleDelete = (e) => {
    onRecordDelete(record.id);
    e.stopPropagation();
  };

  const handleEdit = (e) => {
    navigate(`/home/dataupload/${record.id}`);
    e.stopPropagation();
  };

  const handleSettingsClick = (e) => {
    setSettingsOpen(!settingsOpen);
    e.stopPropagation();
  };

  const handlePreview = (e) => {
    navigate(`/home/dataupload/${record.id}`);
    e.stopPropagation();
  };

  return (
    <tr
      onClick={handlePreview}
      className={`cursor-pointer ${
        hoveringSetting ? "" : " hover:bg-slate-200"
      } 'bg-white'  border-y border-gray-200 `}
    >
      <td className="flex flex-row px-6 py-4 items-center">
        <div className="flex w-10 h-10 bg-gray-100 rounded-[20px] justify-center items-center">
          <HomeSmile className="h-6 w-6" />
        </div>
        <div className="text-gray-900 text-md font-semibold font-Inter ml-3 ">
          {record.name}
        </div>
      </td>
      <td className="px-6 py-4 text-md font-normal text-gray-600 hidden md:table-cell">
        {formatOutPut(record.parameter)}
      </td>
      <td className="px-6 py-4 text-md font-normal text-gray-600 hidden md:table-cell">
        {formatOutPut(record.objective)}
      </td>
      <td className="px-6 py-4 text-md font-normal text-gray-600 hidden md:table-cell">
        {formatOutPut(record.seasonality)}
      </td>
      <td className="px-6 py-4 text-md font-normal text-gray-600 hidden md:table-cell">
        {formatOutPut(record.optimizationHorizon)}
      </td>

      <td
        onClick={handleSettingsClick}
        ref={settingsRef}
        className="justify-around py-4 text-md font-normal text-gray-600 table-cell"
      >
        <div
          style={{ transform: "translateX(-85px) translateY(-125px)" }}
          className={` absolute rounded bg-white border group-hover:bg-white hover:border-gray-200 `}
        >
          {settingsOpen && (
            <div
              className="w-[150px]"
              onMouseEnter={() => setHoveringSetting(true)}
              onMouseLeave={() => setHoveringSetting(false)}
            >
              <div
                onClick={handleDelete}
                className="p-2 border border-white hover:bg-slate-200 "
              >
                Delete
              </div>
              <div
                onClick={handleEdit}
                className="p-2 border border-white hover:bg-slate-200 "
              >
                Edit Record
              </div>
            </div>
          )}
        </div>
        <div className="w-full flex justify-center">
          <DotsVertical className="h-6 w-6 " />
        </div>
      </td>
    </tr>
  );
};

export const CustomModelTable = ({ records, onDeleteRecord }) => {
  return (
    <>
      <div className="w-full bg-white overflow-x-auto border-gray-200">
        <table style={{ borderRadius: "10px" }} className="w-full text-left">
          <thead className="w-full bg-white text-gray-600 text-sm font-medium font-Inter">
            <tr>
              <th className="px-6 py-5">Name</th>
              <th className="px-6 py-5 hidden md:table-cell">Parameter</th>
              <th className="px-6 py-5 hidden md:table-cell">Objective</th>
              <th className="px-6 py-5 hidden md:table-cell">Seasonality</th>
              <th className="px-6 py-5 hidden md:table-cell">
                Optimization Horizon
              </th>
              <th className="px-6 py-5 text-center">Edit</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => (
              <CustomModelRow
                record={record}
                onRecordDelete={(id) => onDeleteRecord(id)}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

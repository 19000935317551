// @ts-nocheck
import React from "react";
import { useSelector } from "react-redux";

const AnnualRiskForecast = () => {
  const forecast = useSelector((state) => state.forecast);
  const { data } = forecast;

  const colorCalc = (color) => {
    switch (color) {
      case "high":
        return "bg-red-300";
      case "normal":
        return "bg-gray-200";
      case "low":
        return "bg-green-200";
      default:
        return "bg-white";
    }
  };

  if (!data) {
    return false;
  }

  return (
    <div className="py-0">
      <div className="text-xl font-normal py-2">
        Pluvial Flood Risk Forecast
      </div>
      <div className="text-sm font-montserrat pb-2">
        This forecast gives a relative risk for the annual maximum 24 hour
        rainfall event versus the local historical average.
      </div>
      <div className="flex flex-1 justify-between	">
        {data.map((forecast) => {
          return (
            <div key={forecast.id}>
              <div
                className={`h-16 w-20 flex items-center justify-center ${colorCalc(
                  forecast.risk
                )} border-gray-800 border`}
              >
                <p className="text-center font-montserrat text-sm">
                  {forecast.risk}
                </p>
              </div>
              <p className="text-center font-normal font-montserrat">
                {forecast.year}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnnualRiskForecast;

// @ts-nocheck

export const minRainfallDataYear = 1840;
export const maxRainfallDataYear = 2022;
export const minRecordCount = 3;
export const daysOfWeek = [
  "saturday",
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
];

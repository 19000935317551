// @ts-nocheck
import React from "react";
import { BiCurrentLocation } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { setFlyLocation } from "../store/mapStateSlice";

const FlyToButton = ({ lngLat, size = 1 }) => {
  const dispatch = useDispatch();

  const handleFly = (coords) => {
    dispatch(setFlyLocation(coords));
  };

  return (
    <button
      className="bg-white transition p-1 align-middle rounded border-black border-2"
      onClick={() => handleFly(lngLat)}
    >
      <BiCurrentLocation fontSize={`${size}em`} />
    </button>
  );
};

export default FlyToButton;

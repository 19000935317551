// @ts-nocheck
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AssetList from "../../TabViewer/AssetsTab/AssetList";

const dayArray = [1, 2, 3, 4, 5, 6, 7, 14, 21];
const probabilityArray = [10, 20, 30, 40, 50, 60, 70, 80, 90];

const AFSoon = () => {
  const [dayIndex, setDayIndex] = useState(8);
  const [probIndex, setProbIndex] = useState(8);
  const assets = useSelector((state) => state.assets);

  return (
    <div>
      <div className="border-2 border mt-2">
        <div className="text-lg p-2 font-bold">
          {dayArray[dayIndex]} Day Probabilistic Forecast
        </div>
        <div className="p-2">Toggle Placeholder</div>
        <div className="flex justify-between border-b-2">
          <div className="">
            {dayArray.map((num, i) => (
              <button
                onClick={() => setDayIndex(i)}
                className={`p-1 ${dayIndex === i ? "font-semibold" : ""}`}
              >
                {num}D
              </button>
            ))}
          </div>
          <div className="p-1">FORECAST WINDOW</div>
        </div>
        <div className="flex justify-between border-b-2">
          <div className="">
            {probabilityArray.map((num, i) => (
              <button
                onClick={() => setProbIndex(i)}
                className={`p-1 ${probIndex === i ? "font-semibold" : ""}`}
              >
                {num}
              </button>
            ))}
          </div>
          <div className="p-1">PROBABILITY</div>
        </div>
        Placeholder
        <AssetList
          assets={assets.data}
          onAssetClick={() => console.log("clicked")}
        />
      </div>
    </div>
  );
};

export default AFSoon;

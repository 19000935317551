// @ts-nocheck
import React, { useState, useEffect } from "react";
import { HeadBreadCrumb } from "../components/AssetBreadCrumb";
import { AssetGroupFolder, NoAssetGroup } from "../components/AssetGroupFolder";
import { ReactComponent as BellIcon } from "../assets/icons/bell-icon.svg";
import { ReactComponent as GroupIcon } from "../assets/icons/group-icon.svg";
import { AssetCount } from "../components/TagStyles";
import { AssetTable } from "../components/AssetTable";
import { fetchAssets } from "../../../store/assetsSlice";
import { useDispatch, useSelector } from "react-redux";
import { setModalState, modalViewsConst } from "../store/modalSlice";
import { fetchAssetsByGroupId } from "../store/assetsSlice";
import { useParams } from "react-router-dom";
import AlertGroupRecord from "../components/AssetAlerts/AlertGroupRecord";
import LoadingIcon from "../components/LoadingIcon";
import { getAlertsbyGroupId } from "../store/alertsSlice";
import AlertGroupDetailRecord from "../components/AssetAlerts/AlertGroupDetailRecord";

const AlertManagerPage = (props) => {
  let { groupid } = useParams();
  const dispatch = useDispatch();
  const { assets, assetGroups, alerts } = useSelector(
    ({ assets, assetGroups, alerts }) => ({
      assets,
      assetGroups,
      alerts,
    })
  );

  const fetchReqAssetAlertGroup = async (groupid) => {
    console.log("pre fetch");
    await dispatch(fetchAssetsByGroupId(groupid));
    await dispatch(getAlertsbyGroupId(groupid));
    console.log("post fetch");
  };

  useEffect(() => {
    // error handling here
    console.log("groupid", groupid);
    // if (!assets.dataGroups[groupid]) {
    fetchReqAssetAlertGroup(groupid);
    // dispatch(fetchAssetsByGroupId(groupid));
    // dispatch(getAlertsbyGroupId(groupid))
    // getAlertsbyGroupId

    // }
  }, []);

  const handleAddAlert = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.addNewAlert,
        properties: groupid,
      })
    );
  };

  const assetGroup = assetGroups.groups[groupid];
  const groupAlerts = alerts.groups[groupid];
  console.log("groupAlerts", groupAlerts);

  if (!assetGroup || !assets.dataGroups[groupid] || !groupAlerts) {
    return (
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div
          className={`transition-opacity delay-700 duration-500 ease-in-out `}
        >
          <LoadingIcon />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow">
          <HeadBreadCrumb text="Manage Asset Alerts">
            <BellIcon className=" w-5 h-5 ml-2 mr-1" />
          </HeadBreadCrumb>
          <div className="flex flex-col h-24 border-y border-gray-200 p-5 justify-center">
            <div className="text-gray-900 text-xl font-semibold mb-2 font-Inter">
              Manage Asset Alerts
            </div>
            <div className="text-gray-400 text-md font-normal font-Inter">
              Create notifications and add recipients for weatther alerts below
            </div>
          </div>
          <div className="flex flex-row h-24 border-y border-gray-200 p-5 justify-left items-center">
            <div className="flex grow items-center">
              <div className="w-12 h-12 border border-gray-200 rounded-lg flex items-center shrink-0 justify-center mr-3">
                <GroupIcon />
              </div>
              <div className="flex-col items-center justify-center">
                <div className="text-gray-900 text-xl font-semibold font-Inter mb-1">
                  {assetGroup.name}
                </div>
                <div>
                  <AssetCount count={groupAlerts.length} countText="Alerts" />
                </div>
              </div>
            </div>

            <div className="flex gap-4">
              <div className="ml-7 p-2">
                <button onClick={handleAddAlert} className="eai-btn">
                  + Add Alert
                </button>
              </div>
            </div>
          </div>

          <div className="p-5 h-[calc(100vh_-_15rem)] overflow-y-auto">
            {/* <div className="h-[1000px]">test</div>
            <div className="h-[1000px]">test</div>
            <div className="h-[1000px]">test</div>
            <div className="h-[1000px]">test</div>
            <div className="h-[1000px]">test</div>
            <div className="h-[1000px]">test</div> */}
            {groupAlerts.map((alertRecord) => {
              return (
                <AlertGroupDetailRecord
                  key={alertRecord.id}
                  alertRecord={alertRecord}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertManagerPage;

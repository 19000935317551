// @ts-nocheck
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setModalState, modalViewsConst } from "../../../store/modalSlice";

const AssetToolBar = () => {
  const dispatch = useDispatch();

  const handleAddAsset = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.addAssetForm,
        properties: null,
      })
    );
  };

  const handleUploadPoints = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.uploadAssetForm,
        properties: null,
      })
    );
  };

  const handleEditPoints = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.editAssetForm,
        properties: null,
      })
    );
  };

  return (
    <div className="flex flex-wrap mb-2 border-b-2 pb-3">
      <button className="eai-btn" onClick={() => handleAddAsset()}>
        New Asset
      </button>
      <button className="eai-btn" onClick={() => handleUploadPoints()}>
        Bulk Upload
      </button>
      <button className="eai-btn" onClick={() => handleEditPoints()}>
        Edit Assets
      </button>
    </div>
  );
};

export default AssetToolBar;

// @ts-nocheck
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy-icon.svg";
import { ReactComponent as ChevronUp } from "../../assets/icons/chevron-up.svg";
import { AssetCount } from "../TagStyles";
import { useNavigate } from "react-router-dom";

const AlertGroupRecord = ({ assetGroup }) => {
  const navigate = useNavigate();

  const [isRotated, setIsRotated] = useState(false);

  const handleClick = () => {
    console.log("TESt isRotated", isRotated);
    setIsRotated(!isRotated);
  };

  return (
    <div className="">
      <div className="flex flex-row h-24 border-b border-gray-200 p-5 justify-left items-center">
        <div className="flex grow items-center">
          <div className="w-12 h-12 border border-gray-200 rounded-lg flex items-center shrink-0 justify-center mr-3">
            <CopyIcon />
          </div>
          <div className="flex-col items-center justify-center">
            <div className="text-gray-900 text-xl font-semibold font-Inter mb-1">
              {assetGroup.name}
            </div>
            <AssetCount count={1} countText="Alerts" />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="">
            <div className="flex grow  pt-2">
              {/* <div className="flex ml-auto">
                <img
                  className="w-8 h-8 rounded-[100px] border-2 border-white"
                  src="https://picsum.photos/32/32?random=1"
                />
              </div>
              <img
                className="w-8 h-8 ml-[-10px] rounded-[100px] border-2 border-white"
                src="https://picsum.photos/32/32?random=2"
              />
              <img
                className="w-8 h-8 ml-[-10px] rounded-[100px] border-2 border-white"
                src="https://picsum.photos/32/32?random=3"
              /> */}
            </div>
          </div>
          <button
            onClick={() => navigate(`/home/alert/${assetGroup.id}`)}
            className="flex-wrap eai-btn-grn my-1"
            type="submit"
          >
            <div className="mx-3">Manage Alerts {isRotated}</div>
          </button>
          <div>
            <div
              onClick={handleClick}
              className={`cursor-pointer transition-transform ${
                isRotated ? "rotate-180" : ""
              }`}
              // className="cursor-pointer border-1 border-black  mt-1 p-2"
            >
              <ChevronUp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertGroupRecord;

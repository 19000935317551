// @ts-nocheck
import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const GraphAnnual = ({ title, graphData }) => {
  const dataFormatted = graphData["dist_x"].map((x_item, i) => {
    return { x: x_item, y: graphData["dist_y"][i] };
  });

  // const otherFormat = graphData[]
  const dataPoints = graphData["hist_x"].map((x_item, i) => {
    return { x: x_item, y: graphData["hist_y"][i] };
  });

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const config = {
    datasets: [
      {
        label: "data Line",
        data: dataFormatted,
        showLine: true,
        backgroundColor: "rgba(66, 135, 245, 1)",
      },
      {
        label: "datapoints",
        data: dataPoints,
        backgroundColor: "rgba(255, 99, 132, 1)",
      },
    ],
  };

  return <Scatter options={options} data={config} />;
};

export default GraphAnnual;

// @ts-nocheck
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  tabIndex: 0,
  assetTabIndex: 0,
};

const TabSlice = createSlice({
  name: "tabState",
  initialState,
  reducers: {
    setTabIndex: (state, action) => {
      state.tabIndex = action.payload;
    },
    setAssetTabIndex: (state, action) => {
      state.assetTabIndex = action.payload;
    },
  },
});

export const { setTabIndex, setAssetTabIndex } = TabSlice.actions;
export default TabSlice.reducer;

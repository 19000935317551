export const processedSample = {
    "asset": "Chief Joseph Dam",
    "asset_id": "1dea84ca-3633-4e59-a180-caeccc509afd",
    "forecast_issued": "2024-08-28 09:39 UTC",
    "processedModels": "turbineflows",
    "description": "Chief Joseph Dam flow through generators.",
    "models": {
        "CHJ-Flow-Gen-1Hour": {
            "variation": "Hourly Gen Flow",
            "graphType": "scatter",
            "yUnit": "k-cfs",
            "data": {
                "2024-08-28 00:00:00": {
                    "mean": 97.74087484871468,
                    "0.1": 86.6330553426975,
                    "0.25": 91.83093487344136,
                    "0.5": 97.5048474335089,
                    "0.75": 102.72304779145776,
                    "0.9": 107.26799663915867
                },
                "2024-08-28 01:00:00": {
                    "mean": 111.3100782254847,
                    "0.1": 99.46428073790014,
                    "0.25": 104.60828529916158,
                    "0.5": 111.07405081027892,
                    "0.75": 116.62082439515649,
                    "0.9": 122.38771995916599
                },
                "2024-08-28 02:00:00": {
                    "mean": 119.41624506508431,
                    "0.1": 107.57047046568334,
                    "0.25": 112.71447502694478,
                    "0.5": 119.18021764987853,
                    "0.75": 125.08097988221704,
                    "0.9": 132.31598648443455
                }
            }
        },
    "CHJ-Flow-Gen-1Hour-VAR": {
            "variation": "Hourly Gen Flow VAR",
            "graphType": "scatter",
            "yUnit": "k-cfs",
            "data": {
                "2024-08-28 00:00:00": {
                    "mean": 11.34519100189209,
                    "0.1": 10.47942066192627,
                    "0.25": 10.657759666442871,
                    "0.5": 11.34519100189209,
                    "0.75": 11.522324562072754,
                    "0.9": 9.193621635437012
                },
                "2024-08-28 01:00:00": {
                    "mean": 24.91439437866211,
                    "0.1": 23.310646057128906,
                    "0.25": 23.435110092163086,
                    "0.5": 24.91439437866211,
                    "0.75": 25.420101165771484,
                    "0.9": 24.313344955444336
                },
                "2024-08-28 02:00:00": {
                    "mean": 33.02056121826172,
                    "0.1": 31.41683578491211,
                    "0.25": 31.54129981994629,
                    "0.5": 33.02056121826172,
                    "0.75": 33.88025665283203,
                    "0.9": 34.24161148071289
                },
                "2024-08-28 03:00:00": {
                    "mean": 31.948701858520508,
                    "0.1": 30.344953536987305,
                    "0.25": 30.469417572021484,
                    "0.5": 31.948701858520508,
                    "0.75": 32.80838394165039,
                    "0.9": 33.16975021362305
                },
                "2024-08-28 04:00:00": {
                    "mean": 36.27045822143555,
                    "0.1": 34.66669845581055,
                    "0.25": 34.791160583496094,
                    "0.5": 36.27045822143555,
                    "0.75": 37.13015365600586,
                    "0.9": 37.49150848388672
                },
                "2024-08-28 05:00:00": {
                    "mean": 20.19854736328125,
                    "0.1": 18.666059494018555,
                    "0.25": 18.666059494018555,
                    "0.5": 20.19854736328125,
                    "0.75": 20.37567901611328,
                    "0.9": 19.863021850585938
                },
                "2024-08-28 06:00:00": {
                    "mean": -0.4965931177139282,
                    "0.1": -1.603334903717041,
                    "0.25": -1.424994945526123,
                    "0.5": -0.4965931177139282,
                    "0.75": -2.107834577560425,
                    "0.9": -0.6649636626243591
                },
                "2024-08-28 07:00:00": {
                    "mean": -19.493385314941406,
                    "0.1": -21.11302947998047,
                    "0.25": -19.853700637817383,
                    "0.5": -19.493385314941406,
                    "0.75": -19.316253662109375,
                    "0.9": -20.12165641784668
                },
                "2024-08-28 08:00:00": {
                    "mean": -28.1412410736084,
                    "0.1": -28.95724868774414,
                    "0.25": -28.953712463378906,
                    "0.5": -28.1412410736084,
                    "0.75": -28.1412410736084,
                    "0.9": -28.920555114746094
                },
                "2024-08-28 09:00:00": {
                    "mean": -35.4025993347168,
                    "0.1": -34.94473648071289,
                    "0.25": -35.48108673095703,
                    "0.5": -35.4025993347168,
                    "0.75": -35.4025993347168,
                    "0.9": -36.1769905090332
                },
                "2024-08-28 10:00:00": {
                    "mean": -37.97881317138672,
                    "0.1": -37.09105682373047,
                    "0.25": -37.79528045654297,
                    "0.5": -37.97881317138672,
                    "0.75": -37.97881317138672,
                    "0.9": -38.753204345703125
                },
                "2024-08-28 11:00:00": {
                    "mean": -27.92770767211914,
                    "0.1": -26.913137435913086,
                    "0.25": -27.61736488342285,
                    "0.5": -27.92770767211914,
                    "0.75": -27.92770767211914,
                    "0.9": -27.92770767211914
                },
                "2024-08-28 12:00:00": {
                    "mean": -22.30666160583496,
                    "0.1": -21.292089462280273,
                    "0.25": -21.996318817138672,
                    "0.5": -22.30666160583496,
                    "0.75": -22.30666160583496,
                    "0.9": -22.30666160583496
                },
                "2024-08-28 13:00:00": {
                    "mean": -16.422130584716797,
                    "0.1": -15.469714164733887,
                    "0.25": -16.11256217956543,
                    "0.5": -16.21794319152832,
                    "0.75": -16.502870559692383,
                    "0.9": -16.502870559692383
                },
                "2024-08-28 14:00:00": {
                    "mean": -8.006047248840332,
                    "0.1": -7.638360977172852,
                    "0.25": -7.74131965637207,
                    "0.5": -7.8018598556518555,
                    "0.75": -8.086787223815918,
                    "0.9": -8.086787223815918
                },
                "2024-08-28 15:00:00": {
                    "mean": 3.205000638961792,
                    "0.1": 3.572685956954956,
                    "0.25": 3.4697277545928955,
                    "0.5": 3.4091885089874268,
                    "0.75": 3.1242611408233643,
                    "0.9": 3.1242611408233643
                },
                "2024-08-28 16:00:00": {
                    "mean": 5.590577602386475,
                    "0.1": 5.958261966705322,
                    "0.25": 5.85530424118042,
                    "0.5": 5.794764995574951,
                    "0.75": 5.509837627410889,
                    "0.9": 5.509837627410889
                },
                "2024-08-28 17:00:00": {
                    "mean": 6.119874477386475,
                    "0.1": 6.487559795379639,
                    "0.25": 6.384602069854736,
                    "0.5": 6.324062824249268,
                    "0.75": 6.039134502410889,
                    "0.9": 6.039134502410889
                },
                "2024-08-28 18:00:00": {
                    "mean": 6.10955286026001,
                    "0.1": 6.477237224578857,
                    "0.25": 6.374279499053955,
                    "0.5": 6.313740253448486,
                    "0.75": 6.02881383895874,
                    "0.9": 6.02881383895874
                },
                "2024-08-28 19:00:00": {
                    "mean": 6.94117546081543,
                    "0.1": 7.308859825134277,
                    "0.25": 7.205902099609375,
                    "0.5": 7.145362854003906,
                    "0.75": 6.86043643951416,
                    "0.9": 6.86043643951416
                },
                "2024-08-28 20:00:00": {
                    "mean": 8.207048416137695,
                    "0.1": 8.335342407226562,
                    "0.25": 8.23238468170166,
                    "0.5": 8.411235809326172,
                    "0.75": 8.12630844116211,
                    "0.9": 8.12630844116211
                },
                "2024-08-28 21:00:00": {
                    "mean": 6.697014808654785,
                    "0.1": 6.825308799743652,
                    "0.25": 6.72235107421875,
                    "0.5": 6.901202201843262,
                    "0.75": 6.706551551818848,
                    "0.9": 6.706551551818848
                },
                "2024-08-28 22:00:00": {
                    "mean": 7.822281360626221,
                    "0.1": 7.950575351715088,
                    "0.25": 7.8476176261901855,
                    "0.5": 8.026470184326172,
                    "0.75": 7.831817150115967,
                    "0.9": 7.831817150115967
                },
                "2024-08-28 23:00:00": {
                    "mean": 9.211402893066406,
                    "0.1": 9.33969783782959,
                    "0.25": 9.236740112304688,
                    "0.5": 9.4155912399292,
                    "0.75": 9.220939636230469,
                    "0.9": 9.357068061828613
                },
                "2024-08-29 00:00:00": {
                    "mean": 14.190567970275879,
                    "0.1": 12.886231422424316,
                    "0.25": 13.258700370788574,
                    "0.5": 14.190567970275879,
                    "0.75": 14.165388107299805,
                    "0.9": 13.484189987182617
                },
                "2024-08-29 01:00:00": {
                    "mean": 19.67905044555664,
                    "0.1": 18.73299789428711,
                    "0.25": 18.219327926635742,
                    "0.5": 19.67905044555664,
                    "0.75": 19.595125198364258,
                    "0.9": 18.450546264648438
                },
                "2024-08-29 02:00:00": {
                    "mean": 28.134199142456055,
                    "0.1": 23.75575065612793,
                    "0.25": 23.88021469116211,
                    "0.5": 28.134199142456055,
                    "0.75": 28.587217330932617,
                    "0.9": 28.828346252441406
                },
                "2024-08-29 03:00:00": {
                    "mean": 26.441926956176758,
                    "0.1": 23.666385650634766,
                    "0.25": 23.790849685668945,
                    "0.5": 26.441926956176758,
                    "0.75": 27.10835838317871,
                    "0.9": 27.3494873046875
                },
                "2024-08-29 04:00:00": {
                    "mean": 29.589374542236328,
                    "0.1": 25.67804718017578,
                    "0.25": 25.738853454589844,
                    "0.5": 29.589374542236328,
                    "0.75": 28.958642959594727,
                    "0.9": 29.282175064086914
                }
            }
        }
    }
}
// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  yearFilterOn: false,
  yearFilter: null,
};

const WeatherStationSlice = createSlice({
  name: "selectedStation",
  initialState,
  reducers: {
    setStationFilter: (state, action) => {
      state.yearFilterOn = action.payload.yearFilterOn;
      state.yearFilter = action.payload.yearFilter;
    },
  },
});

export const { setStationFilter } = WeatherStationSlice.actions;
export default WeatherStationSlice.reducer;

export const updateGeoPointCustom = /* GraphQL */ `
  mutation UpdateGeoPoint(
    $input: UpdateGeoPointInput!
    $condition: ModelGeoPointConditionInput
  ) {
    updateGeoPoint(input: $input, condition: $condition) {
      id
      name
      lat
      lng
      client
      value {
        value
        currency
        __typename
      }
      description
      assetGroupId
      freqAnalysis {
        nextToken
        __typename
      }
      alerts {
        nextToken
        __typename
      }
      # precipCurveId
      # precipCurve {
      #   id
      #   # type
      #   name
      #   description
      #   xUnit
      #   yUnit
      #   createdAt
      #   updatedAt
      #   owner
      #   __typename
      # }
      windCurveId
      windCurve {
        id
        name
        description
        xUnit
        yUnit
        curveData {
          x
          y
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      solarCurveId
      # solarCurve { Craig Upddate this
      #   id
      #   type
      #   name
      #   description
      #   xUnit
      #   yUnit
      #   createdAt
      #   updatedAt
      #   owner
      #   __typename
      # }
      createdAt
      updatedAt
      userGeoPointsId
      owner
      __typename
    }
  }
`;


export const deleteGeoPointCustom = /* GraphQL */ `
  mutation DeleteGeoPoint(
    $input: DeleteGeoPointInput!
    $condition: ModelGeoPointConditionInput
  ) {
    deleteGeoPoint(input: $input, condition: $condition) {
      id
      name
      elevation
      client
      description
      assetGroupId
      createdAt
      updatedAt
      userGeoPointsId
      owner
      __typename
    }
  }
`;
// @ts-nocheck
import React, { useEffect, useState, forwardRef, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Source, Layer, Popup } from "react-map-gl";
import { setRainGridIntersects } from "../../store/rainGridSlice";

export const RainGridLayerId = "RAIN_GRID_LAYER";

const RainGridLayer = forwardRef((props, mapRef) => {
  const rainGrid = useSelector((state) => state.rainGrid);
  const assets = useSelector((state) => state.assets);
  const rainGridLayerVisible = useSelector(
    (state) => state.mapState.rainGridLayerVisible
  );
  const [sourceDataReady, setSourceDataSwitch] = useState(0);
  const loadedRainGridYear = useRef(null); //NEED THIS FOR NOW FOR REFS :?
  const [rainGridGeoJson, setRainGridGeoJson] = useState(null);
  const [rainHoverSection, setRainHoverSection] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    mapRef.current.on("mousemove", "rainGridLayer", handleMouseEnter);
    mapRef.current.on("mouseleave", "rainGridLayer", handleMouseExit);
    mapRef.current.on("idle", "rainGridLayer", handleGridInitialize);

    return () => {
      // mapRef.current.off("mousemove", "rainGridLayer");
      // mapRef.current.off("mouseleave", "rainGridLayer");
      // mapRef.current.off("idle", "rainGridLayer");
    };
  }, [assets.data]);

  //raingrid year changes, fetch stored JSON
  useEffect(() => {
    if (rainGrid.year) {
      // handleYearSelect(rainGrid.year);
    }
  }, [rainGrid.year]);

  useEffect(() => {
    if (sourceDataReady > 0 && assets?.data) {
      const rainGridIntersects = assets.data.reduce(
        (acc, { lat, lng, id, name }) => {
          const point = mapRef.current.project([lng, lat]);
          const [cell] = mapRef.current.queryRenderedFeatures(point, {
            layers: ["rainGridLayer"],
          });
          return {
            ...acc,
            [id]: {
              id,
              name,
              cellId: cell?.properties?.id,
              Precip_mm: cell?.properties?.Precip_mm,
            },
          };
        },
        {}
      );
      dispatch(setRainGridIntersects(rainGridIntersects));
    }
  }, [sourceDataReady]);

  //Source data can be updated, but we need for it to be reflected in mapbox in order to query for intersects
  const handleGridInitialize = (e) => {
    const isSourceLoaded = mapRef.current.isSourceLoaded(RainGridLayerId);
    const raidGridDataSource = mapRef.current.getSource(RainGridLayerId);

    if (
      isSourceLoaded &&
      raidGridDataSource._data?.year !== loadedRainGridYear?.current
    ) {
      loadedRainGridYear.current = raidGridDataSource._data?.year;
      setSourceDataSwitch((state) => state + 1);
    }
  };

  const handleMouseEnter = (event) => {
    const { features } = event;

    if (features && features.length > 0) {
      const hoveredFeature = features[0];
      const feature = hoveredFeature?.properties;
      if (rainHoverSection?.id !== feature?.id && feature?.id !== null) {
        const coordinate = hoveredFeature.geometry.coordinates[0];
        const horizontalMiddle = (coordinate[0][0] + coordinate[2][0]) / 2;
        const verticalMiddle = (coordinate[0][1] + coordinate[1][1]) / 2;
        setRainHoverSection({
          ...hoveredFeature.properties,
          coordinates: [horizontalMiddle, verticalMiddle],
        });
      }
    }
  };

  const handleMouseExit = () => {
    setRainHoverSection(null);
  };

  //CRAIG ABSTRACT TO UTIL
  // const handleYearSelect = async (year) => {
  //   const file = await Storage.get(
  //     `histRainfallGeoJson/${year}_hist_rainfall.geojson`,
  //     {
  //       download: true,
  //     }
  //   );
  //   const rainGridFile = await new Response(file.Body).json();
  //   setRainGridGeoJson({ ...rainGridFile, year });
  // };

  if (!rainGridGeoJson) {
    return false;
  }

  return (
    <Source id={RainGridLayerId} type="geojson" data={{ ...rainGridGeoJson }}>
      <Layer
        {...{
          source: RainGridLayerId,
          id: "rainGridLayer",
          type: "fill",
          paint: {
            "fill-color": [
              "interpolate",
              ["linear"],
              ["get", "Precip_mm"],
              0,
              "#00FFFF",
              30,
              "#00FFFF",
              60,
              "#FFFF00",
              90,
              "#FF0000",
              200,
              "#000000",
            ],
            "fill-opacity": rainGridLayerVisible ? 0.3 : 0,
          },
        }}
      />
      <Layer
        {...{
          source: RainGridLayerId,
          id: "rainGridHighlightLayer",
          type: "fill",
          paint: {
            "fill-opacity": rainGridLayerVisible ? 1 : 0,
            "fill-color": [
              "case",
              ["==", ["get", "id"], rainHoverSection?.id ?? null],
              "rgba(0, 0, 0, 0.3)",
              "rgba(0, 0, 0, 0.0)",
            ],
            "fill-outline-color": [
              "case",
              ["==", ["get", "id"], rainHoverSection?.id ?? null],
              "rgba(0, 0, 0, 1)",
              "rgba(0, 0, 0, 0.0)",
            ],
          },
        }}
      />
      {rainHoverSection?.coordinates && rainGridLayerVisible && (
        <Popup
          closeButton={false}
          anchor="bottom-left"
          offset={15}
          latitude={rainHoverSection.coordinates[1]}
          longitude={rainHoverSection.coordinates[0]}
        >
          {rainHoverSection.Precip_mm} mm
        </Popup>
      )}
    </Source>
  );
});

export default RainGridLayer;

// @ts-nocheck
import React, { useLayoutEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/icons/erode_icon.svg";

const LoadingIcon = ({
  pixelSize = 200,
  logoMult = 0.66,
  borderThick = 16,
  fullPage = true,
}) => {
  const [logoSize, setLogoSize] = useState(null);

  useLayoutEffect(() => {
    setLogoSize(Math.floor(logoMult * parseInt(pixelSize)));
  }, [pixelSize, logoMult]);

  if (!logoSize) {
    return false;
  }

  return (
    <div
      className={`flex items-center justify-center ${
        fullPage ? "min-h-screen" : ""
      }`}
    >
      <div className="relative">
        <div
          style={{
            borderWidth: `${borderThick}px`,
            width: `${pixelSize}px`,
            height: `${pixelSize}px`,
          }}
          className={`loader border-x-primary-500 border-y-eai-secondary rounded-full `}
        ></div>

        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center ">
          <Logo height={logoSize} width={logoSize} />
        </div>
      </div>
    </div>
  );
};

export default LoadingIcon;

// @ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRainGridYear } from "../../../store/rainGridSlice";
import AssetRainGrid from "./AssetRainGrid";

const RainGridTab = () => {
  const dispatch = useDispatch();
  const [selectedYear, setSelectedYear] = useState(1892);
  const rainGrid = useSelector((state) => state.rainGrid);
  const assets = useSelector((state) => state.assets);

  const handleYearSelect = () => {
    dispatch(setRainGridYear(selectedYear));
  };

  return (
    <div className="overflow-y-scroll max-h-[70vh]">
      <div className="slidecontainer">
        <div className="text-xl font-normal">
          Historical Maximum 24 Hour Rainfall
        </div>
        <div className="text-sm font-montserrat py-2">
          Currently displaying data for {rainGrid.year}. Use the slider to
          select another year.{" "}
        </div>
        <span className="text-normal font-medium font-montserrat p-3">
          {" "}
          {selectedYear}
        </span>
        <br />
        <input
          onInput={(e) => setSelectedYear(e.target.value)}
          value={selectedYear}
          type="range"
          min="1892"
          max="2020"
          className="slider"
          id="myRange"
        />
        <br />
        <button
          className="bg-white border rounded p-2 my-2"
          onClick={() => handleYearSelect()}
        >
          {" "}
          Click to Load
        </button>
      </div>
      {assets?.data ? (
        <AssetRainGrid
          raingridFlag={true}
          assets={assets.data}
          rainGridData={rainGrid.intersects}
          onAssetClick={() => console.log("Check")}
        />
      ) : (
        <div>No data</div>
      )}
    </div>
  );
};

export default RainGridTab;

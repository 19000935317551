// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  year: null,
  intersects: null,
};

const RainGridSlice = createSlice({
  name: "rainGrid",
  initialState,
  reducers: {
    setRainGridYear: (state, action) => {
      state.year = action.payload;
    },
    setRainGridIntersects: (state, action) => {
      state.intersects = action.payload;
    },
  },
});

export const { setRainGridYear, setRainGridIntersects } = RainGridSlice.actions; // for non async
export default RainGridSlice.reducer;

// @ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import {
  setModalState,
  modalViewsConst,
  setModalOpen,
} from "../../store/modalSlice";
import { deleteAsset } from "../../store/assetsSlice";
import { deleteAssetGroupFunc } from "../../store/assetGroupSlice";
import { ReactComponent as GroupIcon } from "../../assets/icons/group-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import {
  delSolarEnModel,
  deletePCurve,
  deleteSolarEnergyModel,
} from "../../store/pCurveSlice";

const DeleteApprove = ({ data, deleteType, deleteTypeLabel }) => {
  const { name, id } = data;
  const dispatch = useDispatch();

  const handleDelete = async () => {
    if (deleteType === "asset") {
      await dispatch(deleteAsset({ id }));
      dispatch(setModalOpen(false));
    } else if (deleteType === "assetGroup") {
      await dispatch(deleteAssetGroupFunc({ id }));
      dispatch(setModalOpen(false));
    } else if (deleteType === "pCurve") {
      await dispatch(deletePCurve({ id }));
      dispatch(setModalOpen(false));
    } else if (deleteType === "SolarEnergyModel") {
      await dispatch(delSolarEnModel({ id }));
      dispatch(setModalOpen(false));
    }
  };

  // #CodyFix styling

  return (
    <div className="">
      <div className="flex flow-row grow items-center border-b border-gray-200 p-2">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <GroupIcon className="h-6 w-6" />
        </div>
        <div
          className="flex grow text-gray-900 text-xl font-semibold font-Inter"
          style={{ textTransform: "capitalize" }}
        >
          Delete {deleteTypeLabel}
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>

      <div className="h-1 flex grow">
        <div className="h-1 w-full bg-primary-500"></div>
      </div>

      <div className="flex flex-col grow p-5">
        <div className="text-gray-900 text-xl font-semibold mb-2 font-Inter">
          Are you sure you want to permanently delete your {deleteTypeLabel}{" "}
          named {name}?
        </div>
        <div className="text-red-600 text-md font-normal font-Inter">
          This action can not be undone!
        </div>
      </div>

      <div className="flex flex-row grow space-x-3 p-5">
        <button className="flex-grow eai-btn my-1" type="submit">
          <div className="mx-3" onClick={() => dispatch(setModalOpen(false))}>
            Cancel
          </div>
        </button>
        <button
          onClick={() => handleDelete()}
          className="flex-grow eai-btn-red my-1"
          type="submit"
        >
          <div className="mx-3">Delete</div>
        </button>
      </div>
    </div>
  );
};

export default DeleteApprove;

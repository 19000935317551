// @ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import { daysOfWeek } from "../../../util/constants";
import { capitalizeWords } from "../../../util/utils";
import { editAlert } from "../../../store/alertsSlice";
import { setModalState, modalViewsConst } from "../../../store/modalSlice";

const AlertListRecord = ({ alert }) => {
  const dispatch = useDispatch();

  const alertDaysAbberv = () => {
    const alertDays = daysOfWeek.reduce((acc, curr) => {
      if (alert[`${curr}Flag`]) {
        return [...acc, curr.substring(0, 3)];
      }
      return acc;
    }, []);
    return capitalizeWords(alertDays.join(", "));
  };

  const handleAlertActive = () => {
    dispatch(editAlert({ ...alert, active: !alert.active }));
  };

  const handleModalOpen = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.editAssetAlertForm,
        properties: alert,
      })
    );
  };

  return (
    <div className="border mb-2" onClick={handleModalOpen}>
      <div className="flex justify-between p-2 pb-0">
        <div className="">{alert.emails.map((email) => email)}</div>
        <div className="flex align-middle pr-2">
          <button className="eai-btn">
            <label className="switch">
              <input
                type="checkbox"
                checked={alert.active}
                onChange={() => handleAlertActive()}
              />
              <span className="slider"></span>
            </label>{" "}
          </button>
        </div>
      </div>
      <div className="flex justify-between px-2">
        <div className="text-xl">
          {alert.threshold}
          {" > "}
          {alert.probability}%
        </div>
        <div className="font-bold">{alertDaysAbberv()}</div>
      </div>
      <div className="flex justify-between px-2">
        <div className="font-bold">70 years</div>
        <div>
          {alert.emails.length > 0 && "email"}
          {alert.phoneNumbers.length > 0 && alert.emails.length > 0 && ", "}
          {alert.phoneNumbers.length > 0 && "text"}
        </div>
      </div>
    </div>
  );
};

export default AlertListRecord;

// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as CloudRaining } from "../assets/icons/cloud-raining-05.svg";
import { ReactComponent as WindIcon } from "../assets/icons/wind-02.svg";
import { ReactComponent as SunIcon } from "../assets/icons/sun.svg";
import { ReactComponent as DownIcon } from "../assets/icons/chevron-down.svg";

const ForecastSelect = ({
  activeForecast,
  foreCastSchema,
  onForecastSelect,
}) => {
  const [expandedFlag, setExpanded] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  });

  const handleSelectForecast = (name, main) => {
    if (!main && name !== activeForecast) {
      onForecastSelect(name);
    }
    setExpanded(false);
  };

  const handleDropdownClick = () => {
    if (!expandedFlag) {
      setExpanded(true);
    }
  };

  const renderSelection = (
    forecast,
    key,
    hoverEnabled = true,
    main = false,
    downArrow = false
  ) => {
    if (!forecast) {
      return false;
    }
    const { Icon, name } = forecast;

    let divClassName = "bg-grey-200 text-eai-secondary-blue";
    let iconColor = "stroke-[#84979A]";
    if (hoverEnabled) {
      divClassName =
        "bg-grey-200 text-eai-secondary-blue hover:text-white hover:bg-eai-primary-accent";
      iconColor += " group-hover:stroke-white";
    } else if (activeForecast === key) {
      divClassName = "bg-white font-semibold text-eai-secondary-blue";
      iconColor = "stroke-eai-secondary-blue";
    }

    return (
      <div
        key={key}
        onClick={() => handleSelectForecast(key, main)}
        className={`h-7 ${
          main ? "m-1" : "mx-1"
        } flex  flex-row ${divClassName} justify-between items-center rounded-md group`}
      >
        <div className="flex ">
          <Icon
            // style={{ stroke: 'black' }}
            // stroke={`${activeForecast === key ? "white" : "#84979A"}`}
            className={`h-5 w-5 mx-1 md:mt-[2px]  ${iconColor} `}
          />
          <div className="text-xs mx-3 p-1 font-Inter hidden md:block">
            {name}
          </div>
          {name === "Wind (80m)" && (
            <div className="text-xs mx-1 p-1 font-Inter md:hidden block">
              80m
            </div>
          )}
          {name === "Wind (10m)" && (
            <div className="text-xs mx-1 p-1 font-Inter md:hidden block">
              10m
            </div>
          )}
        </div>
        {downArrow ? (
          <DownIcon
            stroke="white"
            className={`h-5 w-5 mx-1 stroke-eai-secondary-blue md:block hidden`}
          />
        ) : (
          <span></span>
        )}
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className="relative h-9 w-20 md:w-44 "
      onClick={handleDropdownClick}
    >
      {!expandedFlag ? (
        <div className="absolute top-0 left-0 right-0 bg-white border rounded-lg border-gray-200 z-[100] ">
          {renderSelection(
            foreCastSchema[activeForecast],
            activeForecast,
            false,
            true,
            true
          )}
        </div>
      ) : (
        <div className="absolute top-0 left-0 right-0 bg-white border rounded-lg border-gray-200 z-[100] pb-1">
          {renderSelection(
            foreCastSchema[activeForecast],
            activeForecast,
            false,
            true,
            true
          )}
          {Object.keys(foreCastSchema).map((key) => {
            const forecast = foreCastSchema[key];
            return renderSelection(forecast, key);
          })}
        </div>
      )}
    </div>
  );
};

export default ForecastSelect;

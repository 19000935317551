// @ts-nocheck
import React from "react";
import { ReactComponent as GroupIcon } from "../../assets/icons/group-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { useDispatch } from "react-redux";
import {
  modalViewsConst,
  setModalOpen,
  setModalState,
} from "../../store/modalSlice";

export const AddGroupFirst = () => {
  const dispatch = useDispatch();
  const handleConfirm = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.addAssetGroup,
        properties: null,
      })
    );
  };

  return (
    <div className="">
      <div className="flex flow-row grow items-center border-b border-gray-200 p-5">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <GroupIcon className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-600 text-md font-normal font-Inter mr-3 justify-end"></div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>

      <div className="h-1 flex grow"></div>
      <div className="flex grow justify-center items-center">
        <div className="flex flex-0 w-14 h-14 bg-white rounded-md shadow border border-gray-200 items-center mt-8 justify-center">
          <GroupIcon className="h-6 w-6" />
        </div>
      </div>
      <div className="flex flex-col grow p-5 items-center justify-center">
        <div className="text-gray-900 text-xl font-semibold mb-2 font-Inter">
          Add a Group First
        </div>
        <div className="text-gray-400 text-md font-normal font-Inter">
          You need to create a group before you can add a new asset
        </div>
      </div>
      <div className="flex flex-row grow space-x-3 p-5">
        <button
          className="flex-grow eai-btn my-1"
          onClick={() => dispatch(setModalOpen(false))}
        >
          <div className="mx-3">Cancel</div>
        </button>
        <button className="flex-grow eai-btn-grn my-1" onClick={handleConfirm}>
          <div className="mx-3">Okay</div>
        </button>
      </div>
    </div>
  );
};

// @ts-nocheck
// import { API, Storage, graphqlOperation } from "aws-amplify";

import { stationYearlyRainMaxesByStationIdAndYear } from "../graphql/queries";
import { myTInit } from "../api/awsLamba";

export const idfFileNames = {
  AnnualMax: "AnnualMax",
  ReturnPeriod: "ReturnPeriod",
  ReturnPeriodRates: "ReturnPeriodRates",
  ReturnPeriodConfidenceInterval: "ReturnPeriodConfidenceInterval",
  Interpolation: "Interpolation",
};

//put param for json file name, with no .json suffice
export const fetchS3File = async (fileName) => {
  const file = await Storage.get(`${fileName}.json`, { download: true });
  return await new Response(file.Body).json();
};

export const calculatePercentile = (data, percentile) => {
  if (data.length === 0) return 0;

  data.sort((a, b) => a - b);
  var index = (percentile / 100) * (data.length - 1);

  if (Math.floor(index) === index) {
    return data[index];
  } else {
    var lower = data[Math.floor(index)];
    var upper = data[Math.ceil(index)];
    return lower + (upper - lower) * (index - Math.floor(index));
  }
};

export const promiseAll = async (stationId) => {
  return await Promise.all(
    Object.keys(idfFileNames).map((key) =>
      fetchS3File(
        `canadaIDF/climateStationsJSON/${stationId}/${stationId}_${key}`
      )
    )
  );
};

export const capitalizeWords = (str) => {
  // Split the string into an array of words
  let words = str.toLowerCase().split(" ");

  // Capitalize the first letter of each word
  let capitalizedWords = words.map((word) => {
    let firstLetter = word.charAt(0).toUpperCase();
    let restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });

  // Join the capitalized words back into a string
  let capitalizedStr = capitalizedWords.join(" ");

  return capitalizedStr;
};

export const formatAnnualData = (annualData) => {
  const format = {
    index: [],
    Year: [],
    "5min": [],
    "10min": [],
    "15min": [],
    "30min": [],
    "1h": [],
    "2h": [],
    "6h": [],
    "12h": [],
    "24h": [],
  };
  annualData.forEach((yearData) => {
    Object.keys(yearData).forEach((key) => {
      const data =
        key === "Year" ? parseInt(yearData[key]) : parseFloat(yearData[key]);
      format[key.replace(/ /g, "")].push(data);
    });
  });
  delete format.index;

  return format;
};

export const convertToGeoJSON = (mapData) => {
  // Create a GeoJSON object
  const geoJSON = {
    type: "FeatureCollection",
    features: [],
  };

  // Add map data to the GeoJSON object
  // Assuming the mapData contains an array of points with 'longitude' and 'latitude' properties
  mapData.forEach((point) => {
    const feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [point.lng, point.lat],
      },
      properties: point,
    };

    geoJSON.features.push(feature);
  });

  return geoJSON;
};

// export const fetchAllStationRain = async (stationArray, [minYear, MaxYear]) => {
//   // CRAIG SELECT THIS LATER?
//   let yearlyMaxes;
//   try {
//     yearlyMaxes = await Promise.all(
//       stationArray.map((stationId) => {
//         return API.graphql({
//           query: stationYearlyRainMaxesByStationIdAndYear,
//           variables: {
//             stationId,
//             year: { between: [minYear, MaxYear] },
//           },
//         });
//       })
//     );
//   } catch (e) {
//     console.log(e);
//   }
//   const indexedYearlyMaxes = stationArray.reduce((acc, curr, index) => {
//     return [
//       ...acc,
//       yearlyMaxes[
//         index
//       ].data.stationYearlyRainMaxesByStationIdAndYear.items.map((record) => {
//         //CRAIG SORT THIS OUT LATER, we're passing all data to endpoint
//         return {
//           Year: record.year,
//           "5min": record.precip_mm,
//           "10min": record.precip_mm,
//           "15min": record.precip_mm,
//           "30min": record.precip_mm,
//           "1h": record.precip_mm,
//           "2h": record.precip_mm,
//           "6h": record.precip_mm,
//           "12h": record.precip_mm,
//           "24h": record.precip_mm,
//         };
//       }),
//     ];
//   }, []);

//   const formattedDataArray = indexedYearlyMaxes.map((station) =>
//     myTInit(formatAnnualData(station))
//   );

//   const graphReadyArray = await Promise.all(
//     formattedDataArray.map((formattedStationMax) =>
//       API.post("statsapi", "/stats", formattedStationMax)
//     )
//   );

//   const responseGraphReadyData = graphReadyArray.reduce((acc, curr, index) => {
//     return { ...acc, [stationArray[index]]: curr.data };
//   }, {});
//   return responseGraphReadyData;
// };

export const clipArrayWithinRange = (sortedArray, rangeStart, rangeEnd) => {
  // Binary search for the starting index of the range
  let start = 0;
  let end = sortedArray.length - 1;
  let startIndex = -1;

  while (start <= end) {
    const mid = Math.floor((start + end) / 2);

    if (sortedArray[mid] >= rangeStart) {
      startIndex = mid;
      end = mid - 1;
    } else {
      start = mid + 1;
    }
  }

  // Binary search for the ending index of the range
  start = 0;
  end = sortedArray.length - 1;
  let endIndex = -1;

  while (start <= end) {
    const mid = Math.floor((start + end) / 2);

    if (sortedArray[mid] <= rangeEnd) {
      endIndex = mid;
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  if (startIndex === -1 || endIndex === -1) {
    return []; // Range not found, return an empty array
  }

  // Clip the array within the range
  return sortedArray.slice(startIndex, endIndex + 1);
};

//used for matching an objects properties with the model schema
export const schemaMatch = (inputRecord, schema) => {
  return schema.reduce(
    (acc, curr) => ({ ...acc, [curr]: inputRecord[curr] }),
    {}
  );
};

export const filterObjectBySchema = (obj, schema) => {
  const result = {};

  for (const key in schema) {
    // Check if the key exists in both the object and the schema
    if (schema.hasOwnProperty(key) && obj.hasOwnProperty(key)) {
      // If the schema key is an object (indicating nested properties), recurse
      if (
        typeof schema[key] === "object" &&
        schema[key] !== null &&
        !Array.isArray(schema[key])
      ) {
        result[key] = filterObjectBySchema(obj[key], schema[key]);
      } else {
        // Otherwise, directly assign the value from the object to the result
        result[key] = obj[key];
      }
    }
  }

  return result;
};

export const separatePercentileChannels = (data) => {
  const otherChannels = {};
  const percentiles = {};

  Object.keys(data).forEach((key) => {
    if (/^p\d+$/.test(key)) {
      percentiles[key] = data[key];
    } else {
      otherChannels[key] = data[key];
    }
  });

  return { otherChannels, percentiles };
};

// @ts-nocheck
import React, { useEffect } from "react";
import MapViewerReact from "../components/MapViewerReact";
import { useDispatch } from "react-redux";
import { fetchAssets } from "../store/assetsSlice";
import TabViewer from "../components/TabViewer";
import MapToolbar from "../components/MapToolbar";

const MapPage = () => {
  const dispatch = useDispatch();

  //Load Assets
  useEffect(() => {
    //get All User Assets
    dispatch(fetchAssets());
  }, []);

  return (
    <div className="flex-1 p-5 bg-eai-accent-lt flex-col flex">
      <div className="bg-white p-5 rounded-md flex-1 mt-[75px]">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-3/5 md:mr-5 sm:m-0">
            <MapToolbar />
            <MapViewerReact />
          </div>
          <div className="md:w-2/5 bg-eai-lt p-5 flex-col">
            <TabViewer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapPage;

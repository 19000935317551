// @ts-nocheck
import React from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

const PCurveGraph = ({ data, xAxisLabel, yAxisLabel }) => {
  const sortedData = [...data].sort((a, b) => a.x - b.x);

  const maxYValue =
    sortedData.reduce((max, item) => Math.max(max, item?.y), 0) * 1.1; // 10% buffer

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        min: 0,
        title: {
          display: true,
          text: xAxisLabel,
        },
      },
      y: {
        display: true,
        min: 0,
        title: {
          display: true,
          text: yAxisLabel,
        },
        max: Math.ceil(maxYValue),
      },
    },
  };

  const config = {
    datasets: [
      {
        data: sortedData,
        showLine: true,
      },
    ],
  };

  return <Scatter options={options} data={config} />;
};

export default PCurveGraph;

// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Slider from "@mui/material/Slider";
import {
  setSelectedAssetProximity,
  setStationYrlyCountFilter,
  setStationYearRange,
  setFilteredStationIds,
  postFreqAnalysis,
} from "../../../store/assetsSlice";
import ProximityStationList from "./ProximityStationList";
import GraphMultiStation from "../../GraphMultiStation";
import {
  minRainfallDataYear,
  maxRainfallDataYear,
  minRecordCount,
} from "../../../util/constants";
import { clipArrayWithinRange } from "../../../util/utils";
import NowForecasts from "../../AssetForecastTabs/Now/NowForecasts";

const AssetProximity = () => {
  const originalStationGumbel = useRef(null);
  const [stationGumbel, setStationGumbel] = useState({});
  const [calculatedAverage, setCalculatedAverage] = useState(null); //CRAIG set to ref instead?
  const dispatch = useDispatch();
  const assets = useSelector((state) => state.assets);
  const {
    selectedAsset,
    stationYearRange,
    proximityStations,
    stationYrlyCountFilter,
    selectedAssetProximity,
    filteredProximityStationIds,
    freqAnalyses,
  } = assets; //CRAIG deconstruct more
  const [filteredProximityStations, setFilterProximityStations] = useState([]);

  useEffect(() => {
    const filteredStationIds = [];
    const tempFilteredProximityStations = proximityStations.filter(
      (station) => {
        const filteredYearArray = clipArrayWithinRange(
          station.dataYears,
          stationYearRange[0],
          stationYearRange[1]
        );
        const filterFlag = Boolean(
          filteredYearArray.length >= stationYrlyCountFilter
        );
        if (filterFlag) {
          filteredStationIds.push(station.stationID);
        }
        return filterFlag;
      }
    );

    dispatch(setFilteredStationIds(filteredStationIds));
    setFilterProximityStations(tempFilteredProximityStations);
  }, [
    proximityStations.length,
    selectedAssetProximity,
    stationYrlyCountFilter,
    stationYearRange[0],
    stationYearRange[1],
  ]);

  const handleStationCountNumber = (e) => {
    const range = e.target.value;
    console.log("range", range, stationYearRange[1], stationYearRange[0]);
    //minimum record count

    if (stationYearRange[0] + range > maxRainfallDataYear) {
      dispatch(
        setStationYearRange([maxRainfallDataYear - range, maxRainfallDataYear])
      );
    } else {
      if (stationYearRange[1] - stationYearRange[0] < range) {
        dispatch(
          setStationYearRange([
            stationYearRange[0],
            stationYearRange[0] + range,
          ])
        );
      }
    }

    dispatch(setStationYrlyCountFilter(parseInt(e.target.value)));
  };

  const handleDistanceChange = (e) => {
    dispatch(setSelectedAssetProximity(parseInt(e.target.value)));
  };

  const handleFetchStations = async (e) => {
    e.preventDefault();

    // const proximityStationIds = assets.filteredProximityStationIds;
    // const res = await fetchAllStationRain(
    //   proximityStationIds,
    //   assets.stationYearRange
    // );
    // originalStationGumbel.current = res;
    // setStationGumbel(res);
  };

  const handleStationToggle = ({ checked, stationId }) => {
    if (checked) {
      if (originalStationGumbel.current[stationId]) {
        const stationAddGumbel = {
          ...stationGumbel,
          [stationId]: originalStationGumbel.current[stationId],
        };
        setStationGumbel(stationAddGumbel);
      }
    }

    if (!checked) {
      const stationDeleteGumbel = {
        ...stationGumbel,
      };
      delete stationDeleteGumbel[stationId];
      setStationGumbel(stationDeleteGumbel);
    }
  };

  const handleSaveFreqAnalysis = () => {
    console.log("filteredProximityStations", filteredProximityStations);
    // assets.stationYrlyCountFilter
    // assets.selectedAssetProximity
    // stationYearRange
    const freqAnalysisMeta = {
      assetId: selectedAsset.id,
      radius: assets.selectedAssetProximity,
      minYear: stationYearRange[0],
      maxYear: stationYearRange[1],
      minimumRecordLength: stationYrlyCountFilter,

      plotPoints: calculatedAverage,
    };

    // use createFreqAnalysisStations
    //CRAIG promise All for now, but swithc to batch job later: https://medium.com/@jan.hesters/creating-graphql-batch-operations-for-aws-amplify-with-appsync-and-cognito-ecee6938e8ee

    // id: ID
    // freqAnalysisId: ID!
    // stationStationId: String!
    // const stations = [{
    //   stationId:'US1SCHR0124',
    //   id: 'aa7b1f696c114304be8046a671b65236' }] //filteredProximityStations, //filteredProximityStationIds.map((id) => { return {id}}),
    // console.log("freqAnalysisMeta", freqAnalysisMeta)

    //change this to avoid dispatch, write first with AWS then dispatch after everything is fetched?
    dispatch(postFreqAnalysis({ freqAnalysisMeta, filteredProximityStations }));

    //   id: ID!
    // assetId: ID! @index(name:"freqAnalysisByAssetId")
    // name: String
    // radius: Float
    // minYear: Int!
    // maxYear: Int!
    // minimumRecordLength: Int!
    // plotPoints: [PlotPoints]
    // stations: [Station]
  };

  const handleYearlyDataRangeChange = (event, newValue, activeThumb) => {
    const minDistance = Math.max(assets.stationYrlyCountFilter, minRecordCount);

    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(
          newValue[0],
          maxRainfallDataYear - minDistance
        );
        dispatch(setStationYearRange([clamped, clamped + minDistance]));
      } else {
        let clamped = newValue[1];
        if (stationYearRange[0] === minRainfallDataYear) {
          clamped = Math.max(newValue[1], newValue[0] + minDistance);
        }
        dispatch(setStationYearRange([clamped - minDistance, clamped]));
      }
    } else {
      dispatch(setStationYearRange(newValue));
    }
  };

  // console.log("proximity stations", proximityStations)

  console.log("freqAnalyses ", freqAnalyses);
  const assetForecasts = freqAnalyses[selectedAsset?.id] ?? [];

  return (
    <div>
      {/* <div className="font-montserrat font-medium mt-2">Search Radius</div>
      <div className="text-sm font-montserrat mb-2">
        <input
          onInput={handleDistanceChange}
          value={assets.selectedAssetProximity}
          type="range"
          min="0"
          max="50"
          className="slider mt-1"
          id="myRange"  
        />{" "}
        {assets.selectedAssetProximity} km
      </div>
      <div className="font-montserrat font-medium">Minimum Years Recorded</div>
      <div className="text-sm font-montserrat mb-2">
        <input
          onInput={handleStationCountNumber}
          value={assets.stationYrlyCountFilter}
          type="range"
          min="1"
          max="50"
          className="slider mt-1"
          id="myRange"
        />{" "}
        {assets.stationYrlyCountFilter} Year
        {assets.stationYrlyCountFilter > 1 ? "s" : ""}
      </div> */}
      <div className="font-montserrat font-medium mt-2">
        Search Radius: {assets.selectedAssetProximity} km
      </div>
      <div className="px-4">
        <Slider
          onChange={handleDistanceChange}
          aria-label="Temperature"
          value={assets.selectedAssetProximity}
          valueLabelDisplay="auto"
          step={1}
          min={0}
          max={50}
        />
      </div>
      <div className="font-montserrat font-medium mt-2">
        Minimum Record Length: {assets.stationYrlyCountFilter} Year
        {assets.stationYrlyCountFilter > 1 ? "s" : ""}
      </div>
      <div className="px-4">
        <Slider
          onChange={handleStationCountNumber}
          aria-label="Temperature"
          value={assets.stationYrlyCountFilter}
          valueLabelDisplay="auto"
          step={1}
          min={1}
          max={50}
        />
      </div>

      <div className="font-montserrat font-medium mt-2">
        Yearly Data Range: {stationYearRange[0]} - {stationYearRange[1]}
      </div>
      <div className="px-4">
        <Slider
          getAriaLabel={() => "Minimum distance shift"}
          value={stationYearRange}
          onChange={handleYearlyDataRangeChange}
          valueLabelDisplay="auto"
          step={1}
          min={minRainfallDataYear}
          max={maxRainfallDataYear}
          disableSwap
        />
      </div>
      <div className="flex justify-around">
        <button className="eai-btn" onClick={handleFetchStations}>
          Load Historical Data
        </button>
        <button className="eai-btn" onClick={handleSaveFreqAnalysis}>
          Save Frequency Analysis
        </button>
      </div>
      {Object.keys(stationGumbel).length > 0 || assetForecasts.length > 0 ? (
        <GraphMultiStation
          data={stationGumbel}
          onCalculateAverage={(calcAverage) =>
            setCalculatedAverage(calcAverage)
          }
          forecasts={assetForecasts}
        />
      ) : null}
      <ProximityStationList
        // data={proximityStations}
        data={filteredProximityStations}
        onToggleStation={(stationEvent) => handleStationToggle(stationEvent)}
        activeStations={Object.keys(stationGumbel)}
      />
      <NowForecasts />
    </div>
  );
};

export default AssetProximity;

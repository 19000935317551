// @ts-nocheck
import React, { useEffect, useState } from "react";
import Authentication from "../components/Authentication";
import { getCurrentUser } from "aws-amplify/auth";
import { Link } from "react-router-dom";

interface IawsAuth {
  username: String;
  attributes: {
    preferred_username: String;
  };
}

export const Landing = () => {
  const [user, setUser] = useState<IawsAuth | null>(null);

  useEffect(() => {
    if (!user) {
      getCurrentUser()
        .then((user) => {
          setUser(user);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div className="flex-1 px-5 pt-20 bg-eai-accent-lt flex-col">
      <div className="bg-white p-5 rounded flex-1 mt-3 mb-5">
        <div className="bg-image py-20 px-10 flex flex-wrap justify-center items-center">
          <div className="uppercase font-[600] py-1 w-full font-josefin-sans text-center text-white text-[60px] md:text-[75px] ">
            Erode AI
          </div>
          <div className="text-2xl md:text-4xl py-1 w-full font-josefin-slab text-center font-[500] text-white">
            Weather changes. So should your rates.
          </div>
          <div className="p-5 my-10 bg-white text-md rounded-md font-medium font-montserrat center text-center w-[30vw]">
            {user ? (
              <p>Welcome {user?.attributes.preferred_username}</p>
            ) : (
              <Link to="/user">Register or Login to Continue</Link>
            )}
          </div>
        </div>
        <div className="bg-gray-200 p-5 mt-5 flex-1">
          <div className="text-2xl text-eai-dark w-full font-montserrat">
            How it Works
          </div>
          {/* <button onClick={() => handleTest()}>Test</button> */}
          <div className="text-sm md:w-1/2 sm:w-full py-3 text-eai-dark w-full font-montserrat">
            <ul>
              <li>
                <b>1.</b> Upload assets to the platform using the map, or bulk
                upload from a .csv file.
              </li>
              <li>
                <b>2.</b> For an asset, select a search radius to load
                historical stations and calculate rainfall return periods.
              </li>
              <li>
                <b>3.</b> Use the station explorer to view various North
                American weather stations.
              </li>
              <li>
                <b>4.</b> Determine regional average maximum values using the
                historical data layer.
              </li>
            </ul>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

// @ts-nocheck
import React from "react";
import AnnualRiskForecast from "./AnnualRiskForecast";

const index = () => {
  return (
    <div className="overflow-y-scroll max-h-[70vh]">
      <AnnualRiskForecast />
    </div>
  );
};

export default index;

// @ts-nocheck
export function createTickCallback() {
  let lastSubLabel = null; // This variable is captured in the closure of the returned function.

  return function (val, index) {
    const date = new Date(val);

    const mainLabel = this.getLabelForValue(date).split(", ")[1]; // 'HH:mm'
    const subLabel = this.getLabelForValue(date).split(", ")[0]; // 'MMM dd'
    const hourData = this.getLabelForValue(date).split(", ")[2];
    if (subLabel !== lastSubLabel) {
      // If it's different, update lastSubLabel and return both.
      lastSubLabel = subLabel;

      return `${subLabel}, ${mainLabel}`;
    }
  };
}

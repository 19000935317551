// @ts-nocheck
import React, { useState, useEffect } from "react";
import { HeadBreadCrumb } from "../components/AssetBreadCrumb";
import { AssetGroupFolder, NoAssetGroup } from "../components/AssetGroupFolder";
import { ReactComponent as BellIcon } from "../assets/icons/bell-icon.svg";
import { AssetCount } from "../components/TagStyles";
import { AssetTable } from "../components/AssetTable";
import { fetchAssets } from "../../../store/assetsSlice";
import { useDispatch, useSelector } from "react-redux";
import { setModalState, modalViewsConst } from "../store/modalSlice";
import { fetchAssetsByGroupId } from "../store/assetsSlice";
import { useParams } from "react-router-dom";
import AlertGroupRecord from "../components/AssetAlerts/AlertGroupRecord";

const AlertPage = (props) => {
  let { groupid } = useParams();
  const dispatch = useDispatch();
  const { assets, assetGroups } = useSelector(({ assets, assetGroups }) => ({
    assets,
    assetGroups,
  }));

  // Craig Put DYNAMIC DATA Here
  // useEffect(() => {
  //   // error handling here
  //   if (!assets.dataGroups[groupid]) {
  //     console.log("fetch");
  //     dispatch(fetchAssetsByGroupId(groupid));
  //   } else {
  //     console.log("already exists");
  //   }
  // }, []);

  // const handleAddAsset = () => {
  //   dispatch(
  //     setModalState({
  //       modalOpen: true,
  //       modalView: modalViewsConst.addAssetNEW,
  //       properties: null,
  //     })
  //   );
  // };

  // if (!assets.dataGroups[groupid]) {
  //   // #CodyFix
  //   return <h1>Loading</h1>;
  // }

  // #CodyFix. What should appear if there are no assets? suggest doing something similar to when there are no assets

  const assetGroupList =
    assetGroups?.groups &&
    Object.keys(assetGroups.groups).map((key) => assetGroups.groups[key]);
  console.log("assetGroups", assetGroupList);

  return (
    <>
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow">
          <HeadBreadCrumb text="Manage Asset Alerts">
            <BellIcon className=" w-5 h-5 ml-2 mr-1" />
          </HeadBreadCrumb>
          <div className="flex flex-col h-24 border-y border-gray-200 p-5 justify-center">
            <div className="text-gray-900 text-xl font-semibold mb-2 font-Inter">
              All Alerts
            </div>
            <div className="text-gray-400 text-md font-normal font-Inter">
              View and manage the alerts set-up for all your assets
            </div>
          </div>
          {/* <div className="flex-col grow p-5 h-[calc(100vh_-_9rem)] overflow-y-auto ">
            {assetGroupList.map((assetGroup) => (
              <AlertGroupRecord key={assetGroup.id} assetGroup={assetGroup} />
            ))}
          </div> */}
          {/* <AssetTable assetItems={assetGroupList} /> */}
          <div className="flex grow p-5 h-[calc(100vh_-_9rem)] overflow-y-auto">
            <div className="w-full bg-white overflow-x-auto border border-gray-200 rounded-md">
              <div
                style={{ borderRadius: "10px" }}
                className="w-full text-left"
              >
                <div className="w-full bg-white text-gray-600 text-sm font-bold font-Inter border-b border-gray-200">
                  <div className="px-6 py-5">Asset Group Name</div>
                </div>
              </div>

              {assetGroupList.map((assetGroup) => (
                <AlertGroupRecord key={assetGroup.id} assetGroup={assetGroup} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertPage;

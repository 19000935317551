// @ts-nocheck
import React, { useEffect, useState } from "react";
import LoadingIcon from "../components/LoadingIcon";
import { useParams } from "react-router-dom";
import { getUploadData } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import DataUploadForm from "./DataUploadForm";

const DataUploadEditForm = () => {
  const { custommodelid } = useParams();
  const [initialRecord, setInitialRecord] = useState(null);

  const fetchCustomModel = async () => {
    const client = generateClient();
    try {
      const { data } = await client.graphql({
        query: getUploadData,
        variables: {
          id: custommodelid,
        },
      });

      setInitialRecord(data.getUploadData);
      // setCustomModelRecords(data.listUploadData.items)
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchCustomModel();
  }, []);

  if (!initialRecord) {
    return (
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div
          className={`transition-opacity delay-700 duration-500 ease-in-out opacity-100`}
        >
          <LoadingIcon />
        </div>
      </div>
    );
  }

  console.log("initialRecord 1", initialRecord);
  return <DataUploadForm initialUploadForm={initialRecord} editFlag={true} />;
};

export default DataUploadEditForm;

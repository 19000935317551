// @ts-nocheck
import React, { useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch } from "react-redux";
import {
  modalViewsConst,
  setModalOpen,
  setModalState,
} from "../../store/modalSlice";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as HomeSmile } from "../../assets/icons/home-smile.svg";
import { postSolarEnergyModel } from "../../store/pCurveSlice";
import { set } from "date-fns";

const SolarArraySchema = z.object({
  name: z.string().trim().min(2).max(50),
  // type: z.string().min(2, { message: "type must be selected" }),
  description: z.string().trim(),
  xUnit: z.enum(["W/m²"], {
    errorMap: (issue, ctx) => ({ message: "Invalid Unit" }),
  }),
  yUnit: z.enum(["MW"], {
    errorMap: (issue, ctx) => ({ message: "Invalid Unit" }),
  }),
  dcCap: z
    .number()
    .min(0.001, { message: "DC Capacity must be greater than 0" }),
  acCap: z
    .number()
    .min(0.001, { message: "Generator Capacity must be greater than 0" }),
  panelEfficiency: z.enum([
    "0.170",
    "0.175",
    "0.180",
    "0.185",
    "0.190",
    "0.195",
    "0.200",
    "0.205",
    "0.210",
    "0.215",
    "0.220",
  ]),
  thermalLoss: z.enum(["-0.002", "-0.003", "-0.004", "-0.005"]),
  minIrradiance: z.enum(["25", "50", "100", "150", "200"]),
  trackingType: z.enum(["Fixed", "Horizontal"]),
  arrayTilt: z
    .number()
    .min(-90, { message: "Array tilt must be between -90 and 90" })
    .max(90, { message: "Array tilt must be between -90 and 90" }),
  arrayAzimuth: z
    .number()
    .min(0, { message: "Array azimuth must be between 0 and 360" })
    .max(360, { message: "Array azimuth must be between 0 and 360" }),
  maxTilt: z
    .number()
    .min(0, { message: "Max tilt must be between 0 and 90" })
    .max(90, { message: "Max tilt must be between 0 and 90" }),
  groundCoverRatio: z
    .number()
    .min(0, { message: "Panel spacing must be greater than or equal to 0" }),
});

type SolarArrayType = z.infer<typeof SolarArraySchema>;

const SolarPowerForm = ({ initialSPState, editFlag = false }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useForm<SolarArrayType>({
    defaultValues: {
      name: initialSPState?.name || "",
      // type: initialPCurve?.type || "wind",
      description: initialSPState?.description || "",
      xUnit: initialSPState?.xUnit || "W/m²",
      yUnit: initialSPState?.yUnit || "MW",
      dcCap: initialSPState?.dcCap || 0,
      acCap: initialSPState?.acCap || 0,
      panelEfficiency: initialSPState?.panelEfficiency || "0.190",
      thermalLoss: initialSPState?.thermalLoss || "-0.004",
      minIrradiance: initialSPState?.minIrradiance || "100",
      trackingType: initialSPState?.trackingType || "Fixed",
      arrayTilt: initialSPState?.arrayTilt || 45,
      arrayAzimuth: initialSPState?.arrayAzimuth || 180,
      maxTilt: initialSPState?.maxTilt || 45,
      groundCoverRatio: initialSPState?.groundCoverRatio || 0.285,
    },
    resolver: zodResolver(SolarArraySchema),
    mode: "onChange",
  });

  const submitSolarForm = async (data) => {
    dispatch(postSolarEnergyModel(data));
    dispatch(setModalOpen(false));
  };

  const handleModelBack = () => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.energyModelSelect,
        properties: null,
      })
    );
  };

  const handleNextForm = async () => {
    const result = await trigger(["name", "xUnit", "yUnit", "description"]);
    console.log("RESult", result);
    if (result) {
      setStep(2);
    }
  };

  const renderForm1 = () => {
    return (
      <div className={step === 1 ? "block" : "hidden"}>
        <div className="flex flex-col grow p-5">
          <div className="flex gap-8 justify-between">
            <div className="flex-1">
              <div className="flex justify-between">
                <div
                  name="name"
                  className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
                >
                  Curve Name
                </div>
                {errors.name ? (
                  <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                    {errors.name.message}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="flex">
                <input
                  name="name"
                  {...register("name")}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Type
                </div>
                {errors?.type ? (
                  <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                    {errors.type.message}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="flex grow">
                <div
                  disabled
                  className="flex bg-slate-400 grow border p-3 mb-5 text-md rounded-md"
                >
                  Solar
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-8 justify-between">
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  X axis Unit
                </div>
                {errors.xUnit ? (
                  <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                    {errors.xUnit.message}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="flex grow">
                <select
                  name="xaxisunit"
                  {...register("xUnit")}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                >
                  <option value="">--</option>
                  <option value="W/m²">W/m²</option>
                </select>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Y axis Unit
                </div>
                {errors.yUnit ? (
                  <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                    {errors.yUnit.message}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="flex grow">
                <select
                  name="yaxisunit"
                  {...register("yUnit")}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                >
                  <option value="">--</option>
                  <option value="MW">MW</option>
                </select>
              </div>
            </div>
          </div>
          <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
            Curve Description
          </div>
          <div className="flex grow">
            <textarea
              name="description"
              {...register("description")}
              className="flex grow border p-3 mb-2 text-md rounded-md"
            />
          </div>
        </div>
        <div className="flex flex-row grow space-x-3 p-5">
          <button className="flex-grow eai-btn my-1" onClick={handleModelBack}>
            <div className="mx-3">Cancel Form</div>
          </button>
          <button
            className="flex-grow eai-btn-grn my-1"
            onClick={handleNextForm}
          >
            <div className="mx-3">Next</div>
          </button>
        </div>
      </div>
    );
  };

  const renderForm2 = () => {
    return (
      <div className={step === 2 ? "block" : "hidden"}>
        <div className="flex grow flex-col p-5">
          <div className="flex gap-8 justify-between">
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Installed DC Capacity (MW at 1000 W/m²)
                </div>
              </div>
              <div className="flex">
                {/* We need the below for some reason, appears to be a bug with react-hook-forms */}
                <input className=" hidden grow border p-3 mb-5 text-md rounded-md" />
                <input
                  name="dcCap"
                  step="0.001"
                  {...register("dcCap", {
                    setValueAs: (value) =>
                      value === "" ? undefined : parseFloat(value),
                  })}
                  type="number" // Keeping type as text to demonstrate conversion, change to 'number' if you prefer a numeric input interface
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Generator AC Capacity (MW)
                </div>
              </div>
              <div className="flex grow">
                <input
                  name="acCap"
                  {...register("acCap", {
                    setValueAs: (value) =>
                      value === "" ? undefined : parseFloat(value),
                  })}
                  type="number"
                  step="0.001" // Change to 'number' for numeric input interface
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-8 justify-between">
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Panel Efficiency (%)
                </div>
              </div>
              <div className="flex grow">
                <select
                  name="panelEfficiency"
                  {...register("panelEfficiency")}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                >
                  <option value={0.17}>17</option>
                  <option value="0.175">17.5</option>
                  <option value="0.180">18</option>
                  <option value="0.185">18.5</option>
                  <option value="0.190">19 (Default)</option>
                  <option value="0.195">19.5</option>
                  <option value="0.200">20</option>
                  <option value="0.205">20.5</option>
                  <option value="0.210">21</option>
                  <option value="0.215">21.5</option>
                  <option value="0.220">22</option>
                </select>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Thermal Loss Coefficient (1/°C)
                </div>
              </div>
              <div className="flex grow">
                <select
                  name="thermalLoss"
                  {...register("thermalLoss")}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                >
                  <option value="-0.002">-0.002</option>
                  <option value="-0.003">-0.003</option>
                  <option value="-0.004">-0.004 (Default)</option>
                  <option value="-0.005">-0.005</option>
                </select>
              </div>
            </div>
          </div>
          <div className="flex gap-8 justify-between">
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Minimum Threshold Insolation (W/m²)
                </div>
              </div>
              <div className="flex grow">
                <select
                  name="minIrradiance"
                  {...register("minIrradiance")}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100 (Default)</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                </select>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Tracking Type
                </div>
              </div>
              <div className="flex grow">
                <select
                  name="trackingType"
                  {...register("trackingType")}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                >
                  <option value="Fixed">Fixed</option>
                  <option value="Horizontal">Horizontal</option>
                </select>
              </div>
            </div>
          </div>
          <div className="flex gap-8 justify-between">
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Array Tilt (Degrees from Flat)
                </div>
              </div>
              <div className="flex grow">
                <input
                  name="arrayTilt"
                  {...register("arrayTilt", {
                    setValueAs: (value) =>
                      value === "" ? undefined : parseFloat(value),
                  })}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Array Azimuth (Degrees Clockwise from N)
                </div>
              </div>
              <div className="flex grow">
                <input
                  name="arrayAzimuth"
                  {...register("arrayAzimuth", {
                    setValueAs: (value) =>
                      value === "" ? undefined : parseFloat(value),
                  })}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                />
              </div>
            </div>
          </div>
          <div className="flex gap-8 justify-between">
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Max Tilt (Horizontal Tracking Only)
                </div>
              </div>
              <div className="flex grow">
                <input
                  name="maxTilt"
                  {...register("maxTilt", {
                    setValueAs: (value) =>
                      value === "" ? undefined : parseFloat(value),
                  })}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="flex justify-between">
                <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  Ground Cover Ratio
                </div>
              </div>
              <div className="flex grow">
                <input
                  name="groundCoverRatio"
                  {...register("groundCoverRatio", {
                    setValueAs: (value) =>
                      value === "" ? undefined : parseFloat(value),
                  })}
                  className="flex grow border p-3 mb-5 text-md rounded-md"
                />
              </div>
            </div>
          </div>
          {Object.keys(errors).length > 0 && (
            <div className="all-errors text-red-500 text-sm font-normal leading-[18px]">
              <ul>
                {Object.entries(errors).map(([fieldName, error]) => (
                  <li className="py-2" key={fieldName}>
                    {error.message}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex flex-row grow space-x-3 px-5 pb-5">
          <button className="flex-grow eai-btn my-1">
            <div className="mx-3" onClick={() => setStep(1)}>
              Back
            </div>
          </button>
          <button className="flex-grow eai-btn-grn my-1" type="submit">
            <div className="mx-3">{editFlag ? `Update` : "Create"}</div>
          </button>
        </div>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(submitSolarForm)} className="">
      <div className="flex flow-row grow items-center border-b border-gray-200 p-2">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <HomeSmile className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          {editFlag
            ? `Edit Existing Asset: ${initalSPState.name}`
            : "New Solar Energy Model"}
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      {step === 1 ? renderForm1() : renderForm2()}
    </form>
  );
};

export default SolarPowerForm;

// @ts-nocheck
import React, { useEffect, useState } from "react";
import { ReactComponent as LightningIcon } from "../assets/icons/lightning.svg";
import { HeadBreadCrumb } from "../components/AssetBreadCrumb";
import { modalViewsConst, setModalState } from "../store/modalSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchPCurves, fetchSolarEnergyModels } from "../store/pCurveSlice";
import { CustomModelTable } from "../components/CustomModels/CustomModelTable";
import { useNavigate } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { listUploadData } from "../graphql/queries";
import { deleteUploadData } from "../graphql/mutations";

const PerformanceCurvePage = () => {
  const [customModelRecords, setCustomModelRecords] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getCustomModels = async () => {
    const client = generateClient();
    try {
      const { data } = await client.graphql({
        query: listUploadData,
      });
      setCustomModelRecords(data.listUploadData.items);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    getCustomModels();
  }, []);

  const deleteRecord = async (id) => {
    const client = generateClient();
    try {
      const { data } = await client.graphql({
        query: deleteUploadData,
        variables: { input: { id } },
      });

      const updatedRecords = customModelRecords.filter(
        (record) => record.id !== data.deleteUploadData.id
      );
      setCustomModelRecords(updatedRecords);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="flex-1 flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow">
          <HeadBreadCrumb text="Custom AI Models">
            <LightningIcon stroke="#6B7280" className=" w-5 h-5 ml-2 mr-1" />
          </HeadBreadCrumb>
          <div className="flex flex-row h-24 border-y border-gray-200 p-5 justify-left items-center">
            <div className="flex grow items-center">
              <div className="w-12 h-12 border border-gray-200 rounded-lg flex items-center shrink-0 justify-center mr-3">
                <LightningIcon stroke="#A2BBBF" />
              </div>
              <div className="flex-col items-center justify-center">
                <div className="text-gray-900 text-xl font-semibold font-Inter mb-1">
                  Custom AI Models
                </div>
                <div className="text-gray-500 text-sm font-normal font-Inter ml-1">
                  View, manage, and train your customer AI weather forecasting
                  models
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate(`/home/dataupload/new`)}
              className="flex-wrap eai-btn-grn my-1"
              type="submit"
            >
              <div className="mx-3">
                <span className="hidden md:block">+ New Custom Model</span>
                <span className="block md:hidden">+</span>
              </div>
            </button>
          </div>
          <div className="flex grow p-5 h-[calc(100vh_-_9rem)] overflow-y-auto">
            <div className="w-full bg-white overflow-x-auto border border-gray-200 rounded-md">
              {customModelRecords.length > 0 ? (
                <CustomModelTable
                  records={customModelRecords}
                  onDeleteRecord={(id) => deleteRecord(id)}
                />
              ) : (
                <div className="text-center"></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PerformanceCurvePage;

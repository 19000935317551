// @ts-nocheck
import React, { useState, useRef, useLayoutEffect } from "react";
import AssetListItem from "./AssetListItem";
import AssetListItemNEW from "./AssetListItemNEW";

const AssetListClipped = ({ assetList, assetGroup }) => {
  const [bottomDistance, setBottomDistance] = useState(0);
  const divRef = useRef(null);

  useLayoutEffect(() => {
    const calculateBottomDistance = () => {
      const viewportHeight = window.innerHeight;
      const { top } = divRef.current.getBoundingClientRect();
      setBottomDistance(viewportHeight - top);
    };

    calculateBottomDistance();

    // Optional: Recalculate when the window is resized
    window.addEventListener("resize", calculateBottomDistance);

    // Cleanup event listener
    return () => window.removeEventListener("resize", calculateBottomDistance);
  }, []);

  const populateList = () => {
    if (assetList.length === 0) {
      return (
        <div className="flex justify-center">
          <div>No Assets Matching Search</div>
        </div>
      );
    } else {
      return assetList.map((asset) => {
        return (
          <AssetListItemNEW
            assetGroup={assetGroup}
            asset={asset}
            key={asset.id}
          />
        );
      });
    }
  };

  return (
    <div
      style={{ height: `${bottomDistance}px` }}
      className={`scrollable-div  overflow-y-auto`}
      ref={divRef}
    >
      {populateList()}
      {/* {[1, 2, 3, 4].map((item) => {
        return <AssetListItemNEW key={item} />;
      })} */}
    </div>
  );
};

export default AssetListClipped;

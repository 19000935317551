// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
// import { ReactComponent as Hybrid } from "../assets/icons/layout-grid-02.svg";
// import { ReactComponent as Graph } from "../assets/icons/line-chart.svg";
// import { ReactComponent as Table } from "../assets/icons/rows-02.svg";
import { ReactComponent as DownIcon } from "../assets/icons/chevron-down.svg";
import { record } from "aws-amplify/analytics";

const TabViewSelect = ({ activeView, onViewSelect, viewSchema }) => {
  const [expandedFlag, setExpanded] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  });

  const handleSelectView = (name, main) => {
    if (!main && name !== activeView) {
      onViewSelect(name);
      record({
        name: "changeDisplayView",
        attributes: {
          view: name,
        },
      });
    }
    setExpanded(false);
  };

  const handleDropdownClick = () => {
    if (!expandedFlag) {
      setExpanded(true);
    }
  };

  const renderSelection = (
    forecast,
    key,
    hoverEnabled = true,
    main = false,
    downArrow = false
  ) => {
    const { Icon, name } = forecast;

    let divClassName = "bg-grey-200 text-eai-secondary-blue";
    let iconColor = "stroke-[#84979A]";
    if (hoverEnabled) {
      divClassName =
        "bg-grey-200 text-eai-secondary-blue hover:text-white hover:bg-eai-secondary-blue";
    } else if (activeView === key) {
      divClassName = "bg-slate-400 font-semibold text-black";
      iconColor = "stroke-black";
    }

    return (
      <div
        key={key}
        onClick={() => handleSelectView(key, main)}
        className={`h-7 ${
          main ? "m-1" : "mx-1"
        } flex flex-row ${divClassName} justify-between items-center rounded-md group`}
      >
        <div className="flex">
          <Icon
            // style={{ stroke: 'black' }}
            // stroke={`${activeForecast === key ? "white" : "#84979A"}`}
            className={`h-5 w-5 mx-1  ${iconColor} group-hover:stroke-white`}
          />
          <div className="text-xs mx-3 p-1 font-Inter hidden md:block">
            {/* {name} */}
          </div>
        </div>
        {downArrow ? (
          <DownIcon stroke="white" className={`h-5 w-5 mx-1 stroke-white`} />
        ) : (
          <span></span>
        )}
      </div>
    );
  };

  return (
    <div
      ref={ref}
      className="relative h-9 w-10 cursor-pointer mr-2"
      onClick={handleDropdownClick}
    >
      {!expandedFlag ? (
        <div className="absolute top-0 left-0 right-0 bg-slate-200 border rounded-lg border-gray-200 z-[100] ">
          {renderSelection(
            viewSchema[activeView],
            activeView,
            false,
            true,
            true
          )}
        </div>
      ) : (
        <div className="absolute top-0 left-0 right-0 bg-slate-200 rounded-lg border-gray-200 z-[100] pb-1">
          {renderSelection(
            viewSchema[activeView],
            activeView,
            false,
            true,
            true
          )}
          {Object.keys(viewSchema).map((key) => {
            const view = viewSchema[key];
            return renderSelection(view, key);
          })}
        </div>
      )}
    </div>
  );
};

export default TabViewSelect;

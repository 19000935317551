// @ts-nocheck
import React from "react";
import { ReactComponent as PowerIcon } from "../../assets/icons/lightning.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { useDispatch } from "react-redux";
import {
  modalViewsConst,
  setModalOpen,
  setModalState,
} from "../../store/modalSlice";

const EnergyCurveSelect = () => {
  const dispatch = useDispatch();

  const handleModelTypeSelect = (e) => {
    const energyType = e.target.value;
    let modalView = null;
    switch (energyType) {
      case "Wind":
        modalView = modalViewsConst.addPerformanceCurve;
        break;
      case "Solar":
        modalView = modalViewsConst.newSolarPowerCurve;
        break;
      default:
        return;
    }
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalView,
        properties: null,
      })
    );
  };

  return (
    <div>
      <div className=" flex flow-row grow items-center border-b border-gray-200 p-5">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <PowerIcon stroke="#A2BBBF" className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          Select Energy Model
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      <div className="flex justify-between mb-2">
        <div className="text-gray-500 text-sm my-2 font-normal font-['Inter'] leading-[18px]">
          Model
        </div>
        <span></span>
      </div>
      <div className="flex grow">
        <select
          name="assetGroupId"
          className="flex grow border p-3 mb-5 text-md rounded-md"
          onChange={handleModelTypeSelect}
        >
          <option selected disabled value="">
            Select Model Type
          </option>
          <option value="Wind">Wind</option>
          <option value="Solar">Solar</option>
          {/* <option value="HydroPower">HydroPower</option> */}
        </select>
      </div>
    </div>
  );
};

export default EnergyCurveSelect;

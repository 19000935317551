// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { generateClient } from "aws-amplify/api";
import {
  createPerformanceCurve,
  createSolarEnergyModel,
  deletePerformanceCurve,
  deleteSolarEnergyModel,
  updatePerformanceCurve,
} from "../graphql/mutations";
import { listPerformanceCurvesCustom } from "../graphql/customQueries";
import { filterObjectBySchema } from "../util/utils";
import { listSolarEnergyModels } from "../graphql/queries";

const client = generateClient();
const initialState = {
  data: {},
  windData: {},
  solarData: {},
  loading: "idle",
  error: null,
};

export const pCurveSchema = {
  id: true,
  type: true,
  name: true,
  description: true,
  curveData: [{ x: true, y: true }],
  xUnit: true,
  yUnit: true,
};

export const postPCurve = createAsyncThunk(
  "pcurve/postPCurve",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: createPerformanceCurve,
        variables: { input },
      });

      return data.createPerformanceCurve;
    } catch (error) {
      console.log("pcurve error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Solar
export const postSolarEnergyModel = createAsyncThunk(
  "pcurve/postSolarEnergyModel",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: createSolarEnergyModel,
        variables: { input },
      });
      return data.createSolarEnergyModel;
    } catch (error) {
      console.log("pcurve error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Solar
export const fetchSolarEnergyModels = createAsyncThunk(
  "pcurve/fetchSolarEnergyModels",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: listSolarEnergyModels,
        variables: { ...input },
      });

      return data.listSolarEnergyModels.items.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: curr }),
        {}
      );
    } catch (error) {
      console.log("pcurve error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

//Solar
export const delSolarEnModel = createAsyncThunk(
  "pcurve/deleteSolarEnergyModel",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: deleteSolarEnergyModel,
        variables: { input },
      });

      return data.deleteSolarEnergyModel;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchPCurves = createAsyncThunk(
  "pcurve/fetchPCurves",
  async (_, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: listPerformanceCurvesCustom,
      });

      const groupDict = data.listPerformanceCurves.items.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: curr }),
        {}
      );

      return groupDict;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deletePCurve = createAsyncThunk(
  "pcurve/deletePCurve",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: deletePerformanceCurve,
        variables: { input },
      });

      return data.deletePerformanceCurve;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editPCurve = createAsyncThunk(
  "pcurve/editPCurve",
  async (input, thunkAPI) => {
    try {
      const formattedInput = filterObjectBySchema(input, pCurveSchema);

      const { data } = await client.graphql({
        query: updatePerformanceCurve,
        variables: { input: formattedInput },
      });

      return data.updatePerformanceCurve;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const PCurveSlice = createSlice({
  name: "pCurve",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSolarEnergyModel.pending, (state) => {
        // state.error = null;
      })
      .addCase(postSolarEnergyModel.fulfilled, (state, action) => {
        state.solarData[action.payload.id] = action.payload;
      })
      .addCase(postSolarEnergyModel.rejected, (state) => {
        // state.error = null;
      })
      .addCase(fetchSolarEnergyModels.pending, (state) => {
        // state.error = null;
      })
      .addCase(fetchSolarEnergyModels.fulfilled, (state, action) => {
        state.solarData = action.payload;
      })
      .addCase(fetchSolarEnergyModels.rejected, (state) => {
        console.log("rejected");
        // state.error = null;
      })
      .addCase(delSolarEnModel.pending, (state) => {
        // state.error = null;
      })
      .addCase(delSolarEnModel.fulfilled, (state, action) => {
        delete state.solarData[action.payload.id];
      })
      .addCase(delSolarEnModel.rejected, (state) => {
        // state.error = null;
      })
      .addCase(postPCurve.pending, (state) => {
        state.error = null;
      })
      .addCase(postPCurve.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(postPCurve.rejected, (state) => {})
      .addCase(fetchPCurves.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchPCurves.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchPCurves.rejected, (state) => {})
      .addCase(deletePCurve.pending, (state) => {
        state.error = null;
      })
      .addCase(deletePCurve.fulfilled, (state, action) => {
        delete state.data[action.payload.id];
      })
      .addCase(deletePCurve.rejected, (state) => {})
      .addCase(editPCurve.pending, (state) => {
        state.error = null;
      })
      .addCase(editPCurve.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(editPCurve.rejected, (state) => {});
  },
});

export default PCurveSlice.reducer;

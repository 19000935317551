// @ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalState, modalViewsConst } from "../../../store/modalSlice";
// import { fetchAlertbyAssetId } from "../../../store/alertsSlice";
import AlertListRecord from "./AlertListRecord";

const AFAlerts = () => {
  const dispatch = useDispatch();
  const { assets, alerts } = useSelector(({ assets, alerts }) => ({
    assets,
    alerts,
  }));
  const { selectedAsset } = assets;

  useEffect(() => {
    // dispatch(fetchAlertbyAssetId(selectedAsset.id));
  }, [selectedAsset.id]);

  const handleAddAlert = (e) => {
    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.addAssetAlertForm,
        properties: null,
      })
    );
  };

  const assetAlerts = alerts[selectedAsset.id];

  return (
    <div className="border-2 border mt-2 p-2">
      <div className="flex justify-between p-2">
        <div className="text-2xl font-medium">Alerts</div>
        <div className="flex align-middle pr-2">
          <button onClick={handleAddAlert} className="eai-btn">
            Add Alert{" "}
          </button>
        </div>
      </div>
      <div>
        {assetAlerts?.length > 0 ? (
          <>
            {assetAlerts.map((alert) => (
              <AlertListRecord key={alert.id} alert={alert} />
            ))}
          </>
        ) : (
          <div className="m-2">This asset contains no alerts</div>
        )}
      </div>
    </div>
  );
};

export default AFAlerts;

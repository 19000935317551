// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { stationRiskForecastsByStationIdAndYear } from "../graphql/queries";

const initialState = {
  shortGefs: {},
  shortGefsAlerts: {
    highlightIndex: -1,
  },
  data: [
    {
      id: "cf6274478af243f6b710eadebbe376ce",
      stationId: "USC00053005",
      year: 2021,
      risk: "normal",
      p10: 31.1,
      p50: 36.6,
      p90: 95.4,
    },
    {
      id: "8ef4b66e45544e3da2140e5fb24f2000",
      stationId: "USC00053005",
      year: 2022,
      risk: "normal",
      p10: 35,
      p50: 70.4,
      p90: 104.5,
    },
    {
      id: "e9f4672c2d09416dac289caab3aba67d",
      stationId: "USC00053005",
      year: 2023,
      risk: "high",
      p10: 35.2,
      p50: 44.2,
      p90: 95.4,
    },
    {
      id: "5cdfe69da2db48cea5299881bddd75cc",
      stationId: "USC00053005",
      year: 2024,
      risk: "normal",
      p10: 15.1,
      p50: 26.2,
      p90: 105.2,
    },
    {
      id: "07e76c3ffee04466b3ab23756c7ecf20",
      stationId: "USC00053005",
      year: 2025,
      risk: "low",
      p10: 12.1,
      p50: 33,
      p90: 79,
    },
  ],
};

//CRAIG MOVE DATA FETCH OVER HERE LATER

const ForecastSlice = createSlice({
  name: "forecast",
  initialState,
  reducers: {
    setForecast: (state, action) => {
      state.tabIndex = action.payload;
    },
    setShortGEFS: (state, action) => {
      state.shortGefs = action.payload;
    },
    setShortGEFSAlerts: (state, action) => {
      const { groupid, assetid, thresholdPassIndex, alertid } = action.payload;
      if (!state.shortGefsAlerts[groupid]) {
        state.shortGefsAlerts[groupid] = {};
      }
      if (!state.shortGefsAlerts[groupid][assetid]) {
        state.shortGefsAlerts[groupid][assetid] = {};
      }

      state.shortGefsAlerts[groupid][assetid][alertid] = thresholdPassIndex;
    },
    setAlertHighlightIndex: (state, action) => {
      state.shortGefsAlerts.highlightIndex = action.payload;
    },
  },
});

export const { setShortGEFS, setShortGEFSAlerts, setAlertHighlightIndex } =
  ForecastSlice.actions; // for non async
export default ForecastSlice.reducer;

// @ts-nocheck
import React, { useState, useRef, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AntSwitch from "../customWidget/AntSwitch";
import { ReactComponent as DotsVertical } from "../../assets/icons/dots-vertical.svg";
import { deleteAlert, toggleAlertActive } from "../../store/alertsSlice";
import { useDispatch } from "react-redux";
import { modalViewsConst, setModalState } from "../../store/modalSlice";

const AlertGroupDetailRecord = ({ alertRecord }) => {
  const dispatch = useDispatch();
  const [settingDialogueOpen, setSettingDialogueOpen] = useState(false);
  const groupSettingsRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        groupSettingsRef.current &&
        !groupSettingsRef.current.contains(event.target)
      ) {
        setSettingDialogueOpen(false); // Clicked outside the div
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [groupSettingsRef]);

  const handleAlertActivate = async () => {
    await dispatch(
      toggleAlertActive({
        id: alertRecord.id,
        active: !alertRecord.active,
        assetGroupId: alertRecord.assetGroupId,
      })
    );
  };

  const handleGroupSettings = (e) => {
    setSettingDialogueOpen(!settingDialogueOpen);
    e.stopPropagation();
  };

  const handleEditAlert = (e) => {
    const preppedAlert = { ...alertRecord };
    // Craig make sure null asset alerts are handled Here instead
    if (!alertRecord.assetId && alertRecord.groupAlert) {
      preppedAlert.assetId = "allAssets";
    }

    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.editNewAlert,
        properties: preppedAlert,
      })
    );
  };

  const handleDelete = (e) => {
    // CRAIG DO THIS LATER

    // setSettingDialogueOpen(false);
    // dispatch(
    //   setModalState({
    //     modalOpen: true,
    //     modalView: modalViewsConst.deleteAssetGroup,
    //     properties: assetGroup,
    //   })
    // );

    dispatch(deleteAlert(alertRecord.id));

    e.stopPropagation();
  };

  return (
    <div className="p-4 border-b-2 ">
      <div className="flex justify-between">
        <div>{alertRecord.name}</div>
        <div>
          <div ref={groupSettingsRef} onClick={handleGroupSettings}>
            <div
              style={{ transform: "translateX(-65px) translateY(-50px)" }}
              className="absolute bg-white border hover:border-gray-200"
            >
              {settingDialogueOpen && (
                <div>
                  <div
                    onClick={handleDelete}
                    className="cursor-pointer p-2 border border-white hover:border-gray-200 "
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>
            <DotsVertical className="hover:border" />
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-16">
        <div className="flex-1">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            Asset
          </div>
          <div className="">
            <div name="name" className=" border p-1 mb-5 text-md rounded-md">
              {alertRecord?.asset?.name ?? "Asset Group Alert"}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            Rainfall (mm)
          </div>
          <div className="">
            <div name="name" className=" border p-1 mb-5 text-md rounded-md">
              {alertRecord.rainfall}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            Rain Period
          </div>
          <div className="">
            <div name="name" className=" border p-1 mb-5 text-md rounded-md">
              {/* new hir adjust for days / weeks */}
              {`${alertRecord.period} hours`}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            Rain Window
          </div>
          <div className="">
            <div name="name" className=" border p-1 mb-5 text-md rounded-md">
              {`${alertRecord.window} hours`}
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex-1 flex mt-5 justify-end">
            {/* Spinning while updating OR disable may be better?*/}
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Off</Typography>
              <AntSwitch
                checked={alertRecord.active}
                onChange={handleAlertActivate}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography>On</Typography>
            </Stack>
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-16">
        <div className="flex-[2_2_0%]">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            Alert Notes
          </div>
          <div className="">
            <div name="name" className=" border p-1 mb-5 text-md rounded-md">
              {alertRecord.description.length > 0
                ? alertRecord.description
                : "None"}
            </div>
          </div>
        </div>
        <div className="flex-[2_2_0%]">
          <div
            name="name"
            className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
          >
            Email Recipients
          </div>
          <div className="">
            <div name="name" className=" border p-1 mb-5 text-md rounded-md">
              {alertRecord.emails.length > 0
                ? alertRecord.emails.map((email) => {
                    return (
                      <span key={email} className="p-1">
                        {email},
                      </span>
                    );
                  })
                : "None"}
            </div>
          </div>
        </div>
        <div className="flex-[1_1_0%] flex justify-end">
          <div>
            <div
              name="name"
              className="text-white text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
            >
              test
            </div>
            <button onClick={handleEditAlert} className="eai-btn">
              Edit Alert
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertGroupDetailRecord;

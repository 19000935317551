// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  signUp,
  confirmSignUp,
  signIn,
  resendSignUpCode,
  confirmResetPassword,
  fetchAuthSession,
  resetPassword,
} from "aws-amplify/auth";
import useGetMe from "../hooks/useGetMe";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  CreateAccount,
  ConfirmSignUp,
  SignIn,
  LoggedInDisplay,
  ResetPassword,
  ResetPasswordEmailView,
} from "./AuthenticationComponents";

import { ReactComponent as ErodeLogoBlack } from "../assets/images/erode-logo-black.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticationState,
  logOutUser,
  setUserDetails,
} from "../store/userSlice";

interface Props {
  signOut: () => void;
}

const formState = {
  loading: "LOADING",
  signup: "SIGNUP",
  confirmSignup: "CONFIRMSIGNUP",
  loggedIn: "LOGGEDIN",
};

const signInFormStateOption = {
  signIn: "SIGNIN",
  resetPasswordEmail: "RESETPASSWORDEMAIL",
  updatePassword: "UPDATEPASSWORD",
};

const Authentication = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [loginFormState, setLoginFormState] = useState(formState.signup);
  const [signInformState, setSignInFormState] = useState(
    signInFormStateOption.signIn
  );
  const [tabIndex, setTabIndex] = useState(1);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const user = useSelector((state) => state.user);

  //error handling
  const [signInError, setSignInError] = useState("");
  const [emailForgotPasswordError, setEmailForgotPasswordError] = useState([]);
  const [resetPasswordErrors, setResetPasswordErrors] = useState("");
  const [createAccountError, setCreateAccountError] = useState("");
  const [confirmSignUpError, setConfirmSignUpError] = useState("");

  useEffect(() => {
    fetchAuthSession()
      .then((authSession) => {
        if (authSession?.tokens?.idToken) {
          const details = {
            groups: authSession?.tokens?.idToken?.payload?.["cognito:groups"],
            email: authSession?.tokens?.idToken?.payload?.email,
            username: authSession?.tokens?.idToken?.payload?.preferred_username,
          };
          dispatch(setUserDetails(details));
          navigate(`/home`);
        } else {
          dispatch(logOutUser());
        }
      })
      .catch((err) => {
        console.log("Err", err);
        dispatch(logOutUser());
      });
  }, []);

  const handleCreateAccount = async (form) => {
    try {
      const res = await signUp({
        username: form.email,
        password: form.password,
        preferred_username: form.username,
        options: {
          userAttributes: {
            preferred_username: form.username,
            email: form.email,
            "custom:accessCode": form.accessCode, //Craig Remove later
          },
        },
      });

      setCredentials({ email: form.email, password: form.password });
      setCreateAccountError("");
      setLoginFormState(formState.confirmSignup);
    } catch (err) {
      const rawErrorMessage = err.toString();
      const errorMessage = rawErrorMessage.replace(`${err.code}:`, "");
      setCreateAccountError(errorMessage);
    }
  };

  const handleConfirmSignUp = async (verificationCode) => {
    try {
      //Add State here to loading
      const res = await confirmSignUp({
        username: credentials.email,
        confirmationCode: verificationCode,
      });
      const signInRes = await signIn({
        username: credentials.email,
        password: credentials.password,
      });
      navigate("/home");
      //Craig Add here
    } catch (err) {
      const rawErrorMessage = err.toString();
      let errorMessage = rawErrorMessage.replace(`AuthError:`, "");
      errorMessage = errorMessage.replace(`${err.code}:`, "");
      console.log(rawErrorMessage);
      setConfirmSignUpError(errorMessage);
    }
  };

  const handleResendCode = async (e) => {
    try {
      await resendSignUpCode(credentials.email);
    } catch (e) {
      console.log(", resend error", e);
    }
  };

  const handleSignIn = async (passedCredentials) => {
    try {
      const signInResponse = await signIn({
        username: passedCredentials.email,
        password: passedCredentials.password,
      });
      if (!signInResponse.isSignedIn) {
        if (signInResponse?.nextStep?.signInStep === "CONFIRM_SIGN_UP") {
          throw new Error("Confirm Signup");
        }
      }

      const authSession = await fetchAuthSession();
      const details = {
        groups: authSession?.tokens?.idToken?.payload?.["cognito:groups"],
        email: authSession?.tokens?.idToken?.payload?.email,
        username: authSession?.tokens?.idToken?.payload?.preferred_username,
      };

      dispatch(setUserDetails(details));
      if (details.username) {
        navigate("/home");
      }

      // refetch(); //Craig
    } catch (err) {
      const rawErrorMessage = err.toString();
      let errorMessage;
      if (err.name === "InvalidParameterException") {
        errorMessage = "Password value is empty.";
      } else {
        // Craig, maybe log error later?
        errorMessage = rawErrorMessage.replace(`${err.name}:`, "");
      }
      setSignInError(errorMessage);
    }
  };

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  const handleResetPasswordEmailSubmit = async (email) => {
    let errors = [];
    try {
      const result = await resetPassword({
        username: email,
      });
    } catch (error) {
      errors.push(error);
    }

    if (errors.length > 0) {
      setEmailForgotPasswordError(errors);
    } else {
      setResetPasswordEmail(email);
      setSignInFormState(signInFormStateOption.updatePassword);
      setEmailForgotPasswordError([]);
    }
  };

  const handleResetPassword = async (resetPasswordForm) => {
    let errors = [];
    if (
      resetPasswordForm.newPassword !== resetPasswordForm.confirmNewPassword
    ) {
      errors.push("new password and confirmed password do not match");
    }

    if (errors.length === 0) {
      try {
        const res = await confirmResetPassword({
          username: resetPasswordEmail,
          newPassword: resetPasswordForm.newPassword,
          confirmationCode: resetPasswordForm.resetCode,
        });
      } catch (error) {
        errors.push(error);
      }
    }
    // craig / newHire Add loading animation

    if (errors.length > 0) {
      setResetPasswordErrors(errors);
    } else {
      try {
        const { isSignedIn, nextStep } = await signIn({
          username: resetPasswordEmail,
          password: resetPasswordForm.newPassword,
        });
        navigate("/home");
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  //if we haven't checked if the user is authorized, hold on loading
  if (user.status !== authenticationState.unAuthenticated) {
    return false;
  }

  return (
    <div className="grow flex bg-eai-bg-lt2 items-center bg-pattern-01 bg-center">
      <div className="h-[100vh] flex flex-col grow items-center justify-center lg:flex-row">
        <div className="m-4 w-3/4 2xl:w-1/4 lg:w-4/12 md:w-3/5 p-5">
          <ErodeLogoBlack className="w-[200px] h-20 mb-3" />
          <div className="text-4xl font-gray-900 font-Inter mb-5">
            Better forecasts for a climate resilient world.
          </div>
          <div className="text-lg font-gray-900 font-bold">
            Please sign in or sign up to continue.
          </div>
        </div>
        <div className="rounded-lg border border-gray-400 bg-white m-4 2xl:w-1/4 lg:w-4/12 md:w-3/5 sm:w-3/4 p-5">
          <Tabs selectedIndex={tabIndex} onSelect={handleTabChange}>
            <TabList className="flex grow border rounded-t border-gray-400">
              <Tab
                className={` transition rounded-tl flex-1 p-2 flex justify-around ${
                  tabIndex === 0 && "bg-sky-700 text-white"
                }`}
              >
                Sign Up
              </Tab>
              <Tab
                className={`transition rounded-tr flex-1 p-2 flex justify-around ${
                  tabIndex === 1 && "bg-sky-700 text-white"
                }`}
              >
                Sign In
              </Tab>
            </TabList>
            <TabPanel className="">
              {loginFormState === formState.signup && (
                <CreateAccount
                  onFormSubmit={handleCreateAccount}
                  serverSideErrors={createAccountError}
                />
              )}
              {loginFormState === formState.confirmSignup && (
                <ConfirmSignUp
                  onSubmit={handleConfirmSignUp}
                  userEmail={credentials.email}
                  serverSideErrors={confirmSignUpError}
                  onResendCode={handleResendCode}
                />
              )}
            </TabPanel>
            <TabPanel className="">
              {signInformState === signInFormStateOption.signIn && (
                <SignIn
                  onForgotPassword={() =>
                    setSignInFormState(signInFormStateOption.resetPasswordEmail)
                  }
                  onSubmit={handleSignIn}
                  serverSideErrors={signInError}
                />
              )}
              {signInformState === signInFormStateOption.resetPasswordEmail && (
                <ResetPasswordEmailView
                  errorMessages={emailForgotPasswordError}
                  onSubmit={handleResetPasswordEmailSubmit}
                  onCancel={() =>
                    setSignInFormState(signInFormStateOption.signIn)
                  }
                />
              )}
              {signInformState === signInFormStateOption.updatePassword && (
                <ResetPassword
                  errorMessages={resetPasswordErrors}
                  resetCodeEmail={resetPasswordEmail}
                  onCancel={() =>
                    setSignInFormState(signInFormStateOption.signIn)
                  }
                  onSubmit={handleResetPassword}
                />
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Authentication;

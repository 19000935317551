// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import StationDetails from "./StationDetails";
import {
  fetchYearlyStationMaxRain,
  setSelectStation,
} from "../../../store/selectedStationSlice";
import GraphYearlyMax from "../../GraphYearlyMax";
import { idfFileNames, promiseAll } from "../../../util/utils";
import { fetchGraphData } from "../../../api/awsLamba";
import GraphCollection from "../../GraphCollection";

const SelectedStationTab = () => {
  const [climateData, setClimateData] = useState(null);
  const [graphDataCollection, setGraphDataCollection] = useState(null);
  const selectedStation = useSelector((state) => state.selectedStation);
  const dispatch = useDispatch();

  //CRAIG keep for now, but change to THUNK Later
  useEffect(() => {
    if (selectedStation.details?.stationID) {
      dispatch(fetchYearlyStationMaxRain(selectedStation.details?.stationID));
    }
  }, [selectedStation.details?.stationID]);

  //CRAIG REMOVE LATER
  useEffect(() => {
    if (
      selectedStation?.details?.ClimateID &&
      selectedStation.details?.climateStation
    ) {
      promiseAll(selectedStation.details.ClimateID).then((res) => {
        const stationClimateDetails = Object.keys(idfFileNames).reduce(
          (acc, curr, index) => {
            return { ...acc, [curr]: res[index] };
          },
          {}
        );
        setClimateData(stationClimateDetails);
      });
    } else {
      setClimateData(null);
      setGraphDataCollection(null);
    }
  }, [selectedStation.details?.stationID]);

  //CRAIG REMOVE THIS LATER
  useEffect(() => {
    if (climateData) {
      fetchGraphData(climateData.AnnualMax.data).then((res) => {
        setGraphDataCollection(res.data);
      });
    }
  }, [climateData]);

  return (
    <div className="overflow-y-scroll max-h-[70vh]">
      {selectedStation?.details && (
        <button
          className="eai-btn"
          onClick={() => {
            dispatch(setSelectStation(null));
            setGraphDataCollection(null);
            setClimateData(null);
          }}
        >
          Clear Selection
        </button>
      )}
      <StationDetails stationDetails={selectedStation?.details} />
      <GraphYearlyMax />
      {graphDataCollection ? (
        <GraphCollection graphDataCollection={graphDataCollection} />
      ) : null}
    </div>
  );
};

export default SelectedStationTab;

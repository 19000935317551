// @ts-nocheck
import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { listLoginAccessCodes } from "../../graphql/queries";
import {
  createLoginAccessCodes,
  deleteLoginAccessCodes,
} from "../../graphql/mutations";

const initialState = {
  accessCode: "",
  recipient: "",
  notes: "",
};

const client = generateClient();

const LoginAccessCodes = () => {
  const [accessCodeForm, setAccessCodeForm] = useState(initialState);
  const [error, setError] = useState("");
  const [listAccessCodes, setListAccessCodes] = useState([]);

  const fetchAccessCodes = async () => {
    try {
      const { data } = await client.graphql({
        query: listLoginAccessCodes,
      });
      setListAccessCodes(data.listLoginAccessCodes.items);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAccessCodes();
  }, []);

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleGenerateCode = () => {
    const randomString = generateRandomString(12);
    setAccessCodeForm({
      ...accessCodeForm,
      accessCode: randomString,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (accessCodeForm.accessCode.length < 11) {
      setError("access codes should be at least 12 characters");
      return;
    }
    if (accessCodeForm.recipient.length < 3) {
      setError("Recipient Required Pal");
      return;
    }
    setError("");

    const form2Submit = {
      ...accessCodeForm,
      code: accessCodeForm.accessCode,
      available: true,
      activatedBy: "",
    };
    delete form2Submit.accessCode;

    const { data } = await client.graphql({
      query: createLoginAccessCodes,
      variables: {
        input: form2Submit,
      },
    });

    setListAccessCodes([data.createLoginAccessCodes, ...listAccessCodes]);
    setAccessCodeForm(initialState);
  };

  const handleDelete = async (code) => {
    const { data } = await client.graphql({
      query: deleteLoginAccessCodes,
      variables: {
        input: { code },
      },
    });

    const deleteItem = data.deleteLoginAccessCodes;

    const filteredAccessCodes = listAccessCodes.filter(
      (cody) => cody.code !== deleteItem.code
    );
    setListAccessCodes(filteredAccessCodes);
  };

  return (
    <div>
      <div className="Form p-2 border">
        <div className="text-xl">New Access Code Form</div>
        {error.length > 0 && <div className="text-red-500">{error}</div>}
        <form onSubmit={handleSubmit}>
          <table>
            <tbody>
              <tr>
                <td>Access Code</td>
                <td>
                  <input
                    className="border"
                    value={accessCodeForm.accessCode}
                    onChange={(e) =>
                      setAccessCodeForm({
                        ...accessCodeForm,
                        accessCode: e.target.value,
                      })
                    }
                  />
                </td>
                <td>
                  <button
                    type="button"
                    onClick={handleGenerateCode}
                    className="eai-btn"
                  >
                    generate
                  </button>
                </td>
              </tr>
              <tr>
                <td>Recipient</td>
                <td>
                  <input
                    className="border"
                    value={accessCodeForm.recipient}
                    onChange={(e) =>
                      setAccessCodeForm({
                        ...accessCodeForm,
                        recipient: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>Notes</td>
                <td>
                  <input
                    className="border"
                    value={accessCodeForm.notes}
                    onChange={(e) =>
                      setAccessCodeForm({
                        ...accessCodeForm,
                        notes: e.target.value,
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <button className="eai-btn" type="submit">
            Submit
          </button>
        </form>
      </div>
      <div>
        <table>
          <tbody>
            <tr>
              <th>Delete</th>
              <th className="border">Access Code</th>
              <th className="border">recipient</th>
              <th className="border">activatedBy</th>
              <th className="border">available</th>
              <th className="border">notes</th>
            </tr>
            {listAccessCodes.map((accesscode) => (
              <tr className="border" key={accesscode.code}>
                <td>
                  <button
                    disabled={!accesscode.available}
                    onClick={() => handleDelete(accesscode.code)}
                    className={`p-1 border ${
                      accesscode.available
                        ? "bg-red-300"
                        : "bg-gray-400 cursor-not-allowed"
                    } `}
                  >
                    Del.
                  </button>
                </td>
                <td className="border">{accesscode.code}</td>
                <td className="border">{accesscode.recipient}</td>
                <td className="border">{accesscode.activatedBy}</td>
                <td className="border">{`${accesscode.available}`}</td>
                <td className="border">{accesscode.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LoginAccessCodes;

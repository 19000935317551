// @ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { capitalizeWords } from "../../util/utils";
import { postAlert, editAlert, deleteAlert } from "../../store/alertsSlice";
import { daysOfWeek } from "../../util/constants";
import { setModalOpen } from "../../store/modalSlice";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const AssetAlertForm = ({ initialAlert, editFlag = false }) => {
  const dispatch = useDispatch();
  const assets = useSelector((state) => state.assets);
  const [newEmail, setNewEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [tempAlertState, setTempAlertState] = useState({
    threshold: 0.1,
    probability: 80,
    saturdayFlag: false,
    sundayFlag: false,
    mondayFlag: false,
    tuesdayFlag: false,
    wednesdayFlag: false,
    thursdayFlag: false,
    fridayFlag: false,
    active: true,
    emails: [],
    phoneNumbers: [],
    ...initialAlert,
  });

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (newEmail.length > 3) {
      const updatedSet = new Set([...tempAlertState.emails, newEmail]);
      setTempAlertState({ ...tempAlertState, emails: Array.from(updatedSet) });
      setNewEmail("");
    }
  };

  const handleEmailDelete = (deleteEmail) => {
    const filteredEmails = tempAlertState.emails.filter(
      (email) => email !== deleteEmail
    );
    setTempAlertState({ ...tempAlertState, emails: filteredEmails });
  };

  const handleFormSubmit = (e) => {
    //beginner error handling
    let tentErrors = [];
    if (
      tempAlertState.emails.length < 1 &&
      tempAlertState.phoneNumbers.length < 1
    ) {
      tentErrors.push("Alerts must include email or text recipients");
    }
    if (!daysOfWeek.some((day) => tempAlertState[`${day}Flag`])) {
      tentErrors.push("Alerts must include at least one alert day");
    }

    setErrors(tentErrors);
    if (tentErrors.length > 0) {
      return;
    }

    //create occurenceKey, possibly for indexing?
    const occurenceKey = daysOfWeek.reduce((acc, curr) => {
      if (tempAlertState[`${curr}Flag`]) {
        return acc + curr;
      }
      return acc;
    }, "");

    if (editFlag) {
      dispatch(
        editAlert({
          ...tempAlertState,
          assetId: assets.selectedAsset.id,
          occurenceKey,
        })
      );
    } else {
      dispatch(
        postAlert({
          ...tempAlertState,
          assetId: assets.selectedAsset.id,
          occurenceKey,
        })
      );
    }
    dispatch(setModalOpen(false));
  };

  const handleDeleteAlert = () => {
    dispatch(deleteAlert(initialAlert.id));
    dispatch(setModalOpen(false));
  };

  return (
    <div>
      {" "}
      <table>
        <tbody>
          <tr className="m-2">
            <td className="p-2 text-xl" colSpan={2}>
              {editFlag && "Edit "}
              {assets.selectedAsset.name} Alert
            </td>
          </tr>
          <tr className="m-2">
            <td className="p-2"> Threshold </td>
            <td className="p-2">
              <input
                onChange={(e) =>
                  setTempAlertState({
                    ...tempAlertState,
                    threshold: e.target.value,
                  })
                }
                className="border p-1"
                type="number"
                name="threshold"
                step="0.01"
                value={tempAlertState.threshold}
              />
            </td>
          </tr>
          <tr className="m-2">
            <td className="p-2"> Probability </td>
            <td className="p-2">
              <input
                onChange={(e) =>
                  setTempAlertState({
                    ...tempAlertState,
                    probability: e.target.value,
                  })
                }
                className="border p-1"
                type="number"
                name="probability"
                step="0.01"
                value={tempAlertState.probability}
              />
            </td>
          </tr>
          <tr className="m-2">
            <td className="p-2"> Frequency </td>
            <td className="p-2">
              <div className="flex">
                {/* beginner ticket */}
                {/* <div className="pr-2">
            <input type="checkbox" />{" "}WeekDays<br />
            <input type="checkbox" />{" "}Weekends<br />
          </div> */}
                <div>
                  {daysOfWeek.map((day) => {
                    return (
                      <div key={day}>
                        <input
                          onChange={() =>
                            setTempAlertState({
                              ...tempAlertState,
                              [`${day}Flag`]: !tempAlertState[`${day}Flag`],
                            })
                          }
                          checked={tempAlertState[`${day}Flag`]}
                          type="checkbox"
                        />{" "}
                        {capitalizeWords(day)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </td>
          </tr>
          <tr className="m-2">
            <td className="p-2"> Email Address </td>
            <td className="p-2">
              <form onSubmit={handleEmailSubmit}>
                <input
                  onChange={(e) => setNewEmail(e.target.value)}
                  className="border p-1"
                  type="email"
                  name="threshold"
                  value={newEmail}
                />
              </form>
              {/* beginner add default user email address option */}
            </td>
          </tr>
          {tempAlertState.emails.length > 0 && (
            <tr className="m-2">
              <td className="p-2"> Email List </td>
              <td className="p-2">
                {" "}
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 0,
                  }}
                  component="ul"
                >
                  {tempAlertState.emails.map((email) => {
                    return (
                      <ListItem key={email}>
                        <Chip
                          label={email}
                          onDelete={() => handleEmailDelete(email)}
                        />
                      </ListItem>
                    );
                  })}
                </Paper>{" "}
              </td>
            </tr>
          )}
          <tr className="m-2">
            <td className="p-2"> Text Alert </td>
            <td className="p-2">Coming Soon</td>
          </tr>
          {errors.length > 0 && (
            <tr>
              <td className="p-2 text-red-500" colSpan={2}>
                {errors.map((error) => {
                  return <div key={error}>{error}</div>;
                })}
              </td>
            </tr>
          )}
          <tr>
            <td className="p-2">
              <div className="flex justify-between">
                <button className="eai-btn" onClick={handleFormSubmit}>
                  {editFlag ? "Update" : "Submit"}
                </button>
              </div>
            </td>
            <td className="text-right">
              {!confirmDelete ? (
                <button
                  className="eai-btn"
                  onClick={() => setConfirmDelete(true)}
                >
                  Delete
                </button>
              ) : (
                <>
                  <button className="eai-btn" onClick={handleDeleteAlert}>
                    Confirm Delete
                  </button>
                  <button
                    className="eai-btn"
                    onClick={() => setConfirmDelete(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AssetAlertForm;

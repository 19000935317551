// @ts-nocheck
import React, { useState, useEffect } from "react";
import { AssetBreadCrumb } from "../components/AssetBreadCrumb";
import { AssetGroupFolder, NoAssetGroup } from "../components/AssetGroupFolder";
import { ReactComponent as CopyIcon } from "../assets/icons/copy-icon.svg";
import { ReactComponent as GroupIcon } from "../assets/icons/group-icon.svg";
import { AssetCount } from "../components/TagStyles";
import { AssetTable } from "../components/AssetTable";
import { fetchAssets } from "../../../store/assetsSlice";
import { useDispatch, useSelector } from "react-redux";
import { setModalState, modalViewsConst } from "../store/modalSlice";
import { fetchAssetsByGroupId } from "../store/assetsSlice";
import { useParams } from "react-router-dom";
import LoadingIcon from "../components/LoadingIcon";
import { fetchAuthSession } from "aws-amplify/auth";

const GroupPage = (props) => {
  let { groupid } = useParams();
  const dispatch = useDispatch();
  const { assets, assetGroups, user } = useSelector(
    ({ assets, assetGroups, user }) => ({
      assets,
      assetGroups,
      user,
    })
  );

  // Craig Put DYNAMIC DATA Here
  useEffect(() => {
    // error handling here
    if (!assets.dataGroups[groupid]) {
      dispatch(fetchAssetsByGroupId(groupid));
    }
  }, []);

  const handleAddAsset = async () => {
    const authSession = await fetchAuthSession();
    const groups =
      authSession?.tokens?.idToken?.payload?.["cognito:groups"] ?? [];
    const assetCountCap = user.permissionDetails?.assetCount ?? 3; //3 is min
    let assetCount = 0;
    Object.values(assets.dataGroups).forEach(
      (groupAssetList) => (assetCount += groupAssetList.length)
    );

    if (groups?.includes("Admin") || assetCount < assetCountCap) {
      dispatch(
        setModalState({
          modalOpen: true,
          modalView: modalViewsConst.addAssetNEW,
          properties: { assetGroupId: groupid },
        })
      );
    } else {
      dispatch(
        setModalState({
          modalOpen: true,
          modalView: modalViewsConst.notification,
          properties: {
            header: "Quick Note",
            details: `Assets are currently being capped at ${assetCountCap} per user. Please reach out to support@erode.ai for any inquiries`,
          },
        })
      );
    }

    // setDialogueOpen(false);
  };
  // const handleAddAsset = () => {
  //   let assetCount = 0;
  //   Object.values(assets.dataGroups).forEach(
  //     (groupAssetList) => (assetCount += groupAssetList.length)
  //   );
  //   if (assetCount > 9) {
  //     dispatch(
  //       setModalState({
  //         modalOpen: true,
  //         modalView: modalViewsConst.notification,
  //         properties: {
  //           header: "Quick Note",
  //           details:
  //             "Assets are currently being capped at 10 per user. Please reach out to support@erode.ai for any inquiries",
  //         },
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       setModalState({
  //         modalOpen: true,
  //         modalView: modalViewsConst.addAssetNEW,
  //         properties: { assetGroupId: groupid },
  //       })
  //     );
  //   }
  // };

  const selectedGroup = assetGroups.groups[groupid];
  if (
    !assets.dataGroups[groupid] ||
    // assets.dataGroups[groupid].length === 0 ||
    !assetGroups.groups[groupid]
  ) {
    return (
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div
          className={`transition-opacity delay-700 duration-500 ease-in-out `}
        >
          <LoadingIcon />
        </div>
      </div>
    );
  }

  // #CodyFix. What should appear if there are no assets? suggest doing something similar to when there are no assets

  return (
    <>
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow">
          <AssetBreadCrumb assetGroupName={selectedGroup?.name} />
          <div className="flex flex-row h-24 border-y border-gray-200 p-5 justify-left items-center">
            <div className="flex grow items-center">
              <div className="w-12 h-12 border border-gray-200 rounded-lg flex items-center shrink-0 justify-center mr-3">
                <GroupIcon />
              </div>
              <div className="flex-col items-center justify-center">
                <div className="text-gray-900 text-xl font-semibold font-Inter mb-1">
                  {selectedGroup.name}
                </div>
                <AssetCount
                  count={assets?.dataGroups?.[selectedGroup.id]?.length ?? 0}
                />
              </div>
            </div>
            <button
              onClick={handleAddAsset}
              className="flex-wrap eai-btn-grn my-1"
              type="submit"
            >
              <div className="mx-3">
                <span className="hidden md:block">Add Asset +</span>
                <span className="block md:hidden">+</span>
              </div>
            </button>
          </div>
          <div className="flex grow p-5 h-[calc(100vh_-_9rem)] overflow-y-auto">
            <AssetTable assetItems={assets.dataGroups[groupid]} />
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupPage;

// @ts-nocheck
import React from "react";
import withAdminAuth from "../components/HOC/withAdminAuth";
import LoginAccessCodesCreateForm from "../ui-components/LoginAccessCodesCreateForm";
import LoginAccessCodes from "../components/Admin/LoginAccessCodes";
import RestTest from "../components/Admin/RestTest";

const Admin = () => {
  return (
    <>
      <div className="flex flex-col grow bg-white max-h-[100vh]">
        <div className="flex flex-col grow p-5">
          <div className="text-xl">Admin Page</div>
          <div>
            <LoginAccessCodes />
            <RestTest />
          </div>
        </div>
      </div>
    </>
  );
};

export default withAdminAuth(Admin);

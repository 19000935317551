// @ts-nocheck
import React, { forwardRef } from "react";
import { Source, Layer } from "react-map-gl";
import { useSelector } from "react-redux";

const PointExploration = forwardRef((props, mapRef) => {
  const mapState = useSelector((state) => state.mapState);

  const circleProperties = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: mapState?.pointExploreLngLat, // Example coordinates (longitude, latitude)
    },
    properties: {
      // You can put additional data here; for example, a name or identifier for your point
    },
  };

  if (!mapState.pointExploreLngLat) {
    return false;
  }

  return (
    <Source
      id={"PointExplorationSource"}
      type="geojson"
      data={{ ...circleProperties }}
      cluster={false}
    >
      <Layer
        visible={true}
        visibility={true ? "visible" : "none"}
        {...{
          id: "pointExplore",
          type: "circle",
          source: "PointExplorationSource",
          paint: {
            "circle-radius": 7,
            "circle-color": "#007cbf",
          },
        }}
      />
    </Source>
  );
});

export default PointExploration;

// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
  rainGridLayerVisible: false,
  assetLayerVisible: true,
  weatherStationLayerVisible: false,
  customMarkerActive: false,
  flyLocation: null,
  pointExploreLayerVisible: false,
  pointExploreLngLat: null, //[0,0]
};

const MapStateSlice = createSlice({
  name: "mapState",
  initialState,
  reducers: {
    setRainVisible: (state, action) => {
      state.rainGridLayerVisible = action.payload;
    },
    setAssetVisible: (state, action) => {
      state.assetLayerVisible = action.payload;
    },
    setWeatherStationVisible: (state, action) => {
      state.weatherStationLayerVisible = action.payload;
    },
    setCustomMarker: (state, action) => {
      state.customMarkerActive = action.payload;
    },
    setFlyLocation: (state, action) => {
      state.flyLocation = action.payload;
    },
    setPointExploreVisible: (state, action) => {
      state.pointExploreLayerVisible = action.payload.active;
      state.pointExploreLngLat = action.payload.lnglat;
    },
  },
});

export const {
  setRainVisible,
  setAssetVisible,
  setWeatherStationVisible,
  setCustomMarker,
  setFlyLocation,
  setPointExploreVisible,
} = MapStateSlice.actions; // for non async
export default MapStateSlice.reducer;

// @ts-nocheck
import React, { useState, useEffect } from "react";
import { ReactComponent as GroupIcon } from "../../assets/icons/group-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as PowerIcon } from "../../assets/icons/lightning.svg";
import { useDispatch, useSelector } from "react-redux";
import { setModalOpen } from "../../store/modalSlice";
import { z } from "zod";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PCurveGraph from "../PerformanceCurve/PCurveGraph";
import { editPCurve, postPCurve } from "../../store/pCurveSlice";

const PCurveSchema = z.object({
  name: z.string().trim().min(2).max(50),
  description: z.string().trim(),
  xUnit: z.enum(["m/s"], {
    errorMap: (issue, ctx) => ({ message: "Invalid Unit" }),
  }),
  yUnit: z.enum(["MW"], {
    errorMap: (issue, ctx) => ({ message: "Invalid Unit" }),
  }),
  curveData: z
    .array(z.object({ x: z.number(), y: z.number() }))
    .min(2, { message: "At least two Curve Data points must be provided." }), // Enforce a minimum of 2 items in the array,
});
type PCurveType = z.infer<typeof PCurveSchema>;

const PerformanceCurveForm = ({ editFlag = false, initialPCurve }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
    getValues,
    watch,
  } = useForm<PCurveType>({
    defaultValues: {
      name: initialPCurve?.name || "",
      description: initialPCurve?.description || "",
      xUnit: initialPCurve?.xUnit || "m/s",
      yUnit: initialPCurve?.yUnit || "MW",
      curveData: initialPCurve?.curveData || [{ x: 0, y: 0 }],
    },
    resolver: zodResolver(PCurveSchema),
    mode: "onChange",
  });
  const [curveViewerOpen, setCurveViewerOpen] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "curveData",
  });
  const curveDataWatch = watch("curveData"); //needed to updated curve Data

  const handleCancel = () => {
    dispatch(setModalOpen(false));
  };

  const submitPCurve = async (data) => {
    if (editFlag) {
      await dispatch(editPCurve({ id: initialPCurve.id, ...data }));
    } else {
      await dispatch(postPCurve(data));
    }
    dispatch(setModalOpen(false));
  };

  const setMainForm = async () => {
    setCurveViewerOpen(false);
  };

  if (curveViewerOpen) {
    return (
      <div className="">
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className=" flex flow-row grow items-center border-b border-gray-200 p-5"
        >
          <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
            <PowerIcon stroke="#A2BBBF" className="h-6 w-6" />
          </div>
          <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
            {editFlag
              ? `Edit ${initialPCurve.name} Curve Data`
              : "New Curve Data"}
          </div>
          <div
            onClick={() => dispatch(setModalOpen(false))}
            className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
          >
            <XClose className="h-6 w-6" />
          </div>
        </div>
        <div className="px-4 py-2 flex">
          <div className="border flex-1">
            <div>
              <div className="flex ">
                <div className="flex-1 text-center">{getValues("xUnit")}</div>
                <div className="flex-1 text-center">{getValues("yUnit")}</div>
                <div className="flex-1 text-center">{}</div>
              </div>
              {fields.map((item, index) => (
                <div key={item.id} className="flex ">
                  <div className="flex-1 bg-slate-200">
                    <Controller
                      name={`curveData.${index}.x`}
                      render={({ field }) => {
                        return (
                          <input
                            min="0"
                            type="number"
                            className="border p-2 w-full"
                            {...field}
                            onChange={(e) =>
                              field.onChange(parseFloat(e.target.value) || 0)
                            }
                          />
                        ); // Changed to w-full and adjusted padding/margin if needed
                      }}
                      control={control}
                    />
                  </div>
                  <div className="flex-1 bg-slate-500">
                    <Controller
                      name={`curveData.${index}.y`}
                      render={({ field }) => (
                        <input
                          min="0"
                          type="number"
                          className="border p-2 w-full"
                          {...field}
                          onChange={(e) => {
                            if (isNaN(parseFloat(e.target.value))) {
                              return field.onChange(parseFloat(0));
                            } else {
                              return field.onChange(parseFloat(e.target.value));
                            }
                          }}
                        />
                      )}
                      control={control}
                    />
                  </div>
                  <div className="flex-1 flex justify-center">
                    <button
                      className="eai-btn-red "
                      type="button"
                      onClick={() => remove(index)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
              <div>
                <button
                  className="border p-1 m-1 hover:bg-slate-300"
                  onClick={() =>
                    append({
                      x: curveDataWatch[curveDataWatch.length - 1].x,
                      y: curveDataWatch[curveDataWatch.length - 1].y,
                    })
                  }
                >
                  ➕ Add
                </button>
              </div>
            </div>
          </div>
          <div className=" flex-1">
            <PCurveGraph
              data={curveDataWatch}
              xAxisLabel={getValues("xUnit")}
              yAxisLabel={getValues("yUnit")}
            />
          </div>
        </div>
        <div className="flex flex-row grow space-x-3 p-5">
          <button className="flex-grow eai-btn my-1" type="submit">
            <div
              className="mx-3"
              onClick={() => setMainForm()} //Later save curve and if cancel, don't add other features
            >
              Cancel
            </div>
          </button>
          <button
            className="flex-grow eai-btn-grn my-1"
            onClick={() => setMainForm()}
          >
            Accept Curve
          </button>
        </div>
      </div>
    );
  }

  return (
    <form id="my-form" onSubmit={handleSubmit(submitPCurve)} className="">
      <div
        onClick={() => dispatch(setModalOpen(false))}
        className=" flex flow-row grow items-center border-b border-gray-200 p-5"
      >
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <PowerIcon stroke="#A2BBBF" className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          {editFlag
            ? `Edit Energy Model: ${initialPCurve.name}`
            : "New Wind Energy Model"}
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>

      <div className="h-1 flex grow">
        {/* <div className="h-1 w-full bg-primary-500"></div> */}
      </div>
      <div className="flex flex-col grow p-5">
        <div className="flex gap-8 justify-between">
          <div className="flex-1">
            <div className="flex justify-between">
              <div
                name="name"
                className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]"
              >
                Curve Name
              </div>
              {errors.name ? (
                <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  {errors.name.message}
                </span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="flex">
              <input
                name="name"
                {...register("name")}
                className="flex grow border p-3 mb-5 text-md rounded-md"
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between">
              <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                Type
              </div>
              {errors?.type ? (
                <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  {errors.type.message}
                </span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="flex grow">
              <div
                disabled
                className="flex bg-slate-400 grow border p-3 mb-5 text-md rounded-md"
              >
                Wind
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-8 justify-between">
          <div className="flex-1">
            <div className="flex justify-between">
              <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                X axis Unit
              </div>
              {errors.xUnit ? (
                <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  {errors.xUnit.message}
                </span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="flex grow">
              <select
                name="xaxisunit"
                {...register("xUnit")}
                className="flex grow border p-3 mb-5 text-md rounded-md"
              >
                <option value="">--</option>
                <option value="m/s">m/s</option>
                {/* <option value="precip">Precipitation</option>
                <option value="precip">Wind</option> */}
              </select>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex justify-between">
              <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                Y axis Unit
              </div>
              {errors.yUnit ? (
                <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
                  {errors.yUnit.message}
                </span>
              ) : (
                <span></span>
              )}
            </div>
            <div className="flex grow">
              <select
                name="yaxisunit"
                {...register("yUnit")}
                className="flex grow border p-3 mb-5 text-md rounded-md"
              >
                <option value="">--</option>
                <option value="MW">MW</option>

                {/* <option value="precip">Precipitation</option>
                <option value="precip">Wind</option> */}
              </select>
            </div>
          </div>
        </div>
        <div className="text-gray-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
          Curve Description
        </div>
        <div className="flex grow">
          <textarea
            name="description"
            {...register("description")}
            className="flex grow border p-3 mb-2 text-md rounded-md"
          />
        </div>
        <div className="flex justify-between">
          <button
            onClick={() => setCurveViewerOpen(true)}
            className="eai-btn-grn"
          >
            Curve Data
          </button>

          {errors.curveData ? (
            <span className="text-red-500 text-sm mb-2 font-normal font-['Inter'] leading-[18px]">
              {errors.curveData?.message}
            </span>
          ) : (
            <span></span>
          )}
        </div>
      </div>
      <div className="flex flex-row grow space-x-3 p-5">
        <button className="flex-grow eai-btn my-1" type="submit">
          <div className="mx-3" onClick={handleCancel}>
            Cancel
          </div>
        </button>
        <button
          className="flex-grow eai-btn-grn my-1"
          type="submit"
          form="my-form"
        >
          <div className="mx-3">{editFlag ? "Update" : "Submit"}</div>
        </button>
      </div>
    </form>
  );
};

export default PerformanceCurveForm;

// @ts-nocheck
import React, { useEffect, useState, forwardRef } from "react";
import * as turf from "@turf/turf";
import { useSelector, useDispatch } from "react-redux";
import { Source, Layer } from "react-map-gl";
import { convertToGeoJSON } from "../../util/utils";
import {
  setHoveredAsset,
  setSelectedAsset,
  setProximityStations,
} from "../../store/assetsSlice";
import { setTabIndex } from "../../store/tabSlice";
import { useParams } from "react-router-dom";

export const AssetLayerId = "ASSET_LAYER";

const getColorExpression = (hoverStationId = "0", selectedStationId = "0") => {
  return [
    "case",
    ["==", ["get", "id"], selectedStationId],
    "yellow",
    ["==", ["get", "id"], hoverStationId],
    "purple",
    "rgba(0, 0, 0, 0.0)", // Default color
  ];
};

const AssetLayer = forwardRef((props, mapRef) => {
  let { groupid, assetid } = useParams();
  const dispatch = useDispatch();
  const assets = useSelector(({ assets }) => assets);
  const assetList = assets.dataGroups[groupid];
  const assetLayerVisible = useSelector(
    (state) => state.mapState.assetLayerVisible
  );
  const weatherStation = useSelector((state) => state.weatherStation);
  const [assetGeoJSON, setAssetGeoJSON] = useState(null);

  useEffect(() => {
    mapRef.current.on("mouseenter", "user-assets", handleMouseEnter);
    mapRef.current.on("mouseleave", "user-assets", handleMouseExit);
    mapRef.current.on("click", "user-assets", handleFeatureClick);
    return () => {
      //   mapRef.current.off("mouseenter", "user-assets");
      //   mapRef.current.off("mouseleave", "user-assets");
      //   mapRef.current.off("click", "user-assets");
    };
  }, []);

  useEffect(() => {
    if (assets.selectedAsset && assets.selectedAssetProximity >= 0) {
      const assetPoint = [assets.selectedAsset.lng, assets.selectedAsset.lat];
      const radius = assets.selectedAssetProximity;
      const circleOptions = {
        steps: 50,
        units: "kilometers",
        properties: { foo: "bar" },
      };
      const proximityCircle = turf.circle(assetPoint, radius, circleOptions);

      let stationFilters = [];
      if (weatherStation.yearFilterOn) {
        stationFilters.push([
          "in",
          weatherStation.yearFilter,
          ["get", "dataYears"],
        ]);
      }

      const sourceFeatures = mapRef.current.querySourceFeatures(
        "hiddenWeatherStations",
        {
          sourceLayer: "unclustered-point-invisible",
          filter: ["all", ["within", proximityCircle], ...stationFilters],
        }
      );

      const proximityStationProperties = sourceFeatures.map((feature) => {
        const {
          ClimateID,
          Elev_m,
          climateStation,
          stationID,
          src,
          name,
          minYear,
          maxYear,
          dataYears,
        } = feature.properties;

        const stationCoord = feature.geometry.coordinates;
        const stationPoint = turf.point(stationCoord);
        const assetStationDistance = turf.distance(assetPoint, stationPoint, {
          units: "kilometers",
        });

        return {
          ClimateID,
          Elev_m,
          climateStation,
          stationID,
          src,
          name,
          minYear,
          maxYear,
          dataYears: JSON.parse(dataYears), //CRAIG MIGHT NOT NEED
          geometry: stationCoord,
          distance: assetStationDistance,
        };
      });

      const proximityStationSet = Object.values(
        proximityStationProperties.reduce((acc, obj) => {
          acc[obj.stationID] = obj;
          return acc;
        }, {})
      );

      dispatch(setProximityStations(proximityStationSet));
    }
  }, [assets.selectedAssetProximity, assets.stationYrlyCountFilter]);

  useEffect(() => {
    if (assetList) {
      // console.log("assets.data", assets.data)
      const assetFormatted = convertToGeoJSON(assetList);
      setAssetGeoJSON(assetFormatted);
    }
  }, [assetList]);

  const handleMouseEnter = (event) => {
    const { features } = event;

    if (features && features.length > 0) {
      const hoveredFeature = features[0];
      const featureId = hoveredFeature?.properties?.id;
      if (featureId) {
        dispatch(setHoveredAsset(featureId));
      }
    }
  };

  const handleMouseExit = () => {
    dispatch(setHoveredAsset(null));
  };

  const handleFeatureClick = (e) => {
    const features = mapRef.current.queryRenderedFeatures(e.point);

    //CRAIG abstract this out to a util later
    const [assetFeature] = features.filter(
      (feature) => feature.source === AssetLayerId
    );
    const clickedFeature = assetFeature?.properties;
    if (clickedFeature) {
      dispatch(setSelectedAsset(clickedFeature));
      dispatch(setTabIndex(0));
    }
  };

  if (!assetGeoJSON) {
    return false;
  }
  return (
    <Source
      id={AssetLayerId}
      type="geojson"
      data={{ ...assetGeoJSON }}
      cluster={false}
    >
      <Layer
        style={{ cursor: "pointer" }}
        visible={false}
        {...{
          id: "asset-hover",
          type: "circle",
          source: AssetLayerId,
          paint: {
            "circle-radius": 10,
            "circle-opacity": assetLayerVisible ? 1 : 0,
            "circle-color": getColorExpression(
              assets.selectedAsset?.id,
              assets.hoveredAsset
            ),
          },
        }}
      />

      <Layer
        style={{ cursor: "pointer" }}
        visibility={assetLayerVisible ? "visible" : "none"}
        {...{
          id: "user-assets",
          type: "circle",
          source: AssetLayerId,
          paint: {
            "circle-radius": 7,
            "circle-opacity": assetLayerVisible ? 1 : 0,
            "circle-color": "green",
          },
        }}
      />
    </Source>
  );
});

export default AssetLayer;

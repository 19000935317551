// @ts-nocheck
import React from "react";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as Bell } from "../../assets/icons/bell-icon.svg";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../../store/modalSlice";

const Notification = ({ header, details }) => {
  const dispatch = useDispatch();
  // setModalOpen
  return (
    <div>
      <div className="flex flow-row grow items-center border-b border-gray-200 p-2">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <Bell className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          {header}
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      <div className="p-4">{details}</div>
      <div className="flex flex-row grow space-x-3 px-5 pb-5">
        <div className="flex-1"></div>
        <button
          className="flex-grow flex-1 eai-btn my-1"
          onClick={() => dispatch(setModalOpen(false))}
        >
          <div className="mx-3">Ok</div>
        </button>
        <div className="flex-1"></div>
      </div>
    </div>
  );
};

export default Notification;

// @ts-nocheck
import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { setAssetTabIndex } from "../../store/tabSlice";
import { useDispatch, useSelector } from "react-redux";
import AFSetup from "./Setup/AFSetup";
import AFNow from "./Now/AFNow";
import AFSoon from "./Soon/AFSoon";
import AFLater from "./Later/AFLater";
import AFAlerts from "./Alerts/AFAlerts";

const AssetForecastTabs = () => {
  const assetTabIndex = useSelector((state) => state.tabState.assetTabIndex);
  const dispatch = useDispatch();

  return (
    <Tabs
      className="pt-2"
      selectedIndex={assetTabIndex}
      onSelect={(index) => dispatch(setAssetTabIndex(index))}
    >
      <TabList>
        <Tab className="cursor-pointer px-2 py-1">Setup</Tab>
        <Tab className="cursor-pointer px-2 py-1">Now</Tab>
        <Tab className="cursor-pointer px-2 py-1">Soon</Tab>
        <Tab className="cursor-pointer px-2 py-1">Later</Tab>
        <Tab className="cursor-pointer px-2 py-1">Alerts</Tab>
      </TabList>

      <TabPanel>
        <AFSetup />
      </TabPanel>
      <TabPanel>
        <AFNow />
      </TabPanel>
      <TabPanel>
        <AFSoon />
      </TabPanel>
      <TabPanel>
        <AFLater />
      </TabPanel>
      <TabPanel>
        <AFAlerts />
      </TabPanel>
    </Tabs>
  );
};

export default AssetForecastTabs;

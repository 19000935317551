// @ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TiWeatherDownpour } from "react-icons/ti";
import { BiSolidBuildingHouse, BiCurrentLocation } from "react-icons/bi";
import { BsGrid3X3GapFill } from "react-icons/bs";
import {
  setRainVisible,
  setAssetVisible,
  setWeatherStationVisible,
  setCustomMarker,
} from "../store/mapStateSlice";

const MapToolbar = () => {
  const dispatch = useDispatch();
  const mapState = useSelector((state) => state.mapState);

  return (
    <div className="flex">
      <div className="bg-eai-accent-grn justify-left space-x-4 flex-1 p-1">
        <button
          className={`${
            mapState.customMarkerActive
              ? "bg-eai-coral text-eai-lt-dark"
              : "bg-white"
          } transition p-1 align-middle rounded border-eai-dark border-2`}
          type="button"
          title="Set Asset Location"
          onClick={() =>
            dispatch(setCustomMarker(!mapState.customMarkerActive))
          }
        >
          <BiCurrentLocation fontSize="2em" />
        </button>
      </div>
      <div className="bg-eai-accent-grn flex justify-center space-x-4 flex-1 p-1">
        <button
          className={`${
            mapState.rainGridLayerVisible
              ? "bg-white text-eai-dark"
              : "bg-eai-accent-lt"
          } transition p-1 align-middle rounded border-eai-dark border-2`}
          type="button"
          title="Rain Grid Layer"
          onClick={() =>
            dispatch(setRainVisible(!mapState.rainGridLayerVisible))
          }
        >
          <BsGrid3X3GapFill fontSize="2em" />
        </button>
        <button
          className={`${
            mapState.assetLayerVisible
              ? "bg-white text-eai-dark"
              : "bg-eai-accent-lt"
          } transition p-1 align-middle rounded border-eai-dark border-2`}
          type="button"
          title="Asset Layer"
          onClick={() => dispatch(setAssetVisible(!mapState.assetLayerVisible))}
        >
          <BiSolidBuildingHouse fontSize="2em" />
        </button>
        <button
          className={`${
            mapState.weatherStationLayerVisible
              ? "bg-white text-eai-dark"
              : "bg-eai-accent-lt"
          } transition p-1 align-middle rounded border-eai-dark border-2`}
          type="button"
          title="Weather Station Layer"
          onClick={() =>
            dispatch(
              setWeatherStationVisible(!mapState.weatherStationLayerVisible)
            )
          }
        >
          <TiWeatherDownpour fontSize="2em" />
        </button>
      </div>
      <div className="bg-eai-accent-grn flex-1 p-1"></div>
    </div>
  );
};

export default MapToolbar;

// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { stationYearlyRainMaxesByStationIdAndYear } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

//CRAIG add errorState Later?
const initialState = {
  details: null,
  climateData: null,
  loadingClimateData: "idle",
  yrRainMax: null,
  loadingYrRainMax: "idle",
};

// export const fetchClimateData = createAsyncThunk(
//     'station/fetchClimateData',
//     async (ClimateID, thunkAPI) => {
//       try{
//         const { data } = await API.graphql(graphqlOperation(listGeoPoints));
//         return data.listGeoPoints.items;
//       }
//       catch (error) {
//         return thunkAPI.rejectWithValue(error.message);
//       }
//     }
//   )

export const fetchYearlyStationMaxRain = createAsyncThunk(
  "station/fetchYrRainMax",
  async (stationId, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: stationYearlyRainMaxesByStationIdAndYear,
        variables: { stationId },
      });

      return data.stationYearlyRainMaxesByStationIdAndYear.items;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const SelectedStationSlice = createSlice({
  name: "selectedStation",
  initialState,
  reducers: {
    setSelectStation: (state, action) => {
      state.details = action.payload;
    },
    setClimateData: (state, action) => {
      state.climateData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchYearlyStationMaxRain.pending, (state) => {
        state.loadingYrRainMax = "pending";
        state.yrRainMax = null;
        state.error = null;
      })
      .addCase(fetchYearlyStationMaxRain.fulfilled, (state, action) => {
        state.loadingYrRainMax = "success";
        state.yrRainMax = action.payload;
      })
      .addCase(fetchYearlyStationMaxRain.rejected, (state, action) => {
        state.loadingYrRainMax = "failed";
        // CRAIG Put something later?
      });
  },
});

export const { setSelectStation } = SelectedStationSlice.actions;
export default SelectedStationSlice.reducer;

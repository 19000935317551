// @ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import {
  modalViewsConst,
  setModalOpen,
  setModalState,
} from "../../store/modalSlice";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { ReactComponent as PowerIcon } from "../../assets/icons/lightning.svg";

const SolarModelEnergyDisplay = ({ modelProperties }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <div
        onClick={() => dispatch(setModalOpen(false))}
        className=" flex flow-row grow items-center border-b border-gray-200 p-5"
      >
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <PowerIcon stroke="#A2BBBF" className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          {modelProperties.name}
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      <div>
        {Object.keys(modelProperties).map((key) => (
          <div key={key}>
            {key}: {modelProperties[key]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SolarModelEnergyDisplay;

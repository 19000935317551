// @ts-nocheck
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { listAssetGroups, getAssetGroup } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import {
  createAssetGroup,
  deleteAssetGroup,
  updateAssetGroup,
} from "../graphql/mutations";
import { schemaMatch } from "../util/utils";

const client = generateClient();

const initialState = {
  loading: "idle",
  error: null,
  groups: {},
};

export const postAssetGroup = createAsyncThunk(
  "assetGroup/postAssetGroup",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: createAssetGroup,
        variables: { input },
      });

      return data.createAssetGroup;
    } catch (error) {
      console.log("post group error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAssetGroupById = createAsyncThunk(
  "assetGroup/fetchAssetGroupById",
  async (id, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: getAssetGroup,
        variables: { id },
      });
      return data.getAssetGroup;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchAssetGroups = createAsyncThunk(
  "assetGroup/fetchAllAssetGroups",
  async (_, thunkAPI) => {
    try {
      const { data } = await client.graphql({ query: listAssetGroups });
      const groupDict = data.listAssetGroups.items.reduce(
        (acc, curr) => ({ ...acc, [curr.id]: curr }),
        {}
      );
      return groupDict;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteAssetGroupFunc = createAsyncThunk(
  "assetGroup/deleteAssetGroup",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: deleteAssetGroup,
        variables: { input },
      });

      return data.deleteAssetGroup;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editAssetGroupFunc = createAsyncThunk(
  "assetGroup/editAssetGroup",
  async (input, thunkAPI) => {
    try {
      const { data } = await client.graphql({
        query: updateAssetGroup,
        variables: { input },
      });

      return data.updateAssetGroup;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const AssetGroupSlice = createSlice({
  name: "assetGroups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssetGroupById.pending, (state) => {
        // state.error = null;
      })
      .addCase(fetchAssetGroupById.fulfilled, (state, action) => {
        state.groups[action.payload.id] = action.payload;
        state.loading = "success";
        // state.error = null;
      })
      .addCase(fetchAssetGroupById.rejected, (state) => {
        // state.error = null;
      })
      .addCase(editAssetGroupFunc.pending, (state) => {
        // state.error = null;
      })
      .addCase(editAssetGroupFunc.fulfilled, (state, action) => {
        state.groups[action.payload.id] = action.payload;
      })
      .addCase(editAssetGroupFunc.rejected, (state) => {
        state.error = null;
      })
      .addCase(deleteAssetGroupFunc.pending, (state) => {
        state.error = null;
      })
      .addCase(deleteAssetGroupFunc.fulfilled, (state, action) => {
        delete state.groups[action.payload.id];
      })
      .addCase(deleteAssetGroupFunc.rejected, (state) => {
        state.error = null;
      })
      .addCase(postAssetGroup.pending, (state) => {
        state.error = null;
      })
      .addCase(postAssetGroup.fulfilled, (state, action) => {
        state.loading = "success";
        state.groups[action.payload.id] = action.payload;
      })
      .addCase(postAssetGroup.rejected, (state, action) => {
        // newHire
      })
      .addCase(fetchAssetGroups.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(fetchAssetGroups.fulfilled, (state, action) => {
        state.loading = "success";
        state.groups = action.payload;
      })
      .addCase(fetchAssetGroups.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.error.message;
      });
  },
});

export default AssetGroupSlice.reducer;

// @ts-nocheck
import React from "react";
import { ReactComponent as BellIcon } from "../../assets/icons/bell-icon.svg";
import { ReactComponent as XClose } from "../../assets/icons/x-close.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  modalViewsConst,
  setModalOpen,
  setModalState,
} from "../../store/modalSlice";
import AntSwitch from "../customWidget/AntSwitch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { toggleAlertActive } from "../../store/alertsSlice";

const ManageAssetAlertLite = ({ assetid, groupid }) => {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts);
  const groupAlerts = alerts.groups[groupid];

  const handleClick = (alertRecord) => {
    const preppedAlert = { ...alertRecord };
    // Craig make sure null asset alerts are handled Here instead
    if (!alertRecord.assetId && alertRecord.groupAlert) {
      preppedAlert.assetId = "allAssets";
    }

    dispatch(
      setModalState({
        modalOpen: true,
        modalView: modalViewsConst.editNewAlert,
        properties: preppedAlert,
      })
    );
  };

  const renderAlertRecord = (alertRecord) => {
    return (
      <tr key={alertRecord.id}>
        <td className="text-center">
          <span
            className="cursor-pointer hover:underline"
            onClick={() => handleClick(alertRecord)}
          >
            {alertRecord.name}
          </span>
        </td>
        {/* <td>{alertRecord.rainfall}</td>
        <td>{alertRecord.probability}</td>
        <td>{alertRecord.period}</td>
        <td>{alertRecord.window}</td> */}
        <td className="flex justify-center">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Off</Typography>
            <AntSwitch
              checked={alertRecord.active}
              onChange={(e) =>
                dispatch(
                  toggleAlertActive({
                    id: alertRecord.id,
                    active: !alertRecord.active,
                    assetGroupId: alertRecord.assetGroupId,
                  })
                )
              }
              inputProps={{ "aria-label": "ant design" }}
            />
            <Typography>On</Typography>
          </Stack>
        </td>
      </tr>
    );
  };

  return (
    <div className="">
      <div className="flex flow-row grow items-center border-b border-gray-200 p-2">
        <div className="flex flex-0 w-10 h-10 bg-white rounded-md shadow border border-gray-200 mr-3 items-center justify-center">
          <BellIcon className="h-6 w-6" />
        </div>
        <div className="flex grow text-gray-900 text-xl font-semibold font-Inter">
          Manage Alerts
        </div>
        <div
          onClick={() => dispatch(setModalOpen(false))}
          className="cursor-pointer flex flex-0 w-10 h-10 bg-white items-center justify-center"
        >
          <XClose className="h-6 w-6" />
        </div>
      </div>
      <div className="flex ">
        <table className="flex-1 ">
          <tr>
            <th>Name</th>
            {/* <th>rainfall (mm)</th>
    <th>probability</th>
    <th>period</th>
    <th>window</th> */}
            <th>Active</th>
          </tr>

          {groupAlerts.reduce((acc, curr) => {
            if (!curr.groupAlert && curr.assetId !== assetid) {
              return acc;
            }
            return [...acc, renderAlertRecord(curr)];
          }, [])}
        </table>
      </div>
    </div>
  );
};

export default ManageAssetAlertLite;
